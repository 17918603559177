import React, { useCallback, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { DialogProps, Box, Stack, useMediaQuery, IconButton } from '@mui/material'
import { RiCloseLine } from '@remixicon/react'
import DialogWrapper from 'src/components/DialogWrapper/DialogWrapper'
import Typography from 'src/components/Typography/Typography'
import TextField from 'src/components/TextField/TextField'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import StakeSlider from 'src/components/StakeSlider/StakeSlider'
import Button from 'src/components/Button/Button'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { PNG_ICONS } from 'src/assets/icons/icons'
import { useFormik } from 'formik'
import * as schemas from 'src/helpers/schemas'
import * as yup from 'yup';
import { StakingType, TokenType } from 'src/helpers/types'
import { formatTokenToString, toTokenString } from 'src/utils/tokenUtil'

type Reason = 'backdropClick' | 'escapeKeyDown' | 'btnClick'

export enum PoolType {
  FLEXI = 'flexi',
  VESTED = 'vested',
}

export type ChangeAvatarDialogProps = DialogProps & {
  type: `${PoolType}`
  amount?: number
  title: string
  onOk?: (values: { unstakeAmount: number; type: StakingType  }) => void
  onCancel?: () => void
  releasedAt?: string
  children: (opts: { value: number }) => React.ReactNode
}

const ValidationSchema = (externalValue: number) => yup.object().shape({
  amount: schemas.amount(externalValue),
});

export default function UnstakeDialog({
  type,
  amount,
  title,
  onClose,
  onOk,
  onCancel,
  releasedAt,
  children,
  ...restDialogProps
}: ChangeAvatarDialogProps) {
  const [value, setValue] = useState<string | number>('')

  const theme = useTheme()
  const isUpXsmView = useMediaQuery(theme.breakpoints.up('xsm'))

  const handleClose = useCallback(
    (event: object, reason: Reason) => {
      if (onClose) {
        // @ts-ignore
        onClose(event, reason)
      }
    },
    [onClose]
  )

  const handleOnSubmit = (unstakeAmount:number, type:StakingType) => {
    if (onOk) {
      onOk({ unstakeAmount, type })
    }
  }

  const [sliderValue, setSliderValue] = useState(0);

  const formik = useFormik({
    initialValues: {
        amount: value as string,
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: ValidationSchema(amount!==undefined ? amount : 0),
    onSubmit: (values) => {
      formik.setStatus('');  
      handleOnSubmit(Number(values.amount), type==='flexi' ? StakingType.FLEXI : StakingType.VESTED);
      formik.resetForm();
      setSliderValue(0);
      formik.setSubmitting(false);
    }
  }); 

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = event.target.value.replace(/[^0-9.]+/g, '').replace(/(\..*)\./g, '$1');
    // Update formik's state with the filtered value
    formik.setFieldValue(event.target.name, filteredValue);   
    // Update the slider value, clamping it between 0 and 100
    setSliderValue(Number(filteredValue));
  }

  const onSliderChange = (value: number | number[]) => {
    setSliderValue(value as number);
    formik.setFieldValue('amount',roundDownToSixDecimals(value as number));

  }

  const roundDownToSixDecimals = (value : number) => {
    return Math.floor(value * 1e6) / 1e6;
  };

  return (
    <DialogWrapper
      {...restDialogProps}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: { xs: '456px', md720: '521px' },
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            margin: '16px',
            ...restDialogProps.sx,
          },
        },
      }}
    >
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={() => ({
          border: '1px solid',
          borderColor: 'gray.700',
        })}
      >
        <Box
          sx={{
            m: '1px',
            bgcolor: 'gray.800',
            p: '24px 14px',
            [theme.breakpoints.up('xsm')]: {
              p: '24px',
            },
          }}
        >
          <Stack gap="9px" mb="26px">
            <Stack direction="row" justifyContent="flex-end">
              <IconButton sx={{ p: 0 }} onClick={() => handleClose({}, 'btnClick')}>
                <Box component={RiCloseLine} size={18} color="gray.25" />
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Typography
                variant="h6"
                color="gray.0"
                sx={{
                  [theme.breakpoints.up('xsm')]: {
                    ...theme.typography.h5,
                  },
                }}
              >
                {title}
              </Typography>
            </Stack>
          </Stack>
          <div>           
             <TextField
                id="amount" name="amount"
                type="number"
                label="Amount to unstake"
                fullWidth
                value={formik.values.amount}
                onChange={onChange}
                error={(formik.touched.amount && Boolean(formik.errors.amount))}
                helperText={formik.touched.amount && formik.errors.amount !== undefined ? formik.errors.amount : undefined }
              
                sx={{
                  '& input:hover, & input:focus': {
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  },
                }}
              />
            <div>
              {type === PoolType.FLEXI && (
                <Box mt="5px" mb="3px" mr="7px">
                  <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    gap="8px"
                    justifyContent="flex-end"
                  >
                    <Typography variant="paragraphSmall" color="gray.500">
                      Available
                    </Typography>
                    <CoinImageWithAmount
                      amount={formatTokenToString(toTokenString((amount)),TokenType.CT,6,false,true,true)}
                      avatarProps={{ sx: { width: '18px', height: '18px' }, src: PNG_ICONS.coin}}
                      amountTypographyProps={{
                        color: 'white',
                        fontWeight: 600,
                      }}
                    />
                  </Stack>
                </Box>
              )}
              {type === PoolType.VESTED && (
                <Stack
                  direction="row"
                  mt="6px"
                  mb="3px"
                  mr="7px"
                  gap="8px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack
                    flexWrap="wrap"
                    gap="2px"
                    sx={{
                      [theme.breakpoints.up('xsm')]: {
                        flexDirection: 'row',
                      },
                    }}
                  >
                    <Typography variant="bodySmall" color="gray.0">
                      Released at
                    </Typography>
                    <Typography variant="bodySmall" color="gray.0">
                      {releasedAt}
                    </Typography>
                  </Stack>
                  <Stack
                    flexWrap="wrap"
                    gap="4px"
                    sx={{
                      [theme.breakpoints.up('xsm')]: {
                        flexDirection: 'row',
                      },
                    }}
                  >
                    <Typography variant="paragraphSmall" color="gray.500">
                      Available
                    </Typography>
                    <CoinImageWithAmount
                      justifyContent="flex-end"
                      amount={amount}
                      avatarProps={{ sx: { width: '18px', height: '18px' }, src: PNG_ICONS.coin}}
                      amountTypographyProps={{
                        color: 'white',
                        fontWeight: 600,
                      }}
                    />
                  </Stack>
                </Stack>
              )}
              <Box mb="23px" px="6.5px" minHeight="61px">
                <StakeSlider total={amount} value={sliderValue} onChange={onSliderChange} disabled={false} />
              </Box>
            </div>
            <div>
              {children({
                value: amount && Number(formik.values.amount) <= amount ? Number(formik.values.amount) : (amount as number),
              })}
            </div>
          </div>
        </Box>
        <Box bgcolor="gray.900" m="1px" mt="2px">
          <Button
            variant="spaceBetween"
            size={isUpXsmView ? 'large' : 'big'}
            fullWidth
            endIcon={<SvgIcon name="refund-CC-line" />}
            type="submit" 
            disabled={!formik.dirty || formik.isSubmitting }
          >
            Unstake
          </Button>
        </Box>
      </Box>
      </form>
    </DialogWrapper>
  )
}
