export function setCurrentGame(gameId) {
    return { type: "SET_CURRENT_GAME", gameId : gameId };
}

export function setCurrentLeaderboardGame(gameId) {
    return { type: "SET_CURRENT_LEADERBOARD_GAME", gameId : gameId };
}

export function setSortOrder(gameId, sortOrder){
    return {type: "SORT_TEMPLATES", gameId, sortOrder}
}

export function setCurrentProfileMenu(menuId) {
    return { type: "SET_CURRENT_PROFILE_MENU", menuId : menuId };
}   