import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Stack } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import PatternLines from 'src/components/PatternLines/PatternLines'
import Link from 'src/components/Link/Link'
import MobileLegalLinks from 'src/components/MobileLegalLinks/MobileLegalLinks'
import MobileSocialLinks from 'src/components/MobileSocialLinks/MobileSocialLinks'

export default function Footer() {
  const theme = useTheme()
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'))

  if (isTabletView) {
    return (
      <FooterWrapper>
        <Stack
          direction="column"
          alignItems="center"
          bgcolor="gray.800"
          p="27px"
          border="1px solid"
          borderColor="gray.700"
          height="182px"
        >
          <Typography
            variant="paragraphMedium"
            color="gray.500"
            sx={{
              [theme.breakpoints.down('xsm')]: {
                fontSize: theme.typography.paragraphMedium.fontSize,
              },
            }}
          >
            2024 Challenge. All rights Reserved
          </Typography>
          <Box mt="20px" mb="28px">
            <MobileLegalLinks />
          </Box>
          <MobileSocialLinks />
        </Stack>
      </FooterWrapper>
    )
  }

  return (
    <FooterWrapper>
      <Stack
        border="1px solid"
        borderColor="gray.700"
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        gap={1}
        sx={{
          background: theme.palette.gray[800],
          minHeight: '68px',
        }}
      >
        <Stack
          direction="row"
          alignItems="stretch"
          sx={{ borderRight: `1px solid ${theme.palette.gray[700]}` }}
        >
          <Typography
            variant="paragraphMedium"
            color={theme.palette.gray[200]}
            sx={{
              px: 2.125,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Follow us
          </Typography>
          <Link
            href="https://twitter.com/challengedotgg"
            target="_blank"
            rel="noopener"
            color="secondary"
            size="large"
            sx={{
              width: '67px',
              borderLeft: `1px solid ${theme.palette.gray[700]}`,
            }}
          >
            <SvgIcon name="twitter" fontSize="medium" />
          </Link>
          <Link
            href="https://discord.gg/vJxSfNBSpQ"
            target="_blank"
            rel="noopener"
            color="secondary"
            size="large"
            sx={{
              width: '67px',
              borderLeft: `1px solid ${theme.palette.gray[700]}`,
            }}
          >
            <SvgIcon name="discord" fontSize="medium" />
          </Link>
          <Link
            href="https://t.me/IchallengeU"
            target="_blank"
            rel="noopener"
            color="secondary"
            size="large"
            sx={{
              width: '67px',
              borderLeft: `1px solid ${theme.palette.gray[700]}`,
            }}
          >
            <SvgIcon name="telegram" fontSize="medium" />
          </Link>
        </Stack>

        <Typography
          variant="paragraphMedium"
          color={theme.palette.gray[500]}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          2024 Challenge. All rights Reserved
        </Typography>

        <Stack direction="row">
          <Link
            href="https://challenge.gg/terms-of-use/"
            target="_blank"
            rel="noopener"
            color="secondary"
            size="large"
            sx={{
              px: '15px',
              borderLeft: `1px solid ${theme.palette.gray[700]}`,
            }}
          >
            User Agreement
          </Link>
          <Link
            href="https://challenge.gg/privacy-policy/"
            target="_blank"
            rel="noopener"
            color="secondary"
            size="large"
            sx={{
              px: 1.875,
              borderLeft: `1px solid ${theme.palette.gray[700]}`,
            }}
          >
            Privacy Policy
          </Link>
        </Stack>
      </Stack>
    </FooterWrapper>
  )
}

function FooterWrapper({ children }: React.PropsWithChildren) {
  const theme = useTheme()

  return (
    <Box mx={1} mb={1}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          py: '28px',
          px: '24px',
          border: '1px solid',
          borderColor: 'gray.700',
          borderBottom: 0,
          height: '95px',
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            height: '83px',
          },
        }}
      >
        <PatternLines left={0} marginLeft={0} width="100%" zIndex="-1" />
        <Typography
          variant="bodyBig"
          color="gray.500"
          sx={{
            flexGrow: 1,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          {/*Description of service some details or something Law related information. So its necesary
          if needed, and something more*/}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}
