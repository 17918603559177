import { Stack, StackProps as MuiStackProps } from '@mui/material'
import React from 'react'
import SvgIconWithTitleBg, {
  SvgIconWithTitleBgProps,
} from '../SvgIconWithTitleBg/SvgIconWithTitleBg'
import Typography, { TypographyProps } from '../Typography/Typography'

export type TitleWithStatusProps = {
  title?: string
  Status?: SvgIconWithTitleBgProps
  StackProps?: MuiStackProps
  TitleTypography?: TypographyProps
}

function TitleWithStatus({ title, Status, StackProps, TitleTypography }: TitleWithStatusProps) {
  return (
    <Stack direction="row" gap={1} {...StackProps}>
      {title && (
        <Typography
          variant="paragraphSmall"
          color="gray.400"
          {...TitleTypography}
          sx={{
            position: 'relative',
            bottom: '-2px',
            ...TitleTypography?.sx,
          }}
        >
          {title}
        </Typography>
      )}
      {Status && <SvgIconWithTitleBg {...Status} />}
    </Stack>
  )
}

export default TitleWithStatus
