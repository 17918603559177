import React, { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, useMediaQuery, Breakpoint, BoxProps } from '@mui/material'
import Image from 'src/components/Image/Image'
import MenuItemButton from 'src/components/MenuItemButton/MenuItemButton'
import MobileGameSidebar from 'src/components/MobileGameSidebar/MobileGameSidebar'

type MenuItem = {
  id: string
  icon?: string
  name?: string
  label?: string
  isComingSoon?: boolean
  startIcon?: React.ReactNode
  disabled?: boolean
}

export type GameSidebarProps = {
  list: MenuItem[]
  selectedId: string
  onSelect?: (selectedId: string) => void
  breakpoint?: Breakpoint
  shadowBoxProps?: BoxProps
}

export default function GameSidebar({
  breakpoint = 'sm',
  list = [],
  selectedId,
  onSelect,
  shadowBoxProps,
}: GameSidebarProps) {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down(breakpoint))

  const onMenuItemClick = useCallback(
    (id: string) => () => {
      if (onSelect) {
        onSelect(id)
      }
    },
    [onSelect]
  )

  if (isMobileView) {
    return (
      <MobileGameSidebar
        list={list}
        selectedId={selectedId}
        onSelect={onSelect}
        shadowBoxProps={shadowBoxProps}
      />
    )
  }

  return (
    <Box
      sx={{
        minHeight: '536px',
        width: '191px',
        border: `1px solid`,
        borderColor: 'gray.700',
        bgcolor: 'gray.900',
        overflowY: 'auto',
        flexGrow: 0,
        flexShrink: 0,
        '&:hover': {
          borderColor: 'gray.500',
        },
        [theme.breakpoints.down('md')]: {
          width: '174px',
          minHeight: '494px',
        },
      }}
    >
      {list?.length
        ? list.map(({ id, name, icon, isComingSoon }) => (
            <MenuItemButton
              key={id}
              selected={id === selectedId}
              startIcon={<Image src={icon} alt={name} />}
              isComingSoon={isComingSoon}
              onClick={onMenuItemClick(id)}
            >
              {name}
            </MenuItemButton>
          ))
        : null}
    </Box>
  )
}
