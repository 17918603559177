import { Signer } from "ethers";

class SignerManager {
  private static _signer: Signer | undefined = undefined;

  public static set signer(signer: Signer | undefined) {
    SignerManager._signer = signer;
  }

  public static get signer(): Signer | undefined {
    return SignerManager._signer;
  }
}

export default SignerManager;