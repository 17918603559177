import React from 'react'
import {
  toast as nativeToast,
  ToastOptions,
  ToastContainer as NativeToastContainer,
  ToastContentProps,
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTheme } from '@mui/material/styles'
import { Alert, AlertTitle } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import ServiceMessage, { ServiceMessageProps } from 'src/components/ServiceMessage/ServiceMessage'
import LobbyStatusBar, { LobbyStatusBarProps } from 'src/components/LobbyStatusBar/LobbyStatusBar'
import TimerToast, { TimerToastProps } from 'src/components/TimerToast/TimerToast'

export const TOAST_IDS = {
  Notification: {
    containerId: 'Notification',
  },
  ServiceMessage: {
    containerId: 'ServiceMessage',
    toastId: 'ServiceMessage',
  },
  LobbyStatusBar: {
    toastId: 'LobbyStatusBar',
  },
  TimerToast: {
    toastId: 'TimerToast',
  },
}

export const NotificationToastContainer = () => (
  <NativeToastContainer
    enableMultiContainer
    containerId={TOAST_IDS.Notification.containerId}
    closeOnClick={false}
    closeButton={false}
    hideProgressBar
    style={{
      width: '400px',
    }}
    toastStyle={{
      padding: 0,
      borderRadius: 0,
      minHeight: 64,
      overflow: 'inherit',
    }}
    bodyStyle={{
      padding: 0,
      margin: 0,
      width: '100%',
    }}
    icon={false}
  />
)

export const ServiceMessageToastContainer = () => {
  return (
    <NativeToastContainer
      enableMultiContainer
      containerId={TOAST_IDS.ServiceMessage.containerId}
      position={nativeToast.POSITION.BOTTOM_CENTER}
      autoClose={false}
      draggable={false}
      closeOnClick={false}
      closeButton={false}
      hideProgressBar
      style={{
        width: '100%',
        bottom: 0,
        padding: 0,
      }}
      toastStyle={{
        minHeight: '52px',
        marginBottom: 0,
        boxShadow: 'none',
        display: 'block',
        padding: 0,
        borderRadius: 0,
      }}
      bodyStyle={{
        padding: 0,
        margin: 0,
        display: 'block',
      }}
      icon={false}
    />
  )
}

export const ServiceMessageToastBox = (props: ToastContentProps<ServiceMessageProps>) => {
  const { closeToast, data } = props

  if (!data) {
    return null
  }

  return <ServiceMessage {...data} closeToast={closeToast} />
}

export const LobbyStatusBarToastBox = (props: ToastContentProps<LobbyStatusBarProps>) => {
  const { closeToast, data } = props

  if (!data) {
    return null
  }

  return <LobbyStatusBar {...data} closeToast={closeToast} />
}

export const TimerToastInfo = (props: ToastContentProps<TimerToastProps>) => {
  const { closeToast, data } = props

  if (!data) {
    return null
  }

  return <TimerToast {...data} closeToast={closeToast} />
}

export type ToastBoxProps = {
  data?: string | { title?: string; description?: string }
  type: 'success' | 'warning' | 'error'
  onClose?: () => void
}

export const NotificationToastBox = (props: ToastBoxProps) => {
  let title = ''
  let description = ''

  if (props.data) {
    if (typeof props.data === 'string') {
      title = props.data
    } else {
      title = props.data.title || ''
      description = props.data.description || ''
    }
  }

  const theme = useTheme()

  return (
    <Alert
      sx={{
        alignItems: description ? 'flex-start' : 'center',
        width: 'auto',
        minWidth: '400px',
        maxWidth: '400px',
        minHeight: '64px',
        padding: '11px 15px 11px 10px',
        gap: '17px',
        '& .MuiAlert-icon': {
          margin: 0,
          padding: 0,
        },
        '& .MuiAlert-message': {
          padding: '6px 0px 7px',
          '& .MuiAlertTitle-root': {
            ...theme.typography.paragraphMedium,
            marginBottom: description ? '4px' : 0,
          },
        },
        '& .MuiAlert-action': {
          paddingLeft: '4px',
        },
        [theme.breakpoints.down('sm')]: {
          minHeight: '48px',
          padding: '9px 11px 9px 9px',
          gap: '14px',
          minWidth: '363px',
          maxWidth: '363px',
          '& .MuiAlert-icon': {
            '& .MuiSvgIcon-root': {
              width: '28px',
              height: '28px',
            },
          },
          '& .MuiAlert-message': {
            padding: '3px 0px 5px',
            '& .MuiAlertTitle-root': {
              ...theme.typography.paragraphSmall,
            },
          },
          '& .MuiAlert-action': {
            paddingTop: '7px',
            paddingBottom: '7px',
            '& .MuiSvgIcon-root': {
              width: '14px',
              height: '14px',
            },
          },
        },
      }}
      iconMapping={{
        success: <SvgIcon name="alert-success" color="success" fontSize="extraLarge" />,
        warning: <SvgIcon name="alert-warning" color="warning" fontSize="extraLarge" />,
        info: <SvgIcon name="arrow-right-line" color="info" fontSize="extraLarge" />,
        error: <SvgIcon name="alert-error" color="error" fontSize="extraLarge" />,
      }}
      severity={props.type}
      action={
        <SvgIcon
          name="exit-icon"
          color="inherit"
          fontSize="extraSmall"
          sx={{ cursor: 'pointer' }}
          onClick={props.onClose}
        />
      }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography variant="bodySmall" color="gray.200">
        {description}
      </Typography>
    </Alert>
  )
}

type ToastMessageT = string | { title?: string; description?: string }
export const toast: {
  success: (data?: ToastMessageT, options?: ToastOptions) => void
  warning: (data?: ToastMessageT, options?: ToastOptions) => void
  error: (data?: ToastMessageT, options?: ToastOptions) => void
  serviceMessageInfo: (data: ServiceMessageProps, options?: ToastOptions) => void
  lobbyStatusBarInfo: (data: LobbyStatusBarProps, options?: ToastOptions) => void
  timerInfo: (data: TimerToastProps, options?: ToastOptions) => void
  update: typeof nativeToast.update
  dismiss: typeof nativeToast.dismiss
  onChange: typeof nativeToast.onChange
  isActive: typeof nativeToast.isActive
} = {
  success: (data, options) =>
    nativeToast.success(
      (props) => <NotificationToastBox data={data} type="success" onClose={props.closeToast} />,
      { containerId: TOAST_IDS.Notification.containerId, ...options }
    ),
  warning: (data, options) =>
    nativeToast.warning(
      (props) => <NotificationToastBox data={data} type="warning" onClose={props.closeToast} />,
      { containerId: TOAST_IDS.Notification.containerId, ...options }
    ),
  error: (data, options) =>
    nativeToast.error(
      (props) => <NotificationToastBox data={data} type="error" onClose={props.closeToast} />,
      { containerId: TOAST_IDS.Notification.containerId, ...options }
    ),
  serviceMessageInfo: (data, options) =>
    nativeToast.info<ServiceMessageProps>(
      (props) => <ServiceMessageToastBox {...props} data={data} />,
      {
        containerId: TOAST_IDS.ServiceMessage.containerId,
        toastId: TOAST_IDS.ServiceMessage.toastId,
        ...options,
      }
    ),
  lobbyStatusBarInfo: (data, options) =>
    nativeToast.info((props) => <LobbyStatusBarToastBox {...props} data={data} />, {
      containerId: TOAST_IDS.Notification.containerId,
      toastId: TOAST_IDS.LobbyStatusBar.toastId,
      ...options,
    }),
  timerInfo: (data, options) =>
    nativeToast.info((props) => <TimerToastInfo {...props} data={data} />, {
      containerId: TOAST_IDS.Notification.containerId,
      toastId: TOAST_IDS.TimerToast.toastId,
      ...options,
    }),
  update: nativeToast.update,
  dismiss: nativeToast.dismiss,
  onChange: nativeToast.onChange,
  isActive: nativeToast.isActive,
}
