import React from 'react'
import { Stack, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type CountWithTokenProps = {
  StackProps?: StackProps
  CountTypographyProps?: TypographyProps
  TokenTypographyProps?: TypographyProps
  count?: string | number
  token?: string
}

export default function CountWithToken({
  count = '00.00',
  token,
  StackProps,
  CountTypographyProps,
  TokenTypographyProps,
}: CountWithTokenProps) {
  return (
    <Stack gap="4px" direction="row" alignItems="center" {...StackProps}>
      <Typography variant="h7" color={count ? 'gray.0' : 'gray.600'} {...CountTypographyProps}>
        {count}
      </Typography>
      {token && (
        <Typography
          variant="bodySmall"
          color={count ? 'gray.50' : 'gray.600'}
          {...TokenTypographyProps}
        >
          {token}
        </Typography>
      )}
    </Stack>
  )
}
