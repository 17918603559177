const initialState = {
    event: null,
    showEvent: false,
    extraData: null,
    globalEvents: [],
};

export function challengeEventReducer(state = initialState, action) {
    switch (action.type) {
        case "SHOW_CHALLENGE_EVENT":
            return {
                ...state,
                event: action.event,
                showEvent: true,
                extraData: action.extraData
            }
        case "HIDE_CHALLENGE_EVENT":
            return {
                ...state,
                showEvent: false,
                extraData: null
            }
        case "INIT_GLOBAL_EVENTS":
            return {
                ...state,
                globalEvents: action.globalEvents
            }
        case "GLOBAL_EVENTS":

            var diff = 25 - (action.globalEvents.length + state.globalEvents.length)

            if (diff < 0) {
                return {
                    ...state,
                    globalEvents: [...action.globalEvents, ...state.globalEvents.slice(0, diff)]
                }
            }
            else {
                return {
                    ...state,
                    globalEvents: [...action.globalEvents, ...state.globalEvents]
                }
            }
           {/* case "LOGOUT":  //No need to remove events since thet do not change
        return initialState; */}
        default:
            return state;
    }
}