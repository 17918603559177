import React from 'react'
import { Stack, Breakpoint, useTheme } from '@mui/material'
import { ChallengeCardProps } from 'src/components/ChallengeCard/ChallengeCard'
import { ImageProps } from 'src/components/Image/Image'
import assets from 'src/assets'
import { PNG_ICONS } from 'src/assets/icons/icons'
import { EnterChallengePopupProps } from 'src/components/Popup/EnterChallengePopup/EnterChallengePopup'
import bgChallengesFrontland from 'src/assets/images/bgChallengesFrontland.jpg'
import bgChallengesFortnite from 'src/assets/images/bgChallengesFortnite.jpg'
import bannerFrontlandPhoto from 'src/assets/images/photoDownloadBaner.jpg'
import bannerFortnitePhoto from 'src/assets/images/downloadBanerFortnite.jpg'
import ChallengeCardFrontland from 'src/assets/images/ChallengeCardFrontland.png'
import challengeCardFortnine from 'src/assets/images/challengeCardFortnine.png'
import { Options } from 'src/components/Select/Select'
import { ChallengeDetailsCardProps } from 'src/components/ChallengeDetailsCard/ChallengeDetailsCard'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import TrophyNumber from 'src/components/TrophyNumber/TrophyNumber'
import Link from 'src/components/Link/Link'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import ImageWithTitleSmall from 'src/components/ImageWithTitleSmall/ImageWithTitleSmall'
import ImageWithTitleWrapper from 'src/components/ImageWithTitleWrapper/ImageWithTitleWrapper'
import Typography from 'src/components/Typography/Typography'
import { TournamentIconTextPairProps } from 'src/components/TournamentIconTextPair/TournamentIconTextPair'
import { TournamentBannerTimerProps } from 'src/components/TournamentBannerTimer/TournamentBannerTimer'
import TournamentPrizesRow from 'src/components/TournamentPrizesRow/TournamentPrizesRow'

export const challengeItems: Array<
  Omit<ChallengeCardProps, 'btnProps'> & {
    iconSrc: ImageProps['src']
    tableData?: EnterChallengePopupProps['tableData']
  }
> = [
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
    tableData: [
      [
        'Map',
        {
          picture: ChallengeCardFrontland,
          text: 'Name of the Map',
        },
      ],
      ['Rounds', '2'],
      ['Game mode', '1v1'],
      [
        'Prize  pool',

        {
          picture: PNG_ICONS.coins,
          text: '1.8',
          imageSx: {
            width: '18px',
            height: '18px',
          },
        },
      ],
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.fortnite,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'team-fill', label: 'Players', value: '5' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
    tableData: [
      [
        'Map',
        {
          picture: challengeCardFortnine,
          text: 'Name of the Map',
        },
      ],
      ['Players', '2'],
      ['Game mode', '1v1'],
      [
        'Prize  pool',

        {
          picture: PNG_ICONS.coins,
          text: '1.8',
          imageSx: {
            width: '18px',
            height: '18px',
          },
        },
      ],
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '5.25 CC' },
    ],
    tableData: [
      [
        'Map',
        {
          picture: ChallengeCardFrontland,
          text: 'Name of the Map',
        },
      ],
      ['Rounds', '2'],
      ['Game mode', '1v1'],
      [
        'Prize  pool',

        {
          picture: PNG_ICONS.coins,
          text: '1.8',
          imageSx: {
            width: '18px',
            height: '18px',
          },
        },
      ],
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.fortnite,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'team-fill', label: 'Players', value: '10' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
    tableData: [
      [
        'Map',
        {
          picture: challengeCardFortnine,
          text: 'Name of the Map',
        },
      ],
      ['Players', '2'],
      ['Game mode', '1v1'],
      [
        'Prize  pool',

        {
          picture: PNG_ICONS.coins,
          text: '1.8',
          imageSx: {
            width: '18px',
            height: '18px',
          },
        },
      ],
    ],
  },
]

export const ChallengeListItems: Array<
  Omit<ChallengeCardProps, 'btnProps'> & {
    iconSrc: ImageProps['src']
  }
> = [
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Frontland',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
      { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
]

export const challengeListFortnite: Array<
  Omit<ChallengeCardProps, 'btnProps'> & {
    iconSrc: ImageProps['src']
  }
> = [
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '5' },
      { icon: 'trophy-fill', label: '2 Prizes', value: ['1.8 CC', '1.0 CC'] },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '5' },
      { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '10' },
      { icon: 'trophy-fill', label: '2 Prizes', value: ['1.8 CC', '1.0 CC'] },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '10' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '25' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '20' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '10' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
  },
  {
    title: 'Fortnite',
    bgSrc: challengeCardFortnine,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build BR' },
      { icon: 'repeat-line', label: 'Players', value: '10' },
      { icon: 'trophy-fill', label: '3 Prizes', value: ['1.8 CC', '1.0 CC', '0.5 CC'] },
    ],
  },
]

export const underChallengeButtonDataSource = [
  { label: 'Available', value: 24 },
  { label: 'Games', value: 2 },
]

export const underChallengeButtonDataSource2 = [{ label: 'Available challenges', value: 24 }]

export const challengeCardProps: ChallengeCardProps = {
  bgSrc: ChallengeCardFrontland,
  title: 'Frontland',
  dataSource: [
    { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
    { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
    { icon: 'price-tag-3-fill', label: 'Prize', value: '5.25 CC' },
  ],
  btnProps: {
    label: 'Join now',
    subLabel: '0.8 CC',
  },
}

export const frontlandChallengeDetailsCardProps = (
  breakpoint: Breakpoint = 'sm'
): ChallengeDetailsCardProps => ({
  gameBoxProps: {
    imageProps: {
      src:
        breakpoint === 'sm'
          ? PNG_ICONS.tabletLobbyFrontlandDetails
          : PNG_ICONS.lobbyFrontlandDetails,
    },
    imageWithTitleProps: {
      imageProps: {
        src: PNG_ICONS.frontland,
      },
      title: 'Frontland',
    },
  },
  list: [
    {
      avatarProps: {
        src: PNG_ICONS.lobbyFrontlandDetailsMap,
      },
      label: 'Map',
      value: 'Name of the Map',
    },
    { icon: 'user-group-2-fill', label: 'Game mode', value: '1v1' },
    { icon: 'repeat-line', label: 'Rounds', value: 'First to 5' },
    { icon: 'trophy-fill', label: 'Prize', value: '1.8 CC' },
  ],
  // ChallengeCardButtonProps: {
  //   label: 'play again this challenge',
  //   subLabel: '0.8 CC',
  // },
})

export const fortniteChallengeDetailsCardProps = (
  breakpoint: Breakpoint = 'sm'
): ChallengeDetailsCardProps => ({
  gameBoxProps: {
    imageProps: {
      src:
        breakpoint === 'sm' ? PNG_ICONS.tabletLobbyFortniteDetails : PNG_ICONS.lobbyFortniteDetails,
    },
    imageWithTitleProps: {
      imageProps: {
        src: PNG_ICONS.fortnite,
      },
      title: 'Fortnite',
    },
  },
  list: [
    { icon: 'user-group-2-fill', label: 'Game mode', value: 'No Build battle royale' },
    { icon: 'repeat-line', label: 'Rounds', value: '1' },
    { icon: 'team-fill', label: 'Players', value: '5' },
    {
      icon: 'trophy-fill',
      label: '2 Prizes',
      value: ['1.8 CC', '1.0 CC'],
      showAllPrizes: true,
    },
  ],
})

export const gameLocationsDataSource = ['Frontland', 'Fortnite']

export const availableGamesList = [
  {
    id: 'frontland',
    icon: PNG_ICONS.frontland,
    name: 'Frontland',
  },
  {
    id: 'fortnite',
    icon: PNG_ICONS.fortnite,
    name: 'Fortnite',
  },
  {
    id: 'battlefield2042',
    icon: PNG_ICONS.voteCard,
    name: 'Battlefield 2042',
    isComingSoon: true,
  },
]

export const challengesMockData = {
  frontland: {
    bgImage: bgChallengesFrontland,
    gameSortFilterHeaderProps: {
      title: 'Frontland',
      src: PNG_ICONS.frontland,
    },
    list: ChallengeListItems,
    totalGames: 18,
    winGamesCount: 16,
    loseGamesCount: 2,
    fiveKillRow: 3,
    treeKillRow: 3,
    gameDownloadBanner: {
      photoImgSrc: bannerFrontlandPhoto,
      iconSrc: PNG_ICONS.frontlandBig,
      title: 'Frontland',
      subtitle: 'Next generation play-to-earn',
      longText:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  },
  fortnite: {
    bgImage: bgChallengesFortnite,
    gameSortFilterHeaderProps: {
      title: 'Fortnite',
      src: PNG_ICONS.fortnite,
    },
    list: challengeListFortnite,
    totalGames: 5,
    winGamesCount: 5,
    loseGamesCount: 0,
    fiveKillRow: 1,
    treeKillRow: 4,
    gameDownloadBanner: {
      photoImgSrc: bannerFortnitePhoto,
      iconSrc: PNG_ICONS.fortniteBig,
      title: 'Fortnite',
      subtitle: 'Next generation play-to-earn',
      longText:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  },
}

export const gameSortFilterHeaderOptions: Options = [
  {
    // icon: 'home-heart-line',
    value: 'New First',
    label: 'New First',
  },
  {
    value: 'Oldest First',
    label: 'Oldest First',
  },
  {
    value: 'Popular first',
    label: 'Popular first',
  },
  {
    value: 'Less popular first',
    label: 'Less popular first',
  },
]

export const frontlandLiveUpdates = [
  // Waiting for an opponent
  { date: new Date(), message: 'Searching for Opponent' },
  { date: new Date(), message: 'You Enter Lobby' },
  { date: new Date(Date.now() - 5000), message: 'Withdraw 0.8 CC for Entering lobby' },
  { date: new Date(2022, 11, 20, 12, 40), message: 'Challenge Created' },
  // Challenge is commencing
  { date: new Date(), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  { date: new Date(Date.now() - 50000), message: 'nameofplayer Enter the lobby' },
  { date: new Date(Date.now() - 60000), message: 'You Enter Lobby' },
  // Challenge is in progress
  {
    date: new Date(),
    message: 'Its time! First player who hits 5 Kills wins, Good luck!',
    highlighted: true,
  },
  { date: new Date(Date.now() - 5000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  { date: new Date(Date.now() - 50000), message: 'nameofplayer Enter the lobby' },
  // Challenge is completed Calculating winner
  {
    date: new Date(),
    message: 'Game is Finished. Calculated scores and select the Winner',
    highlighted: true,
  },
  { date: new Date(Date.now() - 5000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  { date: new Date(Date.now() - 50000), message: 'nameofplayer Enter the lobby' },
  // Challenge is completed!
  {
    date: new Date(Date.now() - 24000),
    message: 'Masterchief recieved',
    coinImageWithAmountProps: {
      amount: '1.8',
    },
  },
  { date: new Date(Date.now() - 5000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  { date: new Date(Date.now() - 50000), message: 'nameofplayer Enter the lobby' },
  // Challenge has been cancelled
  {
    date: new Date(),
    message: 'Challenge is Canceled, Your Coins has been refunded.',
    highlighted: true,
  },
  {
    date: new Date(Date.now() - 24000),
    message: 'Nickname was refunded',
    coinImageWithAmountProps: {
      amount: '1.0',
    },
  },
  {
    date: new Date(Date.now() - 24000),
    message: 'Masterchief was refunded',
    coinImageWithAmountProps: {
      amount: '1.0',
    },
  },
  { date: new Date(Date.now() - 5000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  { date: new Date(Date.now() - 50000), message: 'nameofplayer Enter the lobby' },
]

export const fortniteLiveUpdates = [
  // Waiting for an opponent
  { date: new Date(), message: 'Waiting for Players', highlighted: true },
  { date: new Date(), message: 'You Enter Lobby' },
  { date: new Date(Date.now() - 5000), message: 'Withdraw 0.8 CC for Entering lobby' },
  { date: new Date(2022, 11, 20, 12, 40), message: 'Challenge Created' },
  // Challenge is commencing
  { date: new Date(), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  { date: new Date(Date.now() - 50000), message: 'nameofplayer Enter the lobby' },
  { date: new Date(Date.now() - 60000), message: 'You Enter Lobby' },
  // Challenge is in progress
  {
    date: new Date(),
    message:
      'Its time! Play match of Frontland, our system will detect if you try to do anything else, it will result disqualification. When you are done, our system will detect how it went and compare it to the other players in the lobby.',
    highlighted: true,
  }, // 10
  { date: new Date(Date.now() - 5000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 18000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 35000), message: 'Nickname Enter the lobby' },
  { date: new Date(Date.now() - 35000), message: 'nameofplayer Left the lobby' },
  // Challenge is in progress
  {
    // 15
    date: new Date(),
    message:
      'Its time! Play match of Frontland, our system will detect if you try to do anything else, it will result disqualification. When you are done, our system will detect how it went and compare it to the other players in the lobby.',
    highlighted: true,
  },
  { date: new Date(Date.now() - 8000), message: 'Nickname was disqualified', highlighted: true },
  {
    date: new Date(Date.now() - 38000),
    message: 'Masterchief complete his round, Results will be displayed shortly.',
  },
  { date: new Date(Date.now() - 18000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 35000), message: 'Challenge is queued to start' },
  // Challenge is in progress
  {
    // 20
    date: new Date(),
    message:
      'Its time! Play match of Frontland, our system will detect if you try to do anything else, it will result disqualification. When you are done, our system will detect how it went and compare it to the other players in the lobby.',
    highlighted: true,
  },
  {
    date: new Date(Date.now() - 8000),
    message: 'Nickname complete his round, Results will be displayed shortly.',
    highlighted: true,
  },
  {
    date: new Date(Date.now() - 38000),
    message: 'Masterchief complete his round, Results will be displayed shortly.',
  },
  { date: new Date(Date.now() - 18000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 35000), message: 'Challenge is queued to start' },
  // Calculating winner
  {
    // 25
    date: new Date(),
    message: 'Game is Finished. Calculated scores and select the Winner',
    highlighted: true,
  },
  {
    date: new Date(Date.now() - 8000),
    message: 'Nickname complete his round, Results will be displayed shortly.',
  },
  {
    date: new Date(Date.now() - 38000),
    message: 'Masterchief complete his round, Results will be displayed shortly.',
  },
  { date: new Date(Date.now() - 35000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 35000), message: 'Challenge is queued to start' },
  { date: new Date(Date.now() - 50000), message: 'Challenge Created' },
  // Challenge is completed!
  {
    // 31
    date: new Date(),
    message: 'Challenge is completed! Masterchief is the winner!',
    highlighted: true,
  },
  {
    date: new Date(Date.now() - 8000),
    message: 'Nickname complete his round, Results will be displayed shortly.',
  },
  {
    date: new Date(Date.now() - 38000),
    message: 'Masterchief complete his round, Results will be displayed shortly.',
  },
  { date: new Date(Date.now() - 35000), message: 'Ready to Start', highlighted: true },
  { date: new Date(Date.now() - 35000), message: 'Challenge is queued to start' },
]

export const lobby = (breakpoint: Breakpoint = 'sm') => ({
  frontland: {
    waiting: {
      status: 'waiting',
      pageMediaWithShadowBoxProps: {
        videoProps: {
          src: assets.video.lobbySolo,
        },
      },
      loading: true,
      title: 'Waiting for an opponent',
      leftCount: '1',
      leftTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
              ],
            },
          ],
        },
      },
      rightCount: '0',
      rightTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [{ cell: null }],
        },
      },
      challengeDetailsCardProps: frontlandChallengeDetailsCardProps(breakpoint),
      liveUpdateList: frontlandLiveUpdates.slice(0, 4),
    },
    commencing: {
      status: 'commencing',
      pageMediaWithShadowBoxProps: {
        videoProps: {
          src: assets.video.lobbyFilled,
        },
      },
      loading: false,
      title: 'Challenge is commencing',
      leftCount: '1',
      leftTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
              ],
            },
          ],
        },
      },
      rightCount: '1',
      rightTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: frontlandChallengeDetailsCardProps(breakpoint),
      liveUpdateList: frontlandLiveUpdates.slice(4, 10),
    },
    progress: {
      status: 'progress',
      pageMediaWithShadowBoxProps: {
        videoProps: {
          src: assets.video.lobbyFilled,
        },
      },
      loading: false,
      title: 'Challenge is in progress',
      leftCount: '1',
      leftTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In Game', color: '#FFD200' },
                { value: '0' },
              ],
            },
          ],
        },
      },
      rightCount: '1',
      rightTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'In Game', color: '#FFD200' },
                { value: '0' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: frontlandChallengeDetailsCardProps(breakpoint),
      liveUpdateList: frontlandLiveUpdates.slice(10, 16),
    },
    calculating: {
      status: 'calculating',
      pageMediaWithShadowBoxProps: {
        videoProps: {
          src: assets.video.lobbyFilled,
        },
      },
      loading: true,
      title: 'Challenge is completed Calculating winner',
      leftCount: '1',
      leftTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '5' },
              ],
            },
          ],
        },
      },
      rightCount: '1',
      rightTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '3' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: frontlandChallengeDetailsCardProps(breakpoint),
      liveUpdateList: frontlandLiveUpdates.slice(16, 22),
    },
    completed: {
      status: 'completed',
      pageMediaWithShadowBoxProps: {
        videoProps: {
          src: assets.video.lobbyFilled,
        },
      },
      loading: false,
      title: 'Challenge is completed!',
      leftCount: '1',
      leftTable: {
        variant: 'commands',
        withTrophy: true,
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '' },
                { value: '5' },
              ],
            },
          ],
        },
      },
      rightCount: '1',
      rightTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: '' },
                { value: '3' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: frontlandChallengeDetailsCardProps(breakpoint),
      liveUpdateList: frontlandLiveUpdates.slice(22, 28),
    },
    cancelled: {
      status: 'cancelled',
      pageMediaWithShadowBoxProps: {
        videoProps: {
          src: assets.video.lobbyFilled,
        },
      },
      loading: false,
      title: 'Challenge has been cancelled',
      leftCount: '1',
      leftTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      rightCount: '1',
      rightTable: {
        variant: 'commands',
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: frontlandChallengeDetailsCardProps(breakpoint),
      liveUpdateList: frontlandLiveUpdates.slice(28),
    },
  },
  fortnite: {
    waiting: {
      status: 'waiting',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: true,
      title: 'Waiting for an opponent',
      players: {
        ready: 1,
        total: 5,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(0, 4),
    },
    waiting2: {
      status: 'waiting2',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: true,
      title: 'Waiting for an opponent',
      players: {
        ready: 1,
        total: 20,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
            { cell: null },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(0, 4),
    },
    commencing: {
      status: 'commencing',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: true,
      title: 'Challenge is commencing',
      players: {
        ready: 3,
        total: 5,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
            { cell: null },
            { cell: null },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(4, 10),
    },
    progress: {
      status: 'progress',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: false,
      title: 'Challenge is in progress',
      timerExpirySeconds: 1854,
      players: {
        ready: 5,
        total: 5,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '0' },
                { value: '0' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'Not in game' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '0' },
                { value: '0' },
              ],
            },
            {
              cell: [
                { value: 'Nikman', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '0' },
                { value: '0' },
              ],
            },
            {
              cell: [
                { value: 'Birdman', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(10, 15),
    },
    progress2: {
      status: 'progress2',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: false,
      title: 'Challenge is in progress',
      timerExpirySeconds: 1120,
      players: {
        ready: 5,
        total: 5,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '4' },
                { value: '51 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '0' },
                { value: '0' },
              ],
            },
            {
              cell: [
                { value: 'Nikman', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '0' },
                { value: '0' },
              ],
            },
            {
              cell: [
                { value: 'Birdman', src: 'https://i.pravatar.cc/28' },
                { value: 'In lobby' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(15, 20),
    },
    progress3: {
      status: 'progress3',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: false,
      title: 'Challenge is in progress',
      timerExpirySeconds: 81,
      players: {
        ready: 5,
        total: 5,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '4' },
                { value: '51 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '2' },
                { value: '31 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '0' },
                { value: '0' },
              ],
            },
            {
              cell: [
                { value: 'Nikman', src: 'https://i.pravatar.cc/28' },
                { value: 'In game' },
                { value: '2' },
                { value: '15 p.' },
              ],
            },
            {
              cell: [
                { value: 'Birdman', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(20, 25),
    },
    calculating: {
      status: 'calculating',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: true,
      title: 'Calculating winner',
      players: {
        ready: 5,
        total: 5,
      },
      table: {
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '4' },
                { value: '51 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '2' },
                { value: '27 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '1' },
                { value: '12 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nikman', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '3' },
                { value: '36 p.' },
              ],
            },
            {
              cell: [
                { value: 'Birdman', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(25, 31),
    },
    completed: {
      status: 'completed',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: false,
      title: 'Challenge is completed!',
      winnerList: [
        {
          name: 'MasterChief',
          place: 1,
          amount: '+ 1.8',
          isVertical: false,
          avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
        },
        {
          name: 'Nikman',
          place: 2,
          amount: '+ 1.0',
          isVertical: false,
          avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
        },
      ],
      players: {
        ready: 5,
        total: 5,
      },
      table: {
        withTrophy: true,
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '4' },
                { value: '51 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '3' },
                { value: '36 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '2' },
                { value: '27 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nikman', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '1' },
                { value: '12 p.' },
              ],
            },
            {
              cell: [
                { value: 'Birdman', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(31),
    },
    completed2: {
      status: 'completed2',
      pageMediaWithShadowBoxProps: {
        imageProps: {
          src: assets.png.fortnitePageImg,
        },
      },
      loading: false,
      title: 'Challenge is completed!',
      winnerList: [
        {
          name: 'MasterChief',
          place: 1,
          amount: '+ 1.8',
          isVertical: false,
          avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
        },
        {
          name: 'Nikman',
          place: 2,
          amount: '+ 1.0',
          isVertical: false,
          avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
        },
        {
          name: 'Nickname',
          place: 3,
          amount: '+ 0.6',
          isVertical: false,
          avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
        },
      ],
      players: {
        ready: 5,
        total: 5,
      },
      table: {
        withTrophy: true,
        data: {
          headRow: {
            cell: [
              { value: 'Player' },
              { value: 'Placement' },
              { value: 'Kills' },
              { value: 'Score' },
            ],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '4' },
                { value: '51 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                { value: 'Top 25', color: '#56E612' },
                { value: '3' },
                { value: '36 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '2' },
                { value: '27 p.' },
              ],
            },
            {
              cell: [
                { value: 'Nikman', src: 'https://i.pravatar.cc/28' },
                { value: 'Died Early', color: '#56E612' },
                { value: '1' },
                { value: '12 p.' },
              ],
            },
            {
              cell: [
                { value: 'Birdman 1', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Birdman 2', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Birdman 3', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Birdman 4', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Birdman 5', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'Birdman 6', src: 'https://i.pravatar.cc/28' },
                { value: 'Disqualified', color: '#EEA40D' },
                { value: '' },
                { value: '' },
              ],
            },
          ],
        },
      },
      challengeDetailsCardProps: fortniteChallengeDetailsCardProps(breakpoint),
      liveUpdateList: fortniteLiveUpdates.slice(31),
    },
  },
})

export const leaderboard = {
  hotStreak: {
    withTrophy: true,
    rowCount: 8,
    data: {
      headRow: {
        cell: [{ value: 'Player' }, { value: 'Kills' }, { value: 'Date' }, { value: '' }],
      },
      bodyRow: [
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '8' },
            { value: '01.03.23' },
            {
              value: () => (
                <CoinImageWithAmount amount="+0.12" amountTypographyProps={{ color: 'gray.0' }} />
              ),
            },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '7' },
            { value: '01.03.23' },
            {
              value: () => (
                <CoinImageWithAmount amount="+0.12" amountTypographyProps={{ color: 'gray.0' }} />
              ),
            },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '4' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '4' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '4' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '4' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '3' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '3' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '3' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '3' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '3' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '3' },
            { value: '01.03.23' },
            { value: '' },
          ],
        },
      ],
    },
  },
  legendStreak: {
    withTrophy: true,
    rowCount: 8,
    data: {
      headRow: {
        cell: [{ value: 'Player' }, { value: 'Wins' }, { value: 'Date' }],
      },
      bodyRow: [
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '26' },
            { value: '18.08.21' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '21' },
            { value: '30.06.22' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '19' },
            { value: '06.02.22' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '18' },
            { value: '01.03.23' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '17' },
            { value: '05.02.22' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '15' },
            { value: '01.01.21' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '14' },
            { value: '01.03.23' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '11' },
            { value: '11.10.21' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '5' },
            { value: '01.03.23' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '5' },
            { value: '01.03.23' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '5' },
            { value: '01.03.23' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '5' },
            { value: '01.03.23' },
          ],
        },
      ],
    },
  },
  highestWin: {
    withTrophy: true,
    rowCount: 8,
    data: {
      headRow: {
        cell: [
          { value: 'Player' },
          { value: 'W/L', width: '68px' },
          { value: 'WIn%', width: '48px' },
        ],
      },
      bodyRow: [
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '41/8' },
            { value: '92%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '120/48' },
            { value: '87%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '84/31' },
            { value: '85%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '450/104' },
            { value: '84%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '39/17' },
            { value: '83%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '84/31' },
            { value: '82%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '41/25' },
            { value: '81%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '41/8' },
            { value: '79%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '39/7' },
            { value: '65%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '35/20' },
            { value: '60%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '25/30' },
            { value: '57%' },
          ],
        },
        {
          cell: [
            { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
            { value: '15/35' },
            { value: '55%' },
          ],
        },
      ],
    },
  },
  mostGamesThisWeek: {
    withTrophy: true,
    rowCount: 5,
    data: {
      headRow: {
        cell: [{ value: 'Player' }, { value: 'Games' }],
      },
      bodyRow: [
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '101' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '98' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '97' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '86' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '84' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
      ],
    },
  },
  mostGames24h: {
    withTrophy: true,
    rowCount: 5,
    data: {
      headRow: {
        cell: [{ value: 'Player' }, { value: 'Games' }],
      },
      bodyRow: [
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '18' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '17' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '16' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '15' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '14' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
        {
          cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: '5' }],
        },
      ],
    },
  },
}

export const profileChallengesBoxData = {
  title: 'Games',
  subTitle: 'Total challenges',
  totalGames: 25,
  winGames: 19,
  rows: [
    {
      title: 'This month',
      value: 8,
    },
    {
      title: 'Most Games 24H',
      value: 2,
    },
  ],
}

export const profileHotStreakBoxData = [
  { title: 'Longest Kills', value: 8, width: '23%' },
  { title: '5 Kills', value: 1, width: '26%' },
  { title: '3 Kills', value: 4, width: '25%' },
  { title: 'Win streak', value: 21, width: '26%' },
]

export const profileHistoryTable = {
  limitRows: 8,
  data: {
    headRow: {
      cell: [
        { value: 'Date' },
        { value: 'Game' },
        { value: 'Players' },
        { value: 'Status' },
        { value: '' },
      ],
    },
    bodyRow: [
      {
        cell: [
          { value: '20.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={1} />
                  <CoinImageWithAmount amount="+1.8" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '20.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: 'Disqualified',
            color: '#EEA40D',
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '19.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={3} />
                  <CoinImageWithAmount amount="+0.5" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '19.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.fortnite}
                text="fortnite"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: 'Lose',
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '19.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.fortnite}
                text="fortnite"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={3} />
                  <CoinImageWithAmount amount="+0.5" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '18.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={1} />
                  <CoinImageWithAmount amount="+0.12" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '18.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={1} />
                  <CoinImageWithAmount amount="+0.12" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '18.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={1} />
                  <CoinImageWithAmount amount="+0.12" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '17.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={1} />
                  <CoinImageWithAmount amount="+0.12" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
      {
        cell: [
          { value: '17.02.22' },
          {
            value: () => (
              <ImageWithTitleSmall
                icon={PNG_ICONS.frontland}
                text="frontland"
                mobileHideTitle={true}
              />
            ),
          },
          { value: '2' },
          {
            value: () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={1} />
                  <CoinImageWithAmount amount="+0.12" />
                </Stack>
              </>
            ),
          },
          {
            value: () => (
              <Link href="/" color="gray.25" style={{ display: 'flex' }} disabled>
                <SvgIcon name="arrow-right-line" />
              </Link>
            ),
          },
        ],
      },
    ],
  },
}

export const liveActivityTable = {
  data: {
    headRow: {
      cell: [{ value: 'Date' }, { value: 'Challenge' }, { value: 'User (s)' }, { value: 'Result' }],
    },
    bodyRow: [
      {
        cell: [
          { value: 'Just now' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '2 Min ago' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          {
            value: () => (
              <ImageWithTitleWrapper
                variant="avatar"
                icon="https://i.pravatar.cc/28"
                text="MaxFRY"
              />
            ),
          },
          {
            value: () => <CoinImageWithAmount amount="+0.85" />,
          },
        ],
      },
      {
        cell: [
          { value: '10 Min ago' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.fortnite} text="No Build BR" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">64 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '10 Min ago' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          {
            value: () => (
              <ImageWithTitleWrapper
                variant="avatar"
                icon="https://i.pravatar.cc/28"
                text="NName"
              />
            ),
          },
          {
            value: () => <CoinImageWithAmount amount="+2.00" />,
          },
        ],
      },
      {
        cell: [
          { value: '15 Min ago' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.fortnite} text="No Build BR" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">100 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '20 Min ago' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          {
            value: () => (
              <ImageWithTitleWrapper
                variant="avatar"
                icon="https://i.pravatar.cc/28"
                text="Nickname"
              />
            ),
          },
          {
            value: () => <CoinImageWithAmount amount="+1.20" />,
          },
        ],
      },
      {
        cell: [
          { value: 'Hour ago' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '20.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '21.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.fortnite} text="No Build BR" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">100 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '22.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '22.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '22.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '22.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '22.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
      {
        cell: [
          { value: '22.04.22' },
          {
            value: () => (
              <ImageWithTitleWrapper variant="game" icon={PNG_ICONS.frontland} text="1vs1" />
            ),
          },
          { value: () => <Typography variant="paragraphSmall">2 Players</Typography> },
          {
            value: 'Start',
          },
        ],
      },
    ],
  },
}

export const tournamentBannerCommonProps = {
  bodyImgSrc: PNG_ICONS.tournamentBanner,
  title: 'Show you skills',
  subTitle: 'Sing in for Tournament',
  bgText: 'Tournament',
  data: [
    {
      title: 'Players join',
      value: '4 / 64',
    },
    {
      icon: 'flag-2-line',
      title: 'Start date',
      value: '21 JUL 2023',
    },
    {
      icon: 'price-tag-3-line',
      title: 'Enter fee',
      value: '1.0 CC',
    },
    {
      icon: 'trophy-line',
      title: '3 prizes',
      value: ['40.0 CC', '20.0 CC', '15.0 CC'],
      otherPrizes: {
        value: '+3',
        tooltipTitle: 'Additional Prizes',
        tooltipText: 'For 4-th, 5-th and 6 place We have small Prizes in quantity of 5 CC',
      },
    },
  ] as TournamentIconTextPairProps[],
}

export const tournamentBannerTimerProps = {
  bodyImgSrc: PNG_ICONS.tournamentBanner,
  title: 'Show you skills',
  subTitle: 'Sing in for Tournament',
  bgText: 'Tournament',
  timer: {
    time: 1875010,
    status: 'joined',
    timerText: 'tournament starts in',
  } as TournamentBannerTimerProps,
  data: [
    {
      title: 'Players join',
      value: '4 / 64',
    },
    {
      icon: 'flag-2-line',
      title: 'Start date',
      value: '21 JUL 2023',
    },
  ] as TournamentIconTextPairProps[],
}

export const tableTournament = {
  currentUserId: '5',
  data: {
    headRow: {
      cell: [{ value: 'Player' }, { value: 'Status' }],
    },
    bodyRow: [
      {
        cell: [{ value: 'MasterChief', src: 'https://i.pravatar.cc/28' }, { value: 'Joined' }],
        userId: '1',
      },
      {
        cell: [{ value: 'Nickname', src: 'https://i.pravatar.cc/28' }, { value: 'Joined' }],
        userId: '2',
      },
      {
        cell: [{ value: 'Nickname Dude', src: 'https://i.pravatar.cc/28' }, { value: 'Joined' }],
        userId: '3',
      },
      {
        cell: [{ value: 'Nikman', src: 'https://i.pravatar.cc/28' }, { value: 'Joined' }],
        userId: '4',
      },
      {
        cell: [{ value: 'Birdman', src: 'https://i.pravatar.cc/28' }, { value: 'Joined' }],
        userId: '5',
      },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
      { cell: null },
    ],
  },
}

export const tournamentModalMiniTable = [
  { title: 'Total players', value: '64' },
  {
    title: 'Game',
    value: () => (
      <ImageWithTitleWrapper
        variant="small"
        icon={PNG_ICONS.frontland}
        text="Frontland"
        TypographyProps={{
          noWrap: false,
          sx: {
            width: 'auto !important',
          },
        }}
      />
    ),
  },
  { title: 'Tournament type', value: 'Single Elimination' },
  { title: 'Start date', value: '21 JUL 2023' },
  {
    title: 'Prize pool',
    value: () => (
      <TournamentPrizesRow
        showCoin={true}
        variant="smallText"
        prizes={['40.0', '20.0', '15.0']}
        otherPrizes={{
          value: '+3',
          tooltipTitle: 'Additional Prizes',
          tooltipText: 'For 4-th, 5-th and 6 place We have small Prizes in quantity of 5 CC',
        }}
        StackProps={{ sx: { transform: 'translateY(-2px)' } }}
      />
    ),
  },
]
