import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'


const sortOrder = (state:RootState, gameId:string|undefined) => state.pageReducer.display.filter((x:any) => x.gameId === gameId) 
 


export const getSortOrder = createSelector(
    sortOrder,
    (order) => {
        return order[0].sortOrder;
    }
);