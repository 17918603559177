import React, { useCallback, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { DialogProps, Box, Stack, useMediaQuery, Grid } from '@mui/material'
import DialogWrapper from 'src/components/DialogWrapper/DialogWrapper'
import { Avatar as AvatarType } from 'src/components/SelectAvatar/SelectAvatar'
import Typography from 'src/components/Typography/Typography'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Button from 'src/components/Button/Button'
import Avatar from 'src/components/Avatar/Avatar'

type Reason = 'backdropClick' | 'escapeKeyDown' | 'btnClick'

export type ChangeAvatarDialogProps = DialogProps & {
  onOk?: (item?: AvatarType) => void
  onCancel?: () => void
  avatars?: AvatarType[]
  selected?: AvatarType
}

export default function ChangeAvatarDialog({
  onClose,
  onOk,
  onCancel,
  avatars = [],
  selected,
  ...restDialogProps
}: ChangeAvatarDialogProps) {
  const [selectedAvatar, setSelectedAvatar] = useState<AvatarType | undefined>(selected)

  const theme = useTheme()
  const isUpSmView = useMediaQuery(theme.breakpoints.up('sm'))

  const handleClose = useCallback(
    (event: object, reason: Reason) => {
      setSelectedAvatar(selected)
      if (onClose) {
        // @ts-ignore
        onClose(event, reason)
      }
    },
    [onClose, selected]
  )

  return (
    <DialogWrapper
      {...restDialogProps}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '456px',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            margin: '16px',
          },
        },
      }}
    >
      <Box
        sx={() => ({
          border: '1px solid',
          borderColor: 'gray.700',
        })}
      >
        <Box
          sx={{
            m: '1px',
            bgcolor: 'gray.800',
            p: '24px',
          }}
        >
          <Stack gap="8px">
            <Stack alignItems="flex-end">
              <SvgIcon
                name="close-fill"
                sx={{ width: '18px', height: '18px', cursor: 'pointer' }}
                onClick={(e) => handleClose(e, 'btnClick')}
              />
            </Stack>
            <Typography variant="h5" align="center" color="gray.0">
              Change Avatar
            </Typography>
          </Stack>
          <Box
            sx={(theme) => ({
              mt: '24px',
              height: '328px',
              overflowY: 'auto',
              px: '13.5px',
              [theme.breakpoints.up('sm')]: {
                px: 0,
                height: '322px',
              },
            })}
          >
            <Grid container gap={{ xs: '8px', sm: '16px' }}>
              {avatars.map((avatar) => {
                const { id, src, component } = avatar
                return (
                  <Grid key={id} item>
                    {src ? (
                      <Avatar
                        size={isUpSmView ? 'medium' : 'small'}
                        selected={selectedAvatar?.src === avatar.src}
                        src={src}
                        onClick={() => setSelectedAvatar(avatar)}
                      />
                    ) : (
                      <Avatar
                        size={isUpSmView ? 'medium' : 'small'}
                        selected={selectedAvatar?.id === id}
                        component={component}
                        onClick={() => setSelectedAvatar(avatar)}
                      />
                    )}
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>
        <Stack p="1px" direction="row" gap="2px">
          <Button
            variant="contained"
            size="medium"
            onClick={() => onOk && onOk(selectedAvatar)}
            fullWidth
            startIcon={<SvgIcon name="check-fill" />}
            sx={{
              maxWidth: '357px',
            }}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            size="medium"
            onClick={() => {
              setSelectedAvatar(selected)
              if (onCancel) {
                onCancel()
              }
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </DialogWrapper>
  )
}
