import { createTheme } from '@mui/material'
import typographySettings from '../common/typography'
import palette from './palette'
import { MuiButton, MuiLink } from './components/components'
import MuiAlertTitle from './components/MuiAlertTitle'
import MuiAlert from './components/MuiAlert'
import MuiSvgIcon from './components/MuiSvgIcon'
import MuiInputLabel from './components/MuiInputLabel'
import MuiFormHelperText from './components/MuiFormHelperText'
import MuiInputBase from './components/MuiInputBase'
import MuiSelect from './components/MuiSelect'

let darkTheme = createTheme({
  typography: typographySettings.getTypography(),
  palette,
  breakpoints: {
    values: {
      xs: 0,
      xsm: 376,
      sm: 600,
      sm2: 800,
      md720: 720,
      md834: 834,
      md: 900,
      md2: 1025,
      md3: 1100,
      lg: 1200,
      lg2: 1289,
      lg3: 1320,
      lg4: 1345,
      xl: 1536,
      xl2: 1512,
    },
  },
  components: {
    MuiInputLabel,
    MuiInputBase,
    MuiFormHelperText,
    MuiSelect,
    MuiButton,
    MuiSvgIcon,
    MuiAlertTitle,
    MuiAlert,
    MuiLink,
    MuiCssBaseline: {
      styleOverrides: `
        ${typographySettings.fontFaces}
        /* Custom scroll START */
        /* Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: #4B5873 #1E283A;
        }
        /* Chrome, Edge and Safari */
        *::-webkit-scrollbar {
          width: 4px;
          width: 4px;
          height: 4px;
        }
        *::-webkit-scrollbar-track {
          border-radius: 2px;
          background-color: #1E283A;
        }
        *::-webkit-scrollbar-track:hover {background-color: #1E283A}
        *::-webkit-scrollbar-track:active {background-color: #1E283A}
        *::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: #4B5873;
        }
        *::-webkit-scrollbar-thumb:hover {background-color: #4B5873}
        *::-webkit-scrollbar-thumb:active {background-color: #4B5873}
        /* Custom scroll END */

        body {
          background: ${palette.gray[900]};
        }
        
        body:not(:has(.MuiModal-hidden)) {
          #root {
            .bottomRight {
              z-index: 0
            }
          }
        }
        
        body .Toastify#Notification .Toastify__toast-container {
          width: auto !important;
          padding: 0;
        }
        
        body .Toastify#Notification .Toastify__toast-container .Toastify__toast {
          min-height: auto !important;
          width: max-content;
        }
        
        body .Toastify#Notification .Toastify__toast-container.Toastify__toast-container--bottom-center {
          bottom: 8px !important;
          margin-left: 4px;
        }
        
        body .Toastify#Notification .Toastify__toast-theme--light {
          background: transparent;
        }
        
        body .Toastify#Notification .Toastify__toast-container.Toastify__toast-container--bottom-right {
          bottom: 8px;
          right: 24px;
          @media (max-width: 900px) {
            right: 20px;
            bottom: 4px;
          }
          @media (max-width: 600px) {
            right: 4px;
            bottom: 8px;
            left: auto;
          }
        }
        
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: typographySettings.variantMapping,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
})

darkTheme = createTheme(darkTheme, {
  typography: typographySettings.getTypography(darkTheme),
})

darkTheme.shadows.push(
  '0px 6.6501px 5.32008px rgba(0, 0, 0, 0.125207), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0871551)',
  '0px 4px 12px rgba(7, 8, 12, 0.25), 0px 12px 24px rgba(7, 8, 12, 0.25)'
)

export { darkTheme }
