const initialState = {
    joindialog: {
        showWait:false,
        status: undefined,
        title: undefined,
        subtitle: undefined,
        timeout: 0
    }
}

export function dialogReducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_WAIT_DIALOG":
            return {
                ...state,
                joindialog: {
                    ...state.joindialog,
                    showWait: action.showWait!==undefined ? action.showWait: state.joindialog.showWait,
                    title: action.title !== undefined ? action.title : state.joindialog.title,
                    subtitle: action.subtitle !== undefined ? action.subtitle : state.joindialog.subtitle,
                    timeout: action.timeout !== undefined ? action.timeout : state.joindialog.timeout,
                    status: action.status,
                   
                }
            }
        case "SHOW_WAIT_DIALOG":
            return {
                ...state,
                joindialog: {
                    ...state.joindialog,
                    showWait: true,
                    title: action.title !== undefined ? action.title : '',
                    subtitle: action.subtitle !== undefined ? action.subtitle : '',
                    timeout: action.timeout !== undefined ? action.timeout : 0,
                    status: action.status                   
                }
            }    
        case "RESET_WAIT_DIALOG":
            return {
                ...state,
                joindialog: {
                    ...state.joindialog,
                    showWait: false,
                    status: undefined,
                    title: '',
                    subtitle:'',
                    timeout: 0
                }
            }    
        default:
            return state
            
    }
}