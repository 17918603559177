import React from 'react'
import { Stack, StackProps } from '@mui/material'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type SvgIconWithTitleProps = {
  StackProps?: StackProps
  SvgIconProps?: SvgIconProps
  TypographyProps?: TypographyProps
} & React.PropsWithChildren

export default function SvgIconWithTitle({
  StackProps,
  SvgIconProps,
  TypographyProps,
  children,
}: SvgIconWithTitleProps) {
  return (
    <Stack direction="row" gap="8px" alignItems="center" {...StackProps}>
      {SvgIconProps?.name && <SvgIcon {...SvgIconProps} />}
      <Typography variant="paragraphMedium" color="gray.300" {...TypographyProps}>
        {TypographyProps?.children || children}
      </Typography>
    </Stack>
  )
}
