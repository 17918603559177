import React, { useMemo, useState } from 'react'
import { Box, useTheme, Stack, Avatar } from '@mui/material'
import { RiPercentLine, RiGift2Line, RiGift2Fill } from '@remixicon/react'
import SimpleTabs, {
  TabsProps,
  SimpleTabsWrapper,
  SimpleTabPanel,
} from 'src/components/SimpleTabs/SimpleTabs'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import BgLinesWrapper from 'src/components/BgLinesWrapper/BgLinesWrapper'
import Button from 'src/components/Button/Button'
import Typography from 'src/components/Typography/Typography'
import TextField from 'src/components/TextField/TextField'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import StakingTotal, {
  TotalItem,
  TotalValue,
  TotalValueOrStatus,
} from 'src/components/StakingTotal/StakingTotal'
import { PNG_ICONS } from 'src/assets/icons/icons'
import StakeSlider from 'src/components/StakeSlider/StakeSlider'
import { useFormik } from 'formik'
import * as schemas from 'src/helpers/schemas'
import * as yup from 'yup';
import { StakingType, TokenType } from 'src/helpers/types'
import { isTimestampGreaterThanCurrentLocalTime } from 'src/utils/datetime'
import { caclulatePotentialRewardInPercentage, calculatePotentialReward, calculatePotentialRewardFromStake } from 'src/helpers/stakingData'
import { FlexiStakingType, StakingFormType, VestedStakingType } from 'src/helpers/stakingTypes'
import { formatTokenToString, toTokenString } from 'src/utils/tokenUtil'

const tabs = ['Flexi', 'Vested']

export type StakingBoxProps = Partial<Pick<TabsProps, 'value' | 'onChange'>> & {
  flexiStaking?: FlexiStakingType
  vestedStaking?: VestedStakingType
  token?: string
  status?: string
  disabled?: boolean
  onSubmit?: (values: { value: number; type: StakingType  }) => void
  availableAmount?: number,
  sliderDisabled: boolean
}

const sx = {
  width: 16,
  height: 16,
}

export default function StakingBox({
  onChange,
  disabled,
  onSubmit,
  flexiStaking,
  vestedStaking,
  token,
  status,
  availableAmount,
  sliderDisabled,
}: StakingBoxProps) {
  const theme = useTheme()

  const [activeTab, setActiveTab] = useState<number>(0)
  const [flexiValue, setFlexiValue] = useState<string | number>('')
  const [vestedValue, setVestedValue] = useState<string | number>('')

  const handleTabs = (val: number) => {
    if (onChange) {
      onChange(val)
    } else {
      setActiveTab(val)
    }
  }

  

  const handleOnSubmit = (value:number, type:StakingType) => {
    if (onSubmit) {
      onSubmit({ value, type })
    }
  }

  return (
    <SimpleTabsWrapper showBorder>
      <SimpleTabs list={tabs} value={activeTab} onChange={handleTabs} showBorder={false} />
     
        <StakeTab
          isFlexi
          availableAmount={availableAmount}
          value={flexiValue}
          token={token}
          staking={flexiStaking}         
          disabled={sliderDisabled}
          activeTab={activeTab}
          tabIndex={0}
          handleOnSubmit={handleOnSubmit}
        />     
    
        <StakeTab
          availableAmount={availableAmount}
          value={vestedValue}
          token={token}
          staking={vestedStaking}
          disabled={sliderDisabled}
          activeTab={activeTab}
          tabIndex={1}
          handleOnSubmit={handleOnSubmit}
        /> 
     
    </SimpleTabsWrapper>
  )
}

type FlexiProps = {
  isFlexi?: boolean
  availableAmount?: number
  staking?: StakingFormType  
  token?: string
  value: number | string
  disabled: boolean
  activeTab: number
  tabIndex: number
  handleOnSubmit: (value:number, type:StakingType) => void
}

const ValidationSchema = (externalValue: number) => yup.object().shape({
  amount: schemas.amount(externalValue),
});

function StakeTab({ isFlexi, availableAmount, staking, token, value, disabled,activeTab,tabIndex,handleOnSubmit }: FlexiProps) {
  const theme = useTheme()

  const [sliderValue, setSliderValue] = useState(0);

  const formik = useFormik({
    initialValues: {
        amount: value as string,
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: ValidationSchema(availableAmount!==undefined ? availableAmount : 0),
    onSubmit: (values) => {
      formik.setStatus('');  
      handleOnSubmit(Number(values.amount), isFlexi ? StakingType.FLEXI : StakingType.VESTED);
      formik.resetForm();
      setSliderValue(0);
      formik.setSubmitting(false);
    }
  });

  const handleNumericChange = (e: { target: { value: any; name: string; }; }) => {
    const { value, name } = e.target;
    // Allow numbers and dot, but prevent multiple consecutive dots
    const filteredValue = value.replace(/[^0-9.]+/g, '').replace(/(\..*)\./g, '$1');
    // Update formik's state with the filtered value
    formik.setFieldValue(name, filteredValue);   
    // Update the slider value, clamping it between 0 and 100
    setSliderValue(filteredValue);
  };

  const onSliderChange = (value: number | number[]) => {
    // Update the slider state
    setSliderValue(value as number);    
    // Update the formik's amount field with the new amount, rounded if necessary

    formik.setFieldValue('amount',roundDownToSixDecimals(value as number));
  }

  const roundDownToSixDecimals = (value : number) => {
    return Math.floor(value * 1e6) / 1e6;
  };

  const rewardPeriodRunning = isTimestampGreaterThanCurrentLocalTime(staking?.currentPeriodFinish);
  const status = rewardPeriodRunning ? undefined : 'Period not Started';

  const potentialreward= useMemo(() => {
     return rewardPeriodRunning 
        ? 
        calculatePotentialRewardFromStake(staking?.totalStaked, staking?.accountBalance, formik.values.amount,staking?.rewardForDuration,
           staking?.currentRewardsDuration,staking?.currentPeriodFinish, staking?.accountEarned)
        : 
        undefined;
  }, [rewardPeriodRunning, formik.values.amount, staking]);

  const apy = useMemo(() => {
        return rewardPeriodRunning
        ?
        caclulatePotentialRewardInPercentage(staking?.rewardForDuration, potentialreward)
        :
        undefined;
  }
  ,[rewardPeriodRunning, potentialreward, staking?.rewardForDuration]);

  const potentialrewardString = potentialreward ? formatTokenToString(potentialreward,TokenType.CT,4,true,true,false) : undefined;
  
  const data = useMemo(
    () => [
      {
        label: 'Total Staked',
        value: <TotalValue value={staking?.totalStaked ? formatTokenToString(staking?.totalStaked,TokenType.CT,2,false,true,false):undefined} />,
        icon: <Avatar src={PNG_ICONS.coin} alt="Coin" sx={sx} />,
      },
      {
        label: 'Reward',
        value: <TotalValue value={apy} />,
        icon: <RiPercentLine size={sx.width} color={theme.palette.gray[300]} />,
      },
      {
        label: 'Potential Reward',
        value: <TotalValueOrStatus value={potentialrewardString} token={token} status={status} />,
        icon: potentialrewardString ? (
          <RiGift2Fill size={sx.width} color={theme.palette.primary.main} />
        ) : (
          <RiGift2Line size={sx.width} color={theme.palette.gray[300]} />
        ),
      },
    ],
    [
      staking?.totalStaked,
      apy,
      theme.palette.gray,
      theme.palette.primary.main,
      formik.values.amount,
      token,
      status,
    ]
  )

  return (
    <>    
     <SimpleTabPanel value={activeTab} index={tabIndex}>
      <form onSubmit={formik.handleSubmit}>
      <Box mb={{ xs: '15px', md834: '21px' }}>
        <Typography variant="h6" mb={{ xs: '8px', md834: '12px' }}>
          {isFlexi ? 'Staking to Flexi pool' : 'Staking to vested pool'}
        </Typography>
        <Typography variant="paragraphMedium" color={theme.palette.gray[100]} fontWeight={400}>
          {isFlexi ? 'Enter Amount to Stake in Flexi Pool' : 'Enter Amount to Stake in Vested Pool'}
        </Typography>
      </Box>
      <TextField
        id="amount" name="amount"
        type="number"
        label="Amount to stake"
        fullWidth
        value={formik.values.amount}
        disabled={disabled}
        onChange={handleNumericChange}
        error={(formik.touched.amount && Boolean(formik.errors.amount))}
        helperText={formik.touched.amount && formik.errors.amount !== undefined ? formik.errors.amount : undefined }
       
        sx={{
          '& input:hover, & input:focus': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
          },
        }}
      />
      <Box m={{ xs: '0px 7px 2px 0', md834: '5px 8px 3px 0' }}>
        <Stack direction="row" alignItems="center" gap="8px" justifyContent="flex-end">
          <Typography variant="paragraphSmall" color="gray.500">
            Available
          </Typography>
          <CoinImageWithAmount
            amount={formatTokenToString(toTokenString(availableAmount),TokenType.CT,6,true,true,true)}
            amountTypographyProps={{
              color: 'white',
              fontWeight: 600,
            }}
            avatarProps={{
              src: PNG_ICONS.coin,  
              sx: { width: '18px', height: '18px' },
            }}
          />
        </Stack>
      </Box>
      <Box
        mb="12px"
        minHeight="61px"
        sx={{
          [theme.breakpoints.up('xs')]: { mb: '17px', mx: '12px' },
          [theme.breakpoints.up('md834')]: { mb: '23px' },
        }}
      >
        <StakeSlider total={availableAmount} value={sliderValue} onChange={onSliderChange} disabled={disabled}/>
      </Box>
      <StakingTotal data={data} />
    
        <BgLinesWrapper BoxProps={{ sx: { py: '7px', px: '0px' } }}>
          <Button
            variant="spaceBetween"
            size="big"
            fullWidth
            endIcon={<SvgIcon name="arrow-right-line" />}
            type="submit" 
            disabled={!formik.dirty || formik.isSubmitting }
          >
            stake
          </Button>
        </BgLinesWrapper>
      
      </form>
      </SimpleTabPanel>
    
      
    </>
  )
}
