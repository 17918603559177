import React from 'react'
import { Stack, StackProps as MuiStackProps } from '@mui/material'
import Link from 'src/components/Link/Link'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

type MobileSocialLinks = {
  /** [The root element of this component uses the Stack component from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
}

export default function MobileSocialLinks({ StackProps }: MobileSocialLinks) {
  return (
    <Stack direction="row" justifyContent="center" gap="24px" {...StackProps}>      
      <Link
        href="https://twitter.com/challengedotgg"
        target="_blank"
        rel="noopener"
        color="secondary"
        size="large"
        sx={{
          width: '44px',
          height: '44px',
        }}
        startIcon={<SvgIcon name="twitter" />}
      />
      <Link
        href="https://discord.gg/vJxSfNBSpQ"
        target="_blank"
        rel="noopener"
        color="secondary"
        size="large"
        sx={{
          width: '44px',
          height: '44px',
        }}
        startIcon={<SvgIcon name="discord" />}
      />
      <Link
        href="https://t.me/IchallengeU"
        target="_blank"
        rel="noopener"
        color="secondary"
        size="large"
        sx={{
          width: '44px',
          height: '44px',
        }}
        startIcon={<SvgIcon name="telegram" />}
      />
    </Stack>
  )
}
