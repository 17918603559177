import {
  IndexRouteObject as IndexRouteObj,
  NonIndexRouteObject as NonIndexRouteObj,
} from 'react-router-dom'

export const ROUTE_PATH = {
  root: '/',
  registration: '/registration',
  startPage: '/',
  startPageLogin: '/',
  leaderboard: '/leaderboard',
  challenges: '/challenges',
  game: '/challenges/game/:gameId',
  lobby: '/lobby/:gameId',
  profile: '/profile',
  tournament: '/tournament',
  tournamentWaitingRoom: '/tournament',
  tournamentStatus: '/tournament/status/:statusId',
  staking: '/staking',
} as const

interface NonIndexRouteObject extends NonIndexRouteObj {
  label?: string
  disabled?: boolean
  isHeaderLink?: boolean
  children?: RouteObject[]
}

type RouteObject = IndexRouteObj | NonIndexRouteObject

export function getHeaderLinks(stakingMenu:boolean): RouteObject[] | [] {
return [
  {"path": ROUTE_PATH.root,"isHeaderLink":true,"label":"Staking"},
]

{/*
 return stakingMenu ? [
  {"path": ROUTE_PATH.root,"isHeaderLink":true,"label":"Startpage"},
  {"path": ROUTE_PATH.staking,"isHeaderLink":true,"label":"Staking"},
]:[
  {"path": ROUTE_PATH.root,"isHeaderLink":true,"label":"Startpage"},
  {"path": ROUTE_PATH.leaderboard,"isHeaderLink":true,"label":"Leaderboard"},
  {"path": ROUTE_PATH.challenges,"isHeaderLink":true,"label":"Challenges"},
  {"path": ROUTE_PATH.staking,"isHeaderLink":true,"label":"Staking"}
]; */}
}

export function getProfileHeaderLinks() {
  return [
    {"path":"/profile","isHeaderLink":true,"label":"General"},
  //  {"path":"/profile/assets","isHeaderLink":true, disabled:true, "label":"My Assets"},
  //  {"path":"/profile/other","isHeaderLink":true, disabled:true, "label":"Other"},
 ];
}
