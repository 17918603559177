import React, { useEffect, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Container, Grid, Stack, useTheme } from '@mui/material';
import { PNG_ICONS } from 'src/assets/icons/icons'
import assets from 'src/assets'
import { useNavigate } from 'react-router-dom';
import { getUser } from "../actions/userActions";
import { getSortedGameTemplates } from "../selectors/gameTemplateSelector";
import ChallengeCard from 'src/components/ChallengeCard/ChallengeCard'
import LoginMainBanner from 'src/components/LoginMainBanner/LoginMainBanner'
import MainBanner from 'src/components/MainBanner/MainBanner'
import HeroImageWrapper from '../components/HeroImageWrapper/HeroImageWrapper'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import GameBanner from 'src/components/GameBanner/GameBanner'
import DownloadBanner from 'src/components/DownloadBanner/DownloadBanner'
import UnderChallengeButton from 'src/components/UnderChallengeButton/UnderChallengeButton'
import ChallengeRangeListHeader from 'src/components/ChallengeRangeListHeader/ChallengeRangeListHeader'
import TotalListHeader from 'src/components/TotalListHeader/TotalListHeader'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Slider from 'src/components/Slider/Slider'
import LiveActivityNew from 'src/components/LiveActivityNew/LiveActivityNew'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import ctx from 'src/context/context'
import { getGameLink } from "src/selectors/gameLinkSelector";
import { ChallengeStatus, GameId } from "src/utils/const";
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { gameTemplateMapper, defaultData, mapSingleGameTemplate } from 'src/helpers/data'
import TournamentBanner from 'src/components/TournamentBanner/TournamentBanner'
import { mapOnTimeData } from 'src/helpers/tournamentData';
import { appendUrlPrefix, isTargetBlank, secondsLeft } from 'src/helpers/commonData';
import { TournamentStatus, TournamentType } from 'src/helpers/types';
import ModalSignup from 'src/components/ModalSignup/ModalSignup';
import getSortedOnTimeChallenges from 'src/selectors/sortedOnTimeChallenges';

const gameBannerCommonProps = {
    bodyImgSrc: PNG_ICONS.bgImg,
    gamesCount: 1,
    iconSrc: PNG_ICONS.frontland,
    title: 'Frontland',
}

export default function Home() {
    const theme = useTheme()
    const isMdView = useMediaQuery(theme.breakpoints.down('md'))
    const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
    const isLg3View = useMediaQuery(theme.breakpoints.down('lg3'))
    const isLessThan834 = useMediaQuery(theme.breakpoints.down('md834'))

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [currentGameTemplate, setCurrentGameTemplate] = React.useState(defaultData);
    const auth = ctx.useAuth()
    console.log("isloggenid " + auth.isWalletConnected);    
    const blockchain = ctx.useBlockchain();

    const gameTemplateCount = useAppSelector(state => state.gameReducer.gameTemplates.length);
    
    //const currentTournament : TournamentType = useAppSelector(state => state.tournamentReducer.currentTournament);
    const sortedOnTimeChallenges =  useMemo(() => getSortedOnTimeChallenges, []);
    const onTimeChallenges: TournamentType[] = useAppSelector(state => sortedOnTimeChallenges(state));
    
    const tournamentData = mapOnTimeData(onTimeChallenges);

    //when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector
    //instance , see https://github.com/reduxjs/reselect#q-can-i-share-a-selector-across-multiple-component-instances

    const sortedGamesCollection = useMemo(() => getSortedGameTemplates, []);

    const result = useAppSelector(state => sortedGamesCollection(state, undefined, undefined, true, undefined));

    const gameTemplates = gameTemplateMapper(result.collection);
    const isRoofTopGameTemplatesDisabled = result.disabled;

    const linkPart = useAppSelector(state => getGameLink(state, GameId.FRONTLAND));
    const frontlandGameLink = appendUrlPrefix(linkPart);
    const targetBlank = isTargetBlank(linkPart);
  
    useEffect(() => {       
        if (blockchain.isWalletConnected && blockchain.isNetworkMismatch === false) {              
                dispatch(getUser());
        }        
    }, [blockchain.isWalletConnected,blockchain.isNetworkMismatch, dispatch]); 


    return (
        <>
            <Box sx={{ position: 'relative', background: '#040811' }}>
                <Container
                    maxWidth="lg2"
                    disableGutters
                    sx={auth.isWalletConnected ?
                        { pt: { xs: '28px', sm: '12px' }, mb: { xs: '36px', md2: '28px' } }
                        :
                        {
                            pt: '32px',
                            pb: '52px',
                            [theme.breakpoints.down('md')]: {
                                pt: '12px',
                                pb: '42px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                pb: '36px',
                                pt: '16px',
                            },
                        }
                    }>
                    {auth.isWalletConnected && auth.user ? < LoginMainBanner user={auth.user} /> : <MainBanner />}
                </Container>
                <HeroImageWrapper src={assets.video.hero}>
                    {isLessThan834 ? (
                        <Box>
                            <TitleWithDot rootStackProps={{ spacing: '6px' }}>Suggested Challenges</TitleWithDot>
                            <Box mt="20px" mb="15px">
                                <Slider>
                                    {
                                        gameTemplates.map((item: any, key) => (
                                            <ChallengeCard key={`challenge-card-${item.title}-${key}`}
                                                {...item}
                                                disabled={item.gameTemplate.disabled}
                                                btnProps={{
                                                    ...item.btnProps,
                                                    onClick: auth.isWalletConnected ? () => { setCurrentGameTemplate({ open: true, challengeCard: item }); } : (undefined),
                                                }}
                                            />
                                        ))
                                    }
                                </Slider>
                                <UnderChallengeButton dataSource={[
                                    { label: 'Available', value: gameTemplateCount ? gameTemplateCount : 0 },
                                    { label: 'Games', value: 2 },
                                ]} linkProps={{ label: 'See all challenges', href: "/challenges" }} />
                            </Box>
                        </Box>
                    ) : (
                        <Box pt={{ xs: '23px', sm: '27px', md: '42px' }}>
                            <Grid container gap="28px" flexWrap="nowrap">
                                <Grid
                                    item
                                    sx={{
                                        flexGrow: 1,
                                        [theme.breakpoints.down('lg3')]: {
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        },
                                    }}
                                >
                                    <Grid container justifyContent={'space-between'}>
                                        <Box pt="1px">
                                            <TitleWithDot rootStackProps={{ spacing: { xs: '10px', md: '6px' } }}>
                                                Suggested Challenges
                                            </TitleWithDot>
                                        </Box>
                                        {!isSmView && (
                                            <ChallengeRangeListHeader
                                                startNumber={1}
                                                endNumber={gameTemplates?.length}
                                                text="List of challenges"
                                                StackProps={{ mt: { xs: '23px', md: '0' } }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        container
                                        py="28px"
                                        spacing={{ xs: '16px', sm: '28px' }}
                                        sx={{
                                            [theme.breakpoints.down('md')]: {
                                                pt: '21px',
                                                pb: '28px',
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                pt: '19px',
                                                pb: '35px',
                                            },
                                        }}
                                    >
                                        {
                                            gameTemplates.map((item: any, index) => (
                                                <Grid item xs={3} key={`challenge-card-${item.title}-${index}`}>
                                                    <ChallengeCard key={`challenge-card-${item.title}-${index}`}
                                                        {...item}
                                                        disabled={item.gameTemplate.disabled}
                                                        btnProps={{
                                                            ...item.btnProps,
                                                            onClick: auth.isWalletConnected ? () => { setCurrentGameTemplate({ open: true, challengeCard: item }); } : (undefined),
                                                        }}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    <UnderChallengeButton dataSource={[
                                        { label: 'Available', value: gameTemplateCount ? gameTemplateCount : 0 },
                                        { label: 'Games', value: 2 },
                                    ]} linkProps={{ label: 'See all challenges', href: "/challenges" }} />
                                </Grid>
                                {!isLg3View && (
                                    <Grid item sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                       
                                        <TableWrapper
                                            title="Live Activity"
                                            width="412px"
                                            height="auto"
                                            padding="12px 10px 0"
                                            bodyStackProps={{ pt: '6px', pr: '5px', pb: '17px', pl: '6px' }}
                                        >
                                            <LiveActivityNew                                             
                                                TableContainerProps={{
                                                    sx: { maxHeight: '461px' },
                                                }}
                                            />
                                        </TableWrapper>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    )}
                </HeroImageWrapper>
                {isLg3View && (
                    <Box mt={{ xs: '24px', sm: '18px' }}>
                        <TableWrapper
                            title="Live Activity"
                            width="100%"
                            height="auto"
                            padding={{ xs: '5px 2px 0', sm: '12px 11px 0' }}
                            bodyStackProps={{
                                pt: '6px',
                                pr: '5px',
                                pb: '17px',
                                pl: '6px',
                                sx: {
                                    [theme.breakpoints.down('sm')]: {
                                        pb: '12px',
                                        pl: '9px',
                                        pr: '9px',
                                    },
                                },
                            }}
                        >
                            <LiveActivityNew
                                TableContainerProps={{
                                    sx: { maxHeight: { xs: '391px', sm2: '461px' } },
                                }}
                            />
                        </TableWrapper>
                    </Box>
                )}
                <Container maxWidth="xl2" disableGutters>
                    <Container maxWidth="lg2" disableGutters>
                       { tournamentData && tournamentData.map((tournament, index) => (
                            <TournamentBanner
                                key={index}
                                {...tournament}
                                buttonText= {onTimeChallenges[index].isTournamentBase ? 
                                    tournament.buttonText 
                                        : 
                                    (!onTimeChallenges[index].isParticipating && onTimeChallenges[index].status===ChallengeStatus.NOT_STARTED 
                                        ? 'Join Now' : 'View')}
                                BoxProps={{ mt: { xs: '36px', md: '48px' } }}
                                onClick={() => 
                                    onTimeChallenges[index].isTournamentBase ?
                                        ((!auth.isWalletConnected || (!onTimeChallenges[index].isParticipating && onTimeChallenges[index].tournamentStatus===TournamentStatus.NOT_STARTED)) 
                                            ? 
                                            navigate(`/tournaments/${onTimeChallenges[index].tournamentId}/signup`) 
                                            : 
                                            navigate(`/tournaments/${onTimeChallenges[index].tournamentId}`) 
                                        )
                                      :
                                        (auth.isWalletConnected 
                                            ?
                                            ((!onTimeChallenges[index].isParticipating && onTimeChallenges[index].status===ChallengeStatus.NOT_STARTED)
                                                ?
                                                setCurrentGameTemplate({ open: true, challengeCard: mapSingleGameTemplate(onTimeChallenges[index]) }) 
                                                :
                                                navigate(`/lobby/${onTimeChallenges[index].id}`) 
                                            )
                                           
                                            : 
                                            (undefined))
                                    }
                                timer={{
                                    status: onTimeChallenges[index].isParticipating ?  'joined': 'notJoined',
                                    time: onTimeChallenges[index].startTime ? secondsLeft(onTimeChallenges[index].startTime as Date,0) : 0,
                                }}
                            />
                        ))}                            
                        <Box
                            position="relative"
                            zIndex={5}
                            pt="48px"
                            pb="81px"
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    pb: '36px',
                                    pt: '35px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    pb: '31px',
                                },
                            }}
                        >
                            <Grid container spacing={{ xs: '0', md: '29px' }}>
                                <Grid item xs>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <TitleWithDot rootStackProps={{ spacing: '7px', mt: '2px' }} dotProps={{ bottom: '10px' }}>
                                            Available Games
                                        </TitleWithDot>
                                        <TotalListHeader total={2} text="total games" />
                                    </Stack>
                                    <Stack
                                        pt="27px"
                                        direction="column"
                                        gap="14px"
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                gap: '12px',
                                                pt: '19px',
                                            },
                                        }}
                                    >
                                        <GameBanner
                                            {...gameBannerCommonProps}
                                            onClick={() => navigate('/challenges/game/FRONTLAND')}
                                        />
                                        <GameBanner
                                            {...gameBannerCommonProps}
                                            gamesCount={2}
                                            bodyImgSrc={PNG_ICONS.bgImg2}
                                            iconSrc={PNG_ICONS.fortnite}
                                            title="Fortnite"
                                            onClick={() => navigate('/challenges/game/FORTNITE')}
                                        />
                                    </Stack>
                                </Grid>
                                {!isMdView && (
                                    <Grid item>
                                        <DownloadBanner
                                            headertitle={frontlandGameLink!=="" ? 'Install' : 'Coming soon!'}
                                            title="Frontland"
                                            imageProps={{ src: PNG_ICONS.bgAvailable, alt: 'Game banner' }}
                                            description="Frontland is a multiplayer online game that allows players to engage in fast-paced and intense battles in a third-person perspective. The game is built on Unreal Engine 5"
                                            linkProps={{
                                                href: frontlandGameLink,
                                                disabled: frontlandGameLink!=="" ? false : true,
                                                target: targetBlank ? '_blank' : undefined,
                                                startIcon: <SvgIcon name="windows-fill" />,
                                                children: 'Download for free',

                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Container>
                </Container>
                <ModalSignup 
                    data={currentGameTemplate.challengeCard} 
                    open={currentGameTemplate.open && auth.isWalletConnected} 
                    onCancel={()=>setCurrentGameTemplate(defaultData)} 
                    handleJoin={() => setCurrentGameTemplate(defaultData)}
                    setOpen={()=>setCurrentGameTemplate(defaultData)}
                />               
            </Box>
        </>
    );

}

