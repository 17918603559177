import React from 'react'
import { Box, BoxProps as MuiBoxProps, useTheme, useMediaQuery } from '@mui/material'
import Link from 'src/components/Link/Link'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import MenuItemButtonList, {
  MenuItemButtonListProps,
} from 'src/components/MenuItemButtonList/MenuItemButtonList'
import UploadAvatar, { UploadAvatarProps } from 'src/components/UploadAvatar/UploadAvatar'
import GameSidebar, { GameSidebarProps } from 'src/components/GameSidebar/GameSidebar'
{/*
export const menuItemButtonList = [
  {
    id: 'statistics',
    startIcon: <SvgIcon name="bar-chart-box-line" />,
    label: 'Statistics',
  },
  {
    id: 'myassets',
    startIcon: <SvgIcon name="list-check" />,
    label: 'My assets',
  },
  {
    id: 'loginInfo',
    startIcon: <SvgIcon name="contacts-line" />,
    label: 'Account info',
  },
  {
    id: 'other',
    disabled: true,
    startIcon: <SvgIcon name="settings-5-line" />,
    label: 'Other',
  },
]
*/}
export const menuItemButtonList = [
  {
    id: 'loginInfo',
    startIcon: <SvgIcon name="contacts-line" />,
    label: 'Account info',
  },
  

]

type ProfileMenuProps = {
  /** The root element of this component uses the BoxProps from Material UI  */
  BoxProps?: MuiBoxProps
  /**
   * [Props applied to the UploadAvatar element.](/?path=/docs/components-uploadavatar--basic)
   * */
  UploadAvatarProps: UploadAvatarProps
  /**
   * [Props applied to the MenuItemButtonList element.](/?path=/docs/components-menuitembuttonlist--basic)
   * */
  MenuItemButtonListProps?: MenuItemButtonListProps
  onLogout?: () => void
}

export default function ProfileMenu({
  BoxProps,
  UploadAvatarProps,
  MenuItemButtonListProps: {
    list = menuItemButtonList,
    selectedId,
    onMenuItemClick,
    ...restMenuItemButtonListProps
  } = {},
  onLogout,
}: ProfileMenuProps) {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm2'))

  if (isMobileView) {
    return (
      <MobileProfileMenu
        list={list}
        selectedId={selectedId ?? ''}
        onSelect={onMenuItemClick}
        UploadAvatarProps={UploadAvatarProps}
      />
    )
  }

  return (
    <Box
      bgcolor="gray.800"
      border="1px solid"
      borderColor="gray.700"
      minWidth="191px"
      minHeight="726.28px"
      display="flex"
      flexDirection="column"
      sx={{
        [theme.breakpoints.down('md')]: {
          minWidth: '174px',
        },
      }}
      {...BoxProps}
    >
      <UploadAvatar {...UploadAvatarProps} />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        borderTop="1px solid"
        borderColor="gray.700"
        bgcolor="gray.900"
      >
        <Box flexGrow={1}>
          <MenuItemButtonList
            list={list}
            selectedId={selectedId}
            onMenuItemClick={onMenuItemClick}
            {...restMenuItemButtonListProps}
          />
        </Box>
        <Box p="18px" display="flex" justifyContent="center">
          <Link
            href="#"
            size="small"
            color="secondary"
            startIcon={<SvgIcon name="logout-box-line2" />}
            onClick={onLogout}
          >
            Log out
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

type MobileProfileMenuProps = GameSidebarProps & {
  UploadAvatarProps: UploadAvatarProps
}

function MobileProfileMenu({
  list,
  selectedId,
  onSelect,
  UploadAvatarProps,
}: MobileProfileMenuProps) {
  return (
    <div>
      <UploadAvatar {...UploadAvatarProps} />
      <GameSidebar
        breakpoint="sm2"
        list={list}
        selectedId={selectedId}
        onSelect={onSelect}
        shadowBoxProps={{ sx: { top: '190px' } }}
      />
    </div>
  )
}
