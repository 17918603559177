import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles'
import Dialog, { Box, DialogProps, Stack, Typography, useMediaQuery } from '@mui/material';
import DialogTitle from '@mui/material';
import DialogContent from '@mui/material';
import Button from 'src/components/Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { metamaskWallet } from '@thirdweb-dev/react';
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle';
import { WalletType } from 'src/helpers/types';

    type Reason = 'backdropClick' | 'escapeKeyDown' | 'btnClick'

    export type WalletSelectDialogProps = DialogProps & {
        walletSelected: (wallet: string) => void
        onCancel?: () => void
    }

    function SelectWalletDialog({
        onClose,
        walletSelected,
        onCancel,
        open,
        ...restDialogProps
    }: WalletSelectDialogProps) {
  

    const theme = useTheme();
    const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
    const isMdView = useMediaQuery(theme.breakpoints.down('md'))
    const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
    useEffect(() => {
        setIsMetaMaskInstalled(typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask);
      }, []);

    const handleClose = useCallback(
        (event: object, reason: Reason) => {
        console.log('handleClose');
        if (onClose) {
            // @ts-ignore
            onClose(event, reason)
        }
        },
        [onClose]
    )

    const aciveWalletSelected = (wallet: string) => {
        console.log('aciveWalletSelected',wallet);
        if(wallet === WalletType.METAMASK && !isMetaMaskInstalled){
            //Open new tab to install metamask
            window.open('https://metamask.io/download.html', '_blank');
            return;
        }
        walletSelected(wallet);
    };

    return (
        <DialogWrapper
        open={open}
        {...restDialogProps}
        onClose={handleClose}
        sx={{
            '& .MuiPaper-root': {
            maxWidth: '286px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                margin: '16px',
            },
            },
        }}
        >
        <Box
            sx={() => ({
            border: '1px solid',
            borderColor: 'gray.700',
            })}
        >
            <Box
            sx={{
                m: '1px',
                bgcolor: 'gray.800',
                p: '24px',
            }}
            >
                <Stack gap="8px">
                <Stack alignItems="flex-end">
                <SvgIcon
                    name="close-fill"
                    sx={{ width: '18px', height: '18px', cursor: 'pointer' }}
                    onClick={(e) => handleClose(e, 'btnClick')}
                />
                </Stack>
                <Typography variant="h5" align="center" color="gray.0">
                Connect
                </Typography>
            </Stack>
            <Box  display="flex" justifyContent="center" alignItems="center"
                sx={(theme) => ({
                mt: '14px',
                height: '74px',
                overflowY: 'auto',
                px: '13.5px',
                [theme.breakpoints.up('sm')]: {
                    px: 0,
                    height: '70px',
                },
                })}               
            >
                 <SvgIcon name="wallet-line" sx={{ width: '40px', height: '40px'}}/>
            </Box>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'}>
        
                            <Button
                                startIcon={<SvgIcon name="metamask" sx={{ width: '40px', height: '40px', cursor: 'pointer' }}/>}
                                fullWidth
                                onClick={() => {
                                    aciveWalletSelected(WalletType.METAMASK);
                                }}
                                sx={{ justifyContent: 'flex-start' }}
                                >
                                <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h7">Metamask</Typography>
                                <Typography variant="body2" color="white">{isMetaMaskInstalled  ? 'Installed' : 'Not installed'}</Typography>
                                </Box>
                            </Button>
            
                        </Stack>
                        <Stack direction={'row'}>
                        <Button
                            startIcon={<SvgIcon name="embedded" sx={{ width: '40px', height: '40px', cursor: 'pointer' }}/>}
                            fullWidth
                            onClick={() => {
                                aciveWalletSelected(WalletType.EMBEDDED);
                            }}
                            sx={{ justifyContent: 'flex-start' }}
                            >
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h7">Email</Typography>
                                <Typography variant="body2" color="lightgray"></Typography>
                                </Box>
                        </Button>
                        </Stack>
                    </Stack>
                </Box>
        </Box>
        </DialogWrapper>
    );
}

export default SelectWalletDialog;