import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps as MuiBoxProps } from '@mui/material'
import Typography from '../Typography/Typography'

export type ExchangeRatesProps = {
  /** [The root element of this component uses the Box component from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  ExchangeRateProps?: Partial<ExchangeRateProps>
  data: { label: string; value: string; BoxProps?: MuiBoxProps }[]
}

export type ExchangeRateProps = {
  /** [The root element of this component uses the Box component from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  label: string
  value: string
}

const ExchangeRate = ({ BoxProps, label, value }: ExchangeRateProps) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      gap="12px"
      p="4px 12px"
      bgcolor="gray.800"
      {...BoxProps}
    >
      <Typography
        color="gray.200"
        variant="uiElement"
        sx={{
          whiteSpace: 'nowrap',
          flexGrow: 1,
          [theme.breakpoints.down('xsm')]: {
            width: '57px',
            textAlign: 'center',
          },
          [theme.breakpoints.down(theme.breakpoints.values.xsm - 1)]: {
            width: '57px',
            textAlign: 'left',
          },
        }}
      >
        {label}
      </Typography>
      <Box width="1px" height="24px" bgcolor="gray.600" />
      <Typography
        color="gray.25"
        variant="paragraphSmall"
        sx={{
          whiteSpace: 'nowrap',
          flexGrow: 1,
          [theme.breakpoints.down('xsm')]: {
            width: '57px',
          },
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default function ExchangeRates({
  BoxProps,
  ExchangeRateProps,
  data,
  ...restProps
}: ExchangeRatesProps) {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      gap="1px"
      border="1px solid"
      borderColor="gray.700"
      p="1px"
      minHeight="36px"
      {...BoxProps}
      sx={{
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        [theme.breakpoints.down(theme.breakpoints.values.xsm - 1)]: {
          flexWrap: 'wrap',
        },
        ...BoxProps?.sx,
      }}
    >
      {!!data?.length &&
        data.map((item) => (
          <ExchangeRate key={item.label} {...item} {...ExchangeRateProps} {...restProps} />
        ))}
    </Box>
  )
}
