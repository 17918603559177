import { SortOrder } from "src/helpers/types";
import { GameId } from 'src/utils/const';
import { menuItemButtonList } from "src/components/ProfileMenu/ProfileMenu";
const initialState = {
    currentGameId: GameId.FRONTLAND,
    currentLeaderboardGameId: GameId.FRONTLAND,
    currentProfileMenu: menuItemButtonList[0].id,
    display:[        
        {
            gameId: GameId.FRONTLAND,
            sortOrder: SortOrder.FEE_LOW 
        },       
        {
            gameId: GameId.FORTNITE,
            sortOrder: SortOrder.FEE_LOW 
        }
    ]
};


export const pageReducer = (state=initialState, action) => {
    switch (action.type) {
        case "SET_CURRENT_GAME":
            return {
                ...state,
                currentGameId: action.gameId,               
            }
        case "SET_CURRENT_LEADERBOARD_GAME":
            return {
                ...state,
                currentLeaderboardGameId: action.gameId,               
            }
        case "SORT_TEMPLATES":
            return{
                ...state,
                display: state.display.map((item, index) => {
                    if(item.gameId === action.gameId){
                       return {
                            ...item,
                            sortOrder: action.sortOrder
                       };
                    }
                    return item;
                })
            }        
        case "SET_CURRENT_PROFILE_MENU":    
            return {
                ...state,
                currentProfileMenu: action.menuId,               
            }
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }

}