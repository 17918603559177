import React, { Fragment, useState } from 'react'
import {
  Stack,
  StackProps,
  useTheme,
  styled,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Box,
} from '@mui/material'
import { RiQuestionLine, RiQuestionFill } from '@remixicon/react'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.gray[600],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px',
    borderRadius: 0,
    backgroundColor: theme.palette.gray[600],
  },
}))

type PeriodInfoProps = {
  label?: string
  percent: string
  releasedAt: string
  periods?: string[]
  StackProps?: StackProps
  FirstRow?: StackProps
  SecondRow?: StackProps
  PercentRow?: StackProps
  ReleasedAtLabel?: TypographyProps
  ReleasedAtValue?: TypographyProps
  SvgIconProps?: SvgIconProps
  Percent?: TypographyProps
}

export default function PeriodInfo({
  label = 'Released at',
  percent,
  releasedAt,
  periods,
  StackProps,
  FirstRow,
  SecondRow,
  PercentRow,
  ReleasedAtLabel,
  ReleasedAtValue,
  SvgIconProps,
  Percent,
}: PeriodInfoProps) {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Stack gap="8px" {...StackProps}>
      <Stack gap="8px" direction="row" alignItems="center" {...FirstRow}>
        <Typography variant="paragraphSmall" color="gray.300" {...ReleasedAtLabel}>
          {label}
        </Typography>
        <Stack
          gap="4px"
          bgcolor="gray.800"
          borderRight="1px solid"
          borderColor="gray.500"
          p="0px 4px"
          direction="row"
          alignItems="center"
          {...PercentRow}
        >
          <SvgIcon
            name="refund-CC-line"
            sx={{ width: '10px', height: '10px', color: 'gray.600', ...SvgIconProps?.sx }}
            {...SvgIconProps}
          />
          <Typography
            variant="uiElement"
            color="gray.500"
            position="relative"
            top="2px"
            {...Percent}
          >
            {percent}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap="8px" direction="row" alignItems="center" {...SecondRow}>
        <Typography
          variant="description"
          color="gray.0"
          {...ReleasedAtValue}
          sx={{
            [theme.breakpoints.up('xsm')]: {
              ...theme.typography.bodySmall,
            },
            ...ReleasedAtValue?.sx,
          }}
        >
          {releasedAt}
        </Typography>
        <CustomTooltip
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          placement="top"
          arrow
          title={
            <Fragment>
              <Typography variant="paragraphSmall" color="common.white">
                Vested Periods
              </Typography>
              <Box mt="4px">
                {periods?.map((period) => (
                  <Typography key={period} variant="bodySmall" color="gray.100">
                    {period}
                  </Typography>
                ))}
              </Box>
            </Fragment>
          }
        >
          <Box display="flex">
            {open ? <RiQuestionFill size={14} /> : <RiQuestionLine size={14} />}
          </Box>
        </CustomTooltip>
      </Stack>
    </Stack>
  )
}
