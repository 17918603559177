import { Box, BoxProps, useTheme } from '@mui/material'
import React from 'react'

type LinesProps = BoxProps

export default function PatternLinesRegistration({ width = 50, height = 50, sx }: LinesProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        background: theme.palette.background.linesFooter,
        width,
        height,
        ...sx,
      }}
    />
  )
}
