import React from 'react'
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  SvgIcon,
  ButtonBase,
} from '@mui/material'

enum AvatarSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

type AvatarProps = MuiAvatarProps & {
  component?: React.ElementType<any>
  selected?: boolean
  onClick?: () => void
  size?: `${AvatarSize}`
}

export default function Avatar(props: AvatarProps) {
  const { size = AvatarSize.MEDIUM, component, selected, src, onClick, ...restProps } = props

  if (component) {
    return (
      <ButtonBase onClick={onClick}>
        <SvgIcon
          component={component}
          viewBox={size === AvatarSize.MEDIUM ? '0 0 68 68' : '0 0 60 60'}
          sx={(theme) => ({
            fill: 'none',
            ...(size === AvatarSize.MEDIUM && {
              width: '68px',
              height: '68px',
            }),
            ...(size === AvatarSize.SMALL && {
              width: '60px',
              height: '60px',
            }),
            '& rect': {
              strokeWidth: '1px',
              stroke: theme.palette.gray[600],
              ...(size === AvatarSize.MEDIUM && {
                width: '67px',
                height: '67px',
              }),
              ...(size === AvatarSize.SMALL && {
                width: '59px',
                height: '59px',
              }),
              x: 1,
              y: 1,
            },
            ...(selected && {
              strokeWidth: '2px',
              stroke: theme.palette.primary.main,
              '& rect': {
                ...(size === AvatarSize.MEDIUM && {
                  width: '66px',
                  height: '66px',
                }),
                ...(size === AvatarSize.SMALL && {
                  width: '58px',
                  height: '58px',
                }),
                x: 1,
                y: 1,
              },
            }),
          })}
        />
      </ButtonBase>
    )
  } else if (src) {
    return (
      <ButtonBase onClick={onClick}>
        <MuiAvatar
          src={src}
          variant="square"
          sx={(theme) => ({
            ...(size === AvatarSize.MEDIUM && {
              width: '68px',
              height: '68px',
            }),
            ...(size === AvatarSize.SMALL && {
              width: '60px',
              height: '60px',
            }),
            border: '1px solid',
            borderColor: 'gray.600',
            ...(selected && {
              border: '2px solid',
              borderColor: theme.palette.primary.main,
            }),
          })}
          {...restProps}
        />
      </ButtonBase>
    )
  } else {
    return null
  }
}
