
export function onTimeChallenges(challenges) {
    return { type: "ON_TIME_CHALLENGES", challenges: challenges };
}

export function onTimeChallengeNew(challenge) {
    return { type: "NEW_ON_TIME_CHALLENGE", challenge: challenge };
}   

export function onTimeEvent(onTimeEvent) {
    return { type: "ON_TIME_EVENT", onTimeEvent: onTimeEvent };
}

export function onTimeError(onTimeError) {
    return { type: "ON_TIME_ERROR", onTimeError: onTimeError };
}

export function tournamentEvent(tournamentEvent) {
    return { type: "TOURNAMENT_EVENT", tournamentEvent: tournamentEvent };
}

export function tournamentChallengeEvent(challengeEvent) {
    return { type: "TOURNAMENT_CHALLENGE_EVENT", challengeEvent: challengeEvent };
}

export function tournamentUpdated(tournament) {
    return { type: "TOURNAMENT_UPDATED", tournament: tournament };
}   

export function tournamentRoundResultEvent(tournamentRoundResult) {
    return { type: "TOURNAMENT_ROUND_RESULT_EVENT", tournamentRoundResult: tournamentRoundResult };
}

export function tournamentResult(challengeResult){
    return { type: "TOURNAMENT_CHALLENGE_RESULT", challengeResult: challengeResult };
}

export function tournamentRoundIsStarting(tournamentId, round){
    return { type: "TOURNAMENT_ROUND_IS_STARTING", tournamentId: tournamentId, round: round };
}

export function tournamentRoundIsFinishing(tournamentId){
    return { type: "TOURNAMENT_ROUND_IS_FINISHING", tournamentId: tournamentId };
}