import { TournamentStatus } from 'src/helpers/types'
import { TournamentType } from 'src/helpers/types';

interface State {
     onTimeChallenges: TournamentType[];
  }

const initialState:State = {
     onTimeChallenges: [],
};


export function tournamentReducer(state = initialState, action:any) {
    switch (action.type) {       
        case "ON_TIME_CHALLENGES":
            return {
                ...state,
                onTimeChallenges: action.challenges,
            };
        case "NEW_ON_TIME_CHALLENGE":
            return {
                ...state,
                onTimeChallenges: [...state.onTimeChallenges, action.challenge],
            };
        case "CHALLENGE_UPDATE":            
            const id = action.challengeUpdate.challengeId;
            const updatedUserId = action.userId;

            return {
                ...state,
                onTimeChallenges: state.onTimeChallenges.map((challenge) => {
                    if(challenge.id=== id){ //previously id was challenge.challengeId but challengeId is only for tournaments
                        return {
                            ...challenge,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== undefined ? action.challengeUpdate.hasInfluenser : challenge.hasInfluenser,
                            influenserDisplayName: typeof action.challengeUpdate.hasInfluenser !== undefined ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserDisplayName : null) : challenge.influenserDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            isParticipating: (typeof updatedUserId !== undefined && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : challenge.isParticipating
                        };
                    }
                    return challenge;
                }),
            };         
            
        case "ON_TIME_EVENT" :
                const challengeId = action.onTimeEvent.challengeId;
    
                return {
                    ...state,
                    onTimeChallenges: state.onTimeChallenges.map((challenge) => {
                        if(challenge.id === challengeId){
                            return {
                                ...challenge,
                                status: action.onTimeEvent.status,
                                prices: action.onTimeEvent.prices !== undefined ? action.onTimeEvent.prices : challenge.prices,
                                pricePotAfterCut: action.onTimeEvent.pricePotAfterCut!==undefined ? action.onTimeEvent.pricePotAfterCut : challenge.pricePotAfterCut,
                            };
                        }
                        return challenge;
                    }),
                };

        case "ON_TIME_ERROR" :
            const errorChallengeId = action.onTimeError.challengeId;

            return {
                ...state,
                onTimeChallenges: state.onTimeChallenges.map((challenge) => {
                    if(challenge.id === errorChallengeId){
                        return {
                            ...challenge,
                            error: true,
                            errorDescription: action.onTimeError.message,
                        };
                    }
                    return challenge;
                }),
            };

        case "TOURNAMENT_EVENT" :
            const tournamentId = action.tournamentEvent.tournamentId;

            return {
                ...state,
                onTimeChallenges: state.onTimeChallenges.map((challenge) => {
                    if(challenge.tournamentId === tournamentId){
                        return {
                            ...challenge,
                            tournamentStatus: action.tournamentEvent.tournamentStatus,
                        };
                    }
                    return challenge;
                }),
            };

        case "TOURNAMENT_UPDATED":
            const updatedTournamentId = action.tournament.tournamentId;

            return {
                ...state,
                onTimeChallenges: state.onTimeChallenges.map((challenge) => {
                    if(challenge.tournamentId === updatedTournamentId){
                        return {
                            ...challenge,
                            tournamentStatus: action.tournament.tournamentStatus,
                            status: action.tournament.status,
                            prices: action.tournament.prices,
                            pricePotAfterCut: action.tournament.pricePotAfterCut,
                        };
                    }
                    return challenge;
                }),
            };

        default:
            return state;
    }
}