import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Stack, StackProps } from '@mui/material'
import Corner from '../Corner/Corner'
import Typography from '../Typography/Typography'
import { useTimer } from 'react-timer-hook'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import { Link } from 'react-router-dom'
import { link } from 'fs'
import Button, { ButtonProps } from '../Button/Button'

export type TimerToastProps = {
  text?: string
  stackProps?: StackProps
  closeToast?: () => void
  expirySeconds: number
  onExpire?: () => void
  dateLabels?: {
    day?: string
    hrs?: string
    min?: string
    sec?: string
  }
  link?: string
  btnProps?: ButtonProps
  hideButton?: boolean,
}

export default function TimerToast({
  text = 'Tournament starts in:',
  stackProps,
  closeToast,
  expirySeconds,
  onExpire,
  dateLabels,
  link,
  hideButton = false,
  btnProps,
  ...restProps
}: TimerToastProps) {
  const theme = useTheme()

  const { sx, ...restStackProps } = stackProps || {}

  const expiryDate: Date = new Date(new Date().setSeconds(new Date().getSeconds() + expirySeconds))

  const handleExpire = () => {
    if (onExpire && typeof onExpire === 'function') {
      onExpire()
    }
  }

  const {
    seconds,
    minutes,
    hours,
    days,
    // isRunning, // isRunning variable is needed if you need to display the text when the counting reaches zero
  } = useTimer({ expiryTimestamp: expiryDate, onExpire: handleExpire })

  const { day = 'd', hrs = 'hrs', min = 'min', sec = 'sec' } = dateLabels || {}

  return (
    <Corner variant="bottomRight" {...restProps}>
      <Stack
        direction="row"
        maxWidth="402px" //Previous 324px
        minHeight="64px"
        bgcolor="gray.800"
        padding="9px 9px 9px 13px"
        alignItems="center"
        gap="13px"
        border="1px solid"
        borderColor="gray.700"
        sx={{
          // @ts-ignore
          boxShadow: theme.shadows[26],
          ...sx,
        }}
        {...restStackProps}
      >
        {link ? (
        <Link to={link}>
          <Typography variant="description" color="gray.0" flexGrow={1}>
            {text}
          </Typography>
        </Link>
        ) : (
          <Typography variant="description" color="gray.0" flexGrow={1}>
            {text}
          </Typography>
        )
        }
        <Stack direction="row" alignItems="baseline" bgcolor="gray.700" px="8px">
          {days > 0 && (
            <>
              <Typography variant="h4" color="primary.light" mr="5px">
                {days >= 10 ? days : `0${days}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                {day}
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="12px" mr="14px">
                :
              </Typography>
            </>
          )}
          {hours > 0 && (
            <>
              <Typography variant="h4" color="primary.light" mr="5px">
                {hours >= 10 ? hours : `0${hours}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                {hrs}
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="12px" mr="14px">
                :
              </Typography>
            </>
          )}
          <>
            <Typography variant="h4" color="primary.light" mr="5px">
              {minutes >= 10 ? minutes : `0${minutes}`}
            </Typography>
            <TitleWithDot
              rootStackProps={{ spacing: '2px' }}
              typographyProps={{ variant: 'h7', color: 'gray.0' }}
              dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
            >
              {min}
            </TitleWithDot>
          </>
          <>
            <Typography alignSelf="center" variant="h6" color="gray.0" ml="12px" mr="14px">
              :
            </Typography>
            <Typography variant="h4" color="primary.light" mr="5px">
              {seconds >= 10 ? seconds : `0${seconds}`}
            </Typography>
            <TitleWithDot
              rootStackProps={{ spacing: '2px' }}
              typographyProps={{ variant: 'h7', color: 'gray.0' }}
              dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
            >
              {sec}
            </TitleWithDot>
          </>
        </Stack>
        {!hideButton && 
          <Button variant="contained" size="small" {...btnProps}>
             {btnProps?.children || 'open tournament'}
          </Button>
        }
      </Stack>
    </Corner>
  )
}
