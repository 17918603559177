import { createSelector } from '@reduxjs/toolkit';
import { ActiveChallengeType } from 'src/helpers/types';
import type { RootState } from 'src/store/configureStore';

// Input selector to get activeChallenges from state
const selectActiveChallenges = (state: RootState) => state.challengeReducer.activeChallenges;

// Factory function to order activeChallenges by isTournamentBase descending and then by tournamentId?
export const selectActiveChallengesOrdered = createSelector(
    [selectActiveChallenges],
    (activeChallenges: ActiveChallengeType[]) => {
        return activeChallenges.sort((a, b) => {
            if (a.isTournamentBase === b.isTournamentBase) {
                return a.tournamentId! - b.tournamentId!;
            }
            return a.isTournamentBase ? -1 : 1;
        });
    }
);






