import { Box, BoxProps, Stack, StackProps as MuiStackProps, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import React from 'react'

type StakingSumItemProps = {
  title: string
  description: string
  icon: React.ReactNode
  StackProps?: MuiStackProps
  IconBoxProps?: BoxProps
  TextBoxProps?: BoxProps
  TitleTypographyProps?: TypographyProps
  DescriptionTypographyProps?: TypographyProps
}

function StakingSumItem({
  title,
  description,
  icon,
  StackProps,
  IconBoxProps,
  TitleTypographyProps,
  DescriptionTypographyProps,
  TextBoxProps,
}: StakingSumItemProps) {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      gap={'13px'}
      alignItems="center"
      flexGrow={1}
      justifyContent="flex-start"
      {...StackProps}
    >
      <Box
        {...IconBoxProps}
        sx={{
          width: '48px',
          height: '48px',
          background: theme.palette.gray[800],
          border: `1px solid ${theme.palette.gray[700]}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...IconBoxProps?.sx,
        }}
      >
        {icon}
      </Box>
      <Box {...TextBoxProps} sx={{ ...TextBoxProps?.sx }}>
        <Typography
          variant="h7"
          {...TitleTypographyProps}
          sx={{ mb: '5px', ...TitleTypographyProps?.sx }}
        >
          {title}
        </Typography>
        <Typography
          variant="paragraphSmall"
          {...DescriptionTypographyProps}
          sx={{ color: `${theme.palette.gray[500]}`, ...DescriptionTypographyProps?.sx }}
        >
          {description}
        </Typography>
      </Box>
    </Stack>
  )
}

export default StakingSumItem
