import React from 'react'
import { Box, useTheme } from '@mui/material'

enum Position {
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomRight = 'bottomRight',
}
type PositionType = `${Position}` // Co "Enum Position" values to input options

export type PageCornerProps = {
  /**
   * Position of the lines
   */
  position: PositionType
  /**
   * Width of the lines
   */
  width?: number
  /**
   * Height of the lines
   */
  height?: number
  /**
   * If true, the point will be displayed
   */
  dot?: boolean
  /**
   * If true, the lines will be stretched to the full width of the viewport
   */
  fullWidth?: boolean
}

export default function PageCorner(props: PageCornerProps) {
  const { width = 16, height = 16 } = props // default lines values width and height
  const theme = useTheme()
  const before: React.CSSProperties = {}
  const after: React.CSSProperties = {}
  const dotPosition: React.CSSProperties = {}
  const thickness = 4 // Thickness lines
  const dotMarginGap = 16 // Point margin from the main line
  const dotMargin = (props.height ? props.height : height) + dotMarginGap // Point margin from viewport
  const zIndex = theme.zIndex.modal + 1

  // Position blocks in the left-top corner
  if (props.position === Position.TopLeft) {
    // Dot vertical line
    before.height = '100vh'
    before.width = thickness
    before.top = 0
    before.left = 0
    before.transform = `translateY(${props.fullWidth ? '0' : `calc(-100% + ${props.height}px)`})`

    // Dot horizontal line
    after.height = thickness
    after.width = '100vw'
    after.top = 0
    after.left = 0
    after.transform = `translateX(${props.fullWidth ? '0' : `calc(-100% + ${props.width}px)`})`

    // Dot position
    dotPosition.top = dotMargin
    dotPosition.left = 0
  }
  // Position blocks in the right-top corner
  if (props.position === Position.TopRight) {
    // Dot vertical line
    before.height = props.height ? props.height : height
    before.width = thickness
    before.top = 0
    before.right = 0

    // Dot horizontal line
    after.height = thickness
    after.width = props.width ? props.width : width
    after.top = 0
    after.right = 0

    // Dot position
    dotPosition.display = 'none'
  }
  // Position blocks in the bottom-right corner
  if (props.position === Position.BottomRight) {
    // Dot vertical line
    before.height = '100vh'
    before.width = thickness
    before.right = 0
    before.bottom = 0
    before.transform = `translateY(${props.fullWidth ? '0' : `calc(100% - ${props.height}px)`})`

    // Dot horizontal line
    after.height = thickness
    after.width = '100vw'
    after.right = 0
    after.bottom = 0
    after.transform = `translateX(${props.fullWidth ? '0' : `calc(100% - ${props.width}px)`})`

    // Dot position
    dotPosition.right = 0
    dotPosition.bottom = dotMargin
  }

  return (
    <Box
      component="span"
      className={props.position}
      sx={{
        '&:before': {
          content: '" "',
          position: 'fixed',
          display: 'block',
          background: theme.palette.primary.main,
          zIndex: zIndex,
          transition: 'transform 0.3s ease-in-out',
          ...before,
        },
        '&:after': {
          content: '" "',
          position: 'fixed',
          display: 'block',
          background: theme.palette.primary.main,
          zIndex: zIndex,
          transition: 'transform 0.3s ease-in-out',
          ...after,
        },
      }}
    >
      {props.dot && (
        <Box
          component="span"
          sx={{
            position: 'fixed',
            display: 'block',
            background: theme.palette.primary.main,
            zIndex: zIndex,
            height: 4,
            width: 4,
            ...dotPosition,
          }}
        ></Box>
      )}
    </Box>
  )
}
