import React from 'react'
import { Stack, StackProps as MuiStackProps, Box } from '@mui/material'
import TrophyNumber from '../TrophyNumber/TrophyNumber'
import Typography from '../Typography/Typography'
import typographySettings from 'src/themes/common/typography'
import TooltipStyled from '../TooltipStyled/TooltipStyled'
import CoinImageWithAmount from '../CoinImageWithAmount/CoinImageWithAmount'
import Image, { ImageProps } from 'src/components/Image/Image'

enum Variants {
  BigText = 'bigText',
  SmallText = 'smallText',
  Banner = 'banner',
}

type ModifiedTypographySettings = Omit<typeof typographySettings.typography, 'fontFamily'>

type VariantStyleT = {
  [key in Variants]: {
    textColor: string
    textVariant: keyof ModifiedTypographySettings
    otherPrizesBg: string
    otherPrizesPaddingX: string
    otherPrizesPaddingY: string
    textTranslateY: string
  }
}

export type OtherPrizesT = {
  value: string
  tooltipTitle: string
  tooltipText: string
}

export type TournamentPrizesRowProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  prizes?: string[]
  otherPrizes?: OtherPrizesT
  prizeIconSrc?: string
  variant?: `${Variants}`
  showCoin?: boolean
  coinAmount?: string
}

function TournamentPrizesRow({
  prizes,
  otherPrizes,
  prizeIconSrc,
  variant = Variants.SmallText,
  StackProps,
  showCoin,
  coinAmount,
}: TournamentPrizesRowProps) {
  return (
    <Stack direction="row" gap="10px" {...StackProps}>
      {showCoin && (
        <CoinImageWithAmount
          amount={coinAmount ? coinAmount : ''}
          avatarProps={{ src: prizeIconSrc}}
          sx={{ transform: 'translateY(2px)' }}
        />
      )}      
      {prizes &&                   
                    
        prizes.map((item, index) => (
          <Stack key={index} direction="row" gap="2px" alignItems="flex-end">
            <TrophyNumber place={index + 1} />
            <Typography
              variant={variantStyle[variant].textVariant}
              color={variantStyle[variant].textColor}
              sx={{ transform: `translateY(${variantStyle[variant].textTranslateY})` }}
            >
              {item}
            </Typography>
          </Stack>
        ))}

      {otherPrizes && (
        <TooltipStyled
          title={otherPrizes.tooltipTitle}
          content={otherPrizes.tooltipText}
          TooltipProps={{ arrow: true, py: '12px' }}
        >
          <Stack
            direction="row"
            alignItems="flex-end"
            minWidth="22px"
            minHeight="22px"
            bgcolor={variantStyle[variant].otherPrizesBg}
            px={variantStyle[variant].otherPrizesPaddingX}
            py={variantStyle[variant].otherPrizesPaddingY}
            borderRadius="1px"
            sx={{ cursor: 'default' }}
          >
            <Typography variant="bodySmall" color="gray.50">
              {otherPrizes.value}
            </Typography>
          </Stack>
        </TooltipStyled>
      )}
    </Stack>
  )
}

const variantStyle: VariantStyleT = {
  [Variants.BigText]: {
    textColor: 'gray.25',
    textVariant: 'h7',
    textTranslateY: '2px',
    otherPrizesBg: 'gray.800',
    otherPrizesPaddingX: '4px',
    otherPrizesPaddingY: '2px',
  },
  [Variants.SmallText]: {
    textColor: 'gray.50',
    textVariant: 'bodySmall',
    textTranslateY: '0',
    otherPrizesBg: 'none',
    otherPrizesPaddingX: '0',
    otherPrizesPaddingY: '0',
  },
  [Variants.Banner]: {
    textColor: 'gray.25',
    textVariant: 'h7',
    textTranslateY: '2px',
    otherPrizesBg: 'gray.800',
    otherPrizesPaddingX: '4px',
    otherPrizesPaddingY: '2px',
  },
}

export default TournamentPrizesRow
