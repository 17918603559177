import React from 'react'
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'

// Only include variant, size, and color
// type ButtonBaseProps = Pick<MuiButtonProps, "variant" | "size" | "color">;

// Use all except onFocusVisible
export type ButtonProps = Omit<MuiButtonProps, 'onFocusVisible'>

export default function Button({ children, variant = 'contained', ...restProps }: ButtonProps) {
  return (
    <MuiButton variant={variant} {...restProps}>
      {children}
    </MuiButton>
  )
}
