import React, { useState, useCallback,useMemo,useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Stack, Collapse } from '@mui/material'
import { ROUTE_PATH } from 'src/routes'
import Button from 'src/components/Button/Button'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import HeaderTokens from 'src/components/HeaderTokens/HeaderTokens'
import HeaderUserCell from 'src/components/HeaderUserCell/HeaderUserCell'
import Logo from 'src/components/Logo/Logo'
import MenuItem from 'src/components/MenuItem/MenuItem'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import MobileHeader from 'src/components/Header/MobileHeader'
import Drawer from 'src/components/Drawer/Drawer'
import { TokenType, UserType, WalletType } from 'src/helpers/types';
import { ExchangeRateTicker } from 'src/components/Ticker/ExchangeRateTicker'
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getProfilePicture } from 'src/helpers/commonData';
import { embeddedWallet, metamaskWallet, useConnect, useDisconnect, useEmbeddedWallet, walletConnect } from '@thirdweb-dev/react';
import WalletSelectDialog from '../SelectWalletDialog/SelectWalletDialog';
import { showErrorPopup } from 'src/actions/dialogActions';
import SignerManager from 'src/utils/signerManager';
import { parseMetamaskError } from 'src/utils/errorParser';
import { PNG_ICONS } from 'src/assets/icons/icons';
import { BrowserClient, feedbackIntegration, getClient } from "@sentry/react";
import { EnvironmentType, config } from 'src/config/config';
import { formatTokenToStringWithSeparator } from 'src/utils/tokenUtil';

export type HeaderProps = {
    isWalletConnected: boolean,
    chainId: number,
    user: UserType,
    logout: () => void
}

export default function Header({ isWalletConnected, chainId, user,logout }: HeaderProps) {
    const [cartWalletModalIsOpen, setCartWalletModalIsOpen] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const isWorking = useAppSelector(state => state.userReducer.working);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const theme = useTheme()
    const isLessThan1300 = useMediaQuery(theme.breakpoints.down(1300))
    const isLessThan1114 = useMediaQuery(theme.breakpoints.down(1114))
    const isLessThan1000 = useMediaQuery(theme.breakpoints.down(1000))
    const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
    const isMdView = useMediaQuery(theme.breakpoints.down('md'))
    const isLessThan834 = useMediaQuery(theme.breakpoints.down('md834'))
    const isLessThan720 = useMediaQuery(theme.breakpoints.down('md720'))

    const client = getClient<BrowserClient>();
    const feedback = feedbackIntegration(client);

    const connectTo = useConnect();
    const metamaskConfig = metamaskWallet(
        {projectId: '633be614e1a54f275007f6cb5dd5327d', connectionMethod: 'walletConnect', recommended: true} //metamaskBrowser
      );

    const embeddedConfig = embeddedWallet({
      auth: {
        options: ["email", "google", "facebook", "apple"],
      },
      recommended: true,
    });

    const walletConnectConfig = walletConnect( {projectId: '633be614e1a54f275007f6cb5dd5327d',qrModal: "walletConnect"});  
    const { connect } = useEmbeddedWallet();
    const disconnect = useDisconnect();    
      
    const onLogout = async (event: React.KeyboardEvent | React.MouseEvent) => {
      logout();    
      toggleDrawer(event)
    }

    const walletSelected = async (wallet: string): Promise<void> =>{
      setCartWalletModalIsOpen(false);
      if (wallet === WalletType.METAMASK) {
        try{
          const wallet = await connectTo(metamaskConfig,{chainId: chainId});
          const signer = await wallet.getSigner();
          SignerManager.signer = signer;
          dispatch({ type: 'LOGIN_USER_SMART_WALLET', loginType: WalletType.METAMASK});
        }
        catch(error){
            const msg = parseMetamaskError(error);
            dispatch(showErrorPopup('Connect error', msg));
            await disconnect();
            console.log(error)
        }
      }
      else if (wallet === WalletType.EMBEDDED) {
        try{          
          const wallet = await connect({            
            strategy: "iframe",
          });
          const email = await wallet.getEmail();
          const signer = await wallet.getSigner();
          SignerManager.signer = signer;
          dispatch({ type: 'LOGIN_USER_SMART_WALLET', loginType: WalletType.EMBEDDED, email: email});
        }
        catch(error){
          dispatch(showErrorPopup('Connect error', (error as Error).message));
          await disconnect();
          console.log(error)
        }
      }
      else{
        dispatch(showErrorPopup('Wallet not supported'));
      }
    }   
   
    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setIsDrawerOpen((prevState) => !prevState)
    }

    const navLinks = useMemo(
      () => [
        {
          label: 'Staking',
          href: ROUTE_PATH.startPage,
        }
      ],
      []
    )
    const navLinksStaking = useMemo(
      () => [
        {
          label: 'Staking',
          href: ROUTE_PATH.startPage,
        }       
       
      ],
      []
    )

    {/* 
  const navLinks = useMemo(
      () => [
        {
          label: 'Startpage',
          href: ROUTE_PATH.startPage,
        },
        {
          label: 'Leaderboard',
          href: ROUTE_PATH.leaderboard,
        },
        {
          label: 'Challenges',
          href: ROUTE_PATH.challenges,
        },
        {
          label: 'Staking',
          href: ROUTE_PATH.staking,
        }
      ],
      []
    )

  

    const navLinksStaking = useMemo(
      () => [
        {
          label: 'Startpage',
          href: ROUTE_PATH.startPage,
        },       
        {
          label: 'Staking',
          href: ROUTE_PATH.staking,
        },
      ],
      []
    )
*/}
    const isStakingPage = true; //location.pathname===ROUTE_PATH.staking;
    const tokenIcon = isStakingPage ? PNG_ICONS.coins : PNG_ICONS.weth;
    const tokenCount = isStakingPage ? user.coins?.challengeCoins : user.coins?.weth;
    const tokenType = isStakingPage ? TokenType.CT : TokenType.WETH;

    const renderMenuItems = useCallback(() => {
      if (!isWalletConnected && isMdView) {
        return null
      }
      if (isWalletConnected && isLessThan1300) {
        return null
      }
   
      const menuItems = !isStakingPage ? navLinks : navLinksStaking;
    
    return (
        <Stack
          direction="row"
          spacing="28px"
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="nowrap"
        >
          {menuItems.map((item, index) => {
            return (
              <MenuItem key={`header-menu-item-${item.label}-${index}`} href={item.href}>
                {item.label}
              </MenuItem>
            )
          })}
        </Stack>
      )
    }, [isWalletConnected, isLessThan1300, isMdView,isStakingPage])

    const renderExchangeRates = useCallback(() => {
      if (isWalletConnected && isLessThan1000) {
        return null
      }
      if (!isWalletConnected && isLessThan1114 && !isMdView) {
        return null
      }
      return (
        <ExchangeRateTicker /> // BoxProps={{ width: 255 }}
      )
    }, [isWalletConnected, isLessThan1000, isLessThan1114, isMdView])

    const renderConnectWalletBtn = useCallback(() => {
      return (
        <Collapse in={!isWalletConnected} orientation="horizontal">
         <Button
            endIcon={<SvgIcon name="wallet-line" sx={{ marginRight: '4px' }} />}
            sx={{ minWidth: isMdView ? '217px' : '172px' }}
            variant="contained"
            size={isMdView ? 'big' : 'medium'}
            onClick={() => setCartWalletModalIsOpen(true)}
            disabled={isWorking}
          >
            Connect wallet
          </Button>         
        </Collapse>
      )
    }, [isWalletConnected, isMdView,isWorking])

    const renderUserMenu = useCallback(() => {
      return (
        <Stack direction="row" alignItems="center">         
          <Collapse in={isWalletConnected} orientation="horizontal">
            <Stack direction="row" gap="24px" alignItems="center">
             
                <HeaderTokens 
                  tokenImgSrc={tokenIcon}
                  tokenCount={formatTokenToStringWithSeparator(tokenCount,3,tokenType)} />

              <HeaderUserCell
                user={{
                  name: user.nickname,
                  avatarUrl: getProfilePicture(user.profilePictureUrl,"") as string,
                  isOnline: true,
                }}
                onClick={()=>navigate('/profile')}
              />
            </Stack>
          </Collapse>
          {renderConnectWalletBtn()}
        </Stack>
      )
    }, [isWalletConnected, renderConnectWalletBtn, tokenCount, user.profilePictureUrl,isStakingPage])

    const renderMenuIcon = useCallback(() => {
      if (!isWalletConnected && !isLessThan1114) {
        return null
      } else if (isWalletConnected && !isLessThan1300) {
        return null
      }
      return (
        <ButtonIcon
          variant={isDrawerOpen ? 'contained' : 'outlined'}
          size={isMdView || (isWalletConnected && isLessThan1300) ? 'big' : 'medium'}
          onClick={toggleDrawer}
        >
          <SvgIcon name={isDrawerOpen ? 'close-fill' : 'menu-fill'} />
        </ButtonIcon>
      )
    }, [isWalletConnected, isLessThan1300, isMdView, isDrawerOpen, !isLessThan1114])

    const renderHeaderContent = useCallback(() => {
      return (
        <Box
          minHeight="64px"
          display="flex"
          gap={isLgView ? '39.5px' : '48px'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={isLgView ? '28.5px' : '47.5px'} alignItems="center">
            <Logo variant="logo-colored" size="small" onClick={()=> navigate('/')} />
            {renderExchangeRates()}
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            gap={isWalletConnected && isLessThan1300 ? '8px' : '48px'}
          >
            {config.environment!==EnvironmentType.PRODUCTION && feedback &&
              <Button 
                onClick={async () => {
                  const form = await feedback.createForm({});
                  form.appendToDom();
                  form.open();
                }}>
                  report a bug
              </Button>
            }
            {renderMenuItems()}
        <Stack direction="row" alignItems="center" gap="8px">
            {renderUserMenu()}
            {renderMenuIcon()}
        </Stack>
          </Box>
        </Box>
      )
    }, [
      isWalletConnected,
      isLessThan1300,
      isLgView,
      renderExchangeRates,
      renderMenuIcon,
      renderMenuItems,
      renderUserMenu,
    ])  
  
    return (
      <>
        {(!isWalletConnected && isLessThan834) || (isWalletConnected && isLessThan720) ? (
          <>
            <MobileHeader
              isWalletConnected={isWalletConnected}
              isDrawerOpen={isDrawerOpen}
              onToggleDrawer={toggleDrawer}
              disableConnectButton={isWorking}
              setCartWalletModalIsOpen={setCartWalletModalIsOpen}
              user={user}
            />
            {/*<Popup
              open={cartWalletModalIsOpen}
              title="You are not Login"
              iconData={{
                name: 'user-locked',
                fontSize: 'extraLarge',
              }}
              description="To participate in a challenge, you must be registered on app.challenge.gg"
              onClose={() => {
                setCartWalletModalIsOpen(false)
              }}
              primaryButtonData={{
                onClick: () => {
                  setCartWalletModalIsOpen(false)
                  dispatch(loginWallet())                
                },
                label: 'Connect wallet',
                endIcon: (
                  <SvgIcon
                    name="wallet-line"
                    sx={{
                      [theme.breakpoints.down('sm')]: { mr: '5px' },
                    }}
                  />
                ),
              }}
            />*/}
             <WalletSelectDialog 
                open={cartWalletModalIsOpen}  
                onClose={() => setCartWalletModalIsOpen(false)}
                onCancel={() => setCartWalletModalIsOpen(false)}
                walletSelected={walletSelected}
              />
          </>
        ) : (
          <Box
            minHeight="94px"
            py="15px"
            px="16px"
            bgcolor="gray.900"
            position="sticky"
            top={0}
            zIndex={theme.zIndex.drawer + 1}
          >
            {renderHeaderContent()}
          </Box>
        )}
        <Drawer
          isWalletConnected={isWalletConnected}
          open={isDrawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          onLogout={onLogout}
          user={user}
          stakingMenu={isStakingPage}
          onConnect={
            ()=> {setCartWalletModalIsOpen(true)
            setIsDrawerOpen(false)}
          }
        />
        {/*<Popup
          open={cartWalletModalIsOpen}
          title="You are not Login"
          iconData={{
            name: 'user-locked',
            fontSize: 'extraLarge',
          }}
          description="To participate in a challenge, you must be registered on app.challenge.gg"
          onClose={() => {
            setCartWalletModalIsOpen(false)
          }}
          primaryButtonData={{
            onClick: () => {
                  setCartWalletModalIsOpen(false)
                  dispatch(loginWallet())
            },
            label: 'Connect wallet',
            endIcon: <SvgIcon name="wallet-line" />,
          }}
        />*/}
        <WalletSelectDialog 
          open={cartWalletModalIsOpen}  
          onClose={() => setCartWalletModalIsOpen(false)}
          onCancel={() => setCartWalletModalIsOpen(false)}
          walletSelected={walletSelected}
        />
    </>
  )
}
