import React from 'react'
import { useTimer } from 'react-timer-hook'
import { Box, BoxProps as MuiBoxProps, Stack, useTheme } from '@mui/material'
import Typography from '../Typography/Typography'
import TitleWithDot from '../TitleWithDot/TitleWithDot'

type TimerStakingProps = {
  expirySeconds?: number // Timer reference time in seconds
  onExpire?: () => void
  title?: string
  showSeconds?: boolean
  dateLabels?: {
    day?: string
    hrs?: string
    min?: string
    sec?: string
  }
  showZeroTime?: {
    zeroDay?: boolean
    zeroHrs?: boolean
    zeroMin?: boolean
  }
  BoxProps?: MuiBoxProps
}

export default function TimerStaking({
  expirySeconds = 1200,
  onExpire,
  showSeconds = true,
  dateLabels,
  showZeroTime,
  BoxProps,
}: TimerStakingProps) {
  const theme = useTheme()

  const expiryDate: Date = new Date(new Date().setSeconds(new Date().getSeconds() + expirySeconds))

  // Start Function when timer is stopped, if needed
  const handleExpire = () => {
    if (onExpire && typeof onExpire === 'function') {
      onExpire()
    }
  }

  const {
    seconds,
    minutes,
    hours,
    days,
    // isRunning, // isRunning variable is needed if you need to display the text when the counting reaches zero
  } = useTimer({ expiryTimestamp: expiryDate, onExpire: handleExpire })

  const { day = 'd', hrs = 'h', min = 'm', sec = 's' } = dateLabels || {}
  const { zeroDay = false, zeroHrs = false, zeroMin = true } = showZeroTime || {}

  return (
    <Box
      display="inline-block"
      {...BoxProps}
      sx={{
        width: 'auto',
        height: '43px',
        ...BoxProps?.sx,
      }}
    >
      <Box display="flex" alignItems="center" position="relative">
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          {(days > 0 || zeroDay) && (
            <>
              <Typography
                variant="h3"
                color="primary.light"
                mr="8px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {days >= 10 ? days : `0${days}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '4px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '3px' }}
              >
                {day}
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="8px" mr="8px" mb="8px">
                :
              </Typography>
            </>
          )}
          {(hours > 0 || zeroHrs) && (
            <>
              <Typography
                variant="h3"
                color="primary.light"
                mr="8px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {hours >= 10 ? hours : `0${hours}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '4px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '3px' }}
              >
                {hrs}
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="8px" mr="8px" mb="8px">
                :
              </Typography>
            </>
          )}

          {(minutes > 0 || zeroMin) && (
            <>
              <Typography
                variant="h3"
                color="primary.light"
                mr="8px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {minutes >= 10 ? minutes : `0${minutes}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '4px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '3px' }}
              >
                {min}
              </TitleWithDot>
            </>
          )}

          {showSeconds && (
            <>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="8px" mr="8px">
                :
              </Typography>
              <Typography
                variant="h3"
                color="primary.light"
                mr="8px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {seconds >= 10 ? seconds : `0${seconds}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '4px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '3px' }}
              >
                {sec}
              </TitleWithDot>
            </>
          )}
        </Stack>
      </Box>
      {/* {!isRunning && <Typography variant="h7" color="gray.0" mt='8px'>Time is up</Typography>} */}
    </Box>
  )
}
