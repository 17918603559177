import React from 'react'
import {
  Box,
  Stack,
  StackProps as MuiStackProps,
  useTheme,
  styled,
  useMediaQuery,
} from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import TitleWithDot from '../TitleWithDot/TitleWithDot'
import ProfileRateBox from '../ProfileRateBox/ProfileRateBox'

type ProfileChallengesBoxProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  data: {
    title: string
    subTitle: string
    totalGames: number
    winGames: number
    rows: {
      title: string
      value: number
    }[]
  }
}

function ProfileChallengesBox({ data, ...StackProps }: ProfileChallengesBoxProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Stack
      bgcolor="gray.900"
      direction="row"
      border="1px solid"
      borderColor="gray.700"
      pr="1px"
      gap="17px"
      flexWrap="wrap"
      position="relative"
      sx={{
        transition: 'border-color 0.3s ease-in-out',
        '&:hover': { borderColor: 'gray.500' },
        [theme.breakpoints.down('sm')]: {
          gap: '1px',
          height: '155px',
        },
      }}
      {...StackProps}
    >
      <Box
        minWidth="159px"
        width="31.7%"
        height="100%"
        pt="17px"
        pl="17px"
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '29.7%',
          },
          [theme.breakpoints.down('sm')]: {
            width: '63.5%',
            height: 'auto',
          },
        }}
      >
        <Typography
          variant="paragraphSmall"
          color="gray.500"
          height="16px"
          mb={{ xs: '1px', sm: '5px' }}
        >
          {data.subTitle}
        </Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography
            variant={isSmView ? 'h4' : 'h3'}
            color="primary.light"
            mr={{ xs: '11px', sm: '8px' }}
          >
            {data.totalGames}
          </Typography>
          <TitleWithDot
            typographyProps={{ variant: isSmView ? 'h7' : 'h6' }}
            dotProps={{ bottom: { xs: '4px', sm: '7px' }, width: '4px', height: '4px' }}
            rootStackProps={{
              spacing: { xs: '3px', sm: '5px' },
              position: 'relative',
              bottom: '1px',
            }}
          >
            {data.title}
          </TitleWithDot>
        </Stack>
      </Box>

      <Stack
        direction="row"
        flexGrow={1}
        sx={{
          [theme.breakpoints.down('sm')]: {
            width: '63.5%',
            flexGrow: 0,
            pl: '17px',
            pr: '3px',
          },
        }}
      >
        {data?.rows?.map((val, index) => (
          <StyledInfoStack key={index} direction="column">
            <Typography variant="paragraphSmall" color="gray.500">
              {val.title}
            </Typography>
            <Typography variant="h6">{val.value}</Typography>
          </StyledInfoStack>
        ))}
      </Stack>

      <Box
        borderLeft="1px solid"
        width={{ xs: '23.7%', md: '25.3%' }}
        borderColor="gray.700"
        pl="1px"
        sx={{
          [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '36.5%',
          },
        }}
      >
        <ProfileRateBox title="win %" totalGames={data.totalGames} currentValue={data.winGames} />
      </Box>
    </Stack>
  )
}

const StyledInfoStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  paddingTop: '7px',
  gap: '18px',
  '&:first-of-type': {
    width: '40%',
  },
  '&:nth-of-type(2)': {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    '&:first-of-type': {
      width: '43%',
    },
    '&:nth-of-type(2)': {
      width: '57%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: '0',
    gap: '10px',
    justifyContent: 'flex-start',
    '&:first-of-type': {
      width: '44%',
    },
    '&:nth-of-type(2)': {
      width: '56%',
    },
  },
}))

export default ProfileChallengesBox
