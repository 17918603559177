import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'
import { ActiveChallengeType, LogEventType } from 'src/helpers/types';

const logEventsSelector = (state:RootState, challengeId:number) => 
    state.challengeReducer.activeChallenges.find((x:ActiveChallengeType) => x.id === challengeId) 
        && state.challengeReducer.activeChallenges.find((x:ActiveChallengeType)=> x.id === challengeId).logEvents;


export const getSortedLogEventsCollection = createSelector(
    logEventsSelector,
    (logEventsCollection) => {       

        if (logEventsCollection === undefined)
            return undefined;
            //Need slice to make copy, since sort will otherwise mutate state
            const sorted = logEventsCollection.slice().sort((a:LogEventType, b:LogEventType) => {
            return new Date(b.created).valueOf() - new Date(a.created).valueOf()
        });

        return sorted;              
    }


);