import React, { useMemo, useState } from 'react'
import { Box, useTheme, Stack, Avatar, Theme } from '@mui/material'
import { RiGift2Line, RiHandCoinLine, RemixiconComponentType } from '@remixicon/react'
import { PNG_ICONS } from 'src/assets/icons/icons'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import SquareRow from 'src/components/SquareRow/SquareRow'
import Comment from 'src/components/Comment/Comment'
import BoxWrapper from 'src/components/BoxWrapper/BoxWrapper'
import MediaBoxWithText from 'src/components/MediaBoxWithText/MediaBoxWithText'
import StakePool from 'src/components/StakePool/StakePool'
import UnstakeDialog, { PoolType } from 'src/components/UnstakeDialog/UnstakeDialog'
import Typography from 'src/components/Typography/Typography'
import { FlexiStakingType, StakingSummary, VestedStakingType } from 'src/helpers/stakingTypes'
import { StakingType, TokenType } from 'src/helpers/types'
import { getAvailableAmountToUnstakeNow, getFormattedReleaseDates, getNextRelease } from 'src/helpers/stakingData'
import { formatTokenToNumber, formatTokenToString, formatTokenToStringWithSeparator, toTokenString } from 'src/utils/tokenUtil'

type YourStakingProps = {
  // Add your props here
  flexiStaking?: FlexiStakingType
  vestedStaking?: VestedStakingType
  summary?: StakingSummary  
  onClaim?: (action: StakingType, amount:string) => void
  onUnstake?: (values: { unstakeAmount: number; fee:number; type: StakingType }) => void
}

export default function YourStaking({flexiStaking,vestedStaking,summary,onClaim,onUnstake}: YourStakingProps) {
  const theme = useTheme()
  const [isFlexiDialogOpen, setIsFlexiDialogOpen] = useState(false)
  const [isVestedDialogOpen, setIsVestedDialogOpen] = useState(false)


  const flexiEarnedBigInt = flexiStaking?.accountEarned ? BigInt(flexiStaking.accountEarned) : BigInt(0);
  const vestedEarnedBigInt = vestedStaking?.accountEarned ? BigInt(vestedStaking.accountEarned) : BigInt(0);
  const totalRewardsBigInt = summary ? BigInt(summary.totalRewards) : BigInt(0);
  const total = flexiEarnedBigInt + vestedEarnedBigInt + totalRewardsBigInt;

  const lifetimeStatsData = useMemo(
    () => [
      {
        icon: (
          <Avatar
            src={PNG_ICONS.coin}
            alt="Coin"
            sx={{
              width: '12px',
              height: '12px',
              ...getMediaXsm(theme),
            }}
          />
        ),
        title: 'Not staked',
        value: (summary!==undefined && BigInt(summary.totalStaked) > 0) ? formatTokenToStringWithSeparator(summary.totalStaked,4,TokenType.CT): '',
        subTitle: 'Total Staked',
      },
      {
        icon: getIcon(theme, RiGift2Line),
        title: 'No rewards',
        value: (summary!==undefined && total > 0) ? formatTokenToStringWithSeparator(total.toString(),4,TokenType.CT): '',
        subTitle: 'Rewards Gained',
      },
      {
        icon: getIcon(theme, RiHandCoinLine),
        title: 'No rewards',
        value: (summary!==undefined && BigInt(summary.totalRewards) > 0) ? formatTokenToStringWithSeparator(summary.totalRewards,4,TokenType.CT): '',
        subTitle: 'Rewards Claimed',
      },
    ],
    [theme,summary,total]
  )

  const nextRelease = useMemo(
    () => vestedStaking ? getNextRelease(vestedStaking.vestingSchedule): null, 
    [vestedStaking]
  )

  const availableVestedAmount = useMemo(
    () => vestedStaking ? getAvailableAmountToUnstakeNow(vestedStaking.vestingSchedule): 0n, 
    [vestedStaking]
  )
  const formattedReleaseDates = useMemo(
    () => vestedStaking ? getFormattedReleaseDates(vestedStaking.vestingSchedule): [], 
    [vestedStaking]
  )

  function handleOnClaim(type: StakingType, amount: string) {
    if (onClaim) {
        onClaim(type,amount);
    } 
  }

  function handleUnstake(values: { unstakeAmount: number; type: StakingType }): void {
    if (onUnstake) {
      setIsVestedDialogOpen(false)
      setIsFlexiDialogOpen(false)
      const fee = values.type===StakingType.FLEXI ? flexiStaking?.unstakeFee : vestedStaking?.unstakeFee;
      const unstakeVales = { unstakeAmount: values.unstakeAmount, fee: fee as number, type: values.type }
      onUnstake(unstakeVales)
    }    
  }

  return (
    <BoxWrapper
      p="13px"
      width="100%"
      display="flex"
      flexDirection="column"
      gap="16px"
      sx={{
        [theme.breakpoints.up('xs')]: { p: '13px', gap: '16px' },
        [theme.breakpoints.up('md834')]: { gap: '29px', p: '18px 18px 44px 18px' },
        [theme.breakpoints.up('md2')]: { p: '18px' },
      }}
    >
      <div>
        <Stack direction="row" justifyContent="space-between">
          <TitleWithDot
            typographyProps={{ variant: 'h6' }}
            dotProps={{ bottom: { xs: '7px' } }}
            rootStackProps={{ spacing: { xs: '9px' }, mb: { xs: '12px' } }}
          >
            your stakings
          </TitleWithDot>
          <div>
            <SquareRow count={3} />
          </div>
        </Stack>
        <Stack gap="2px">
          <Comment rootStackProps={{ mb: { xs: '-2px' } }}>Your lifetime stats</Comment>
          <Stack gap="4px" direction="row">
            <Box
              minWidth="16px"
              display="none"
              sx={{
                background: `repeating-linear-gradient(40deg, transparent, transparent 4px, ${theme.palette.gray[700]} 4px, ${theme.palette.gray[700]} 8px)`,
                [theme.breakpoints.up('md834')]: {
                  display: 'block',
                },
              }}
            />
            <BoxWrapper
              p="7px"
              display="flex"
              flexGrow={1}
              gap="14px"
              flexWrap="wrap"
              justifyContent="flex-start"
              sx={{
                [theme.breakpoints.up('md720')]: {
                  gap: '24px',
                },
              }}
            >
              {lifetimeStatsData.map(({ icon, value, title, subTitle }) => (
                <MediaBoxWithText
                  key={subTitle}
                  StackProps={{
                    sx: {
                      flexGrow: 1,
                      [theme.breakpoints.up('md720')]: {
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '12px',
                      },
                    },
                  }}
                  MediaBox={{
                    children: icon,
                    sx: {
                      [theme.breakpoints.up('md720')]: {
                        width: '48px',
                        height: '48px',
                      },
                    },
                  }}
                  TitleTypographyProps={{
                    children: value || title,
                    color: value ? 'gray.0' : 'gray.600',
                    sx: { [theme.breakpoints.up('xsm')]: { mb: '4px' } },
                  }}
                  SubTitleTypographyProps={{
                    children: subTitle,
                    sx: { [theme.breakpoints.up('md720')]: { ...theme.typography.paragraphSmall } },
                  }}
                />
              ))}
            </BoxWrapper>
          </Stack>
        </Stack>
      </div>
      {flexiStaking && flexiStaking.accountBalance !== '' &&  //&& flexiStaking.accountBalance !== '0'
            <Stack gap="4px">
              <Comment>Flexi Pool</Comment>
              <StakePool
                type="flexi"
                stakedValue={formatTokenToStringWithSeparator(flexiStaking.accountBalance,6,TokenType.CT)}
                onUnstake={() => {
                  setIsFlexiDialogOpen(true)
                }}
                rewardValue={formatTokenToString(flexiStaking.accountEarned,TokenType.CT,6,false,true,false)}
                token={TokenType.CT}
                unstakeDisabled={flexiStaking.accountBalance==='0'}
                onClaim={() => handleOnClaim(StakingType.FLEXI,flexiStaking.accountEarned)}
                unstakeFee={flexiStaking.unstakeFee}
                periods={[]}
              />
            </Stack>
      }
       {vestedStaking  && vestedStaking.accountBalance !== '' && //&& vestedStaking.accountBalance !== '0'
            <Stack gap="4px">
              <Comment>Vested staking</Comment>
              <StakePool
                type="vested"
                stakedValue={formatTokenToStringWithSeparator(vestedStaking.accountBalance,6,TokenType.CT)}
                onUnstake={() => {
                  setIsVestedDialogOpen(true)
                }}
                rewardValue={formatTokenToString(vestedStaking.accountEarned,TokenType.CT,6,false,true,false)}
                token={TokenType.CT}
                onClaim={() => handleOnClaim(StakingType.VESTED,vestedStaking.accountEarned)}
                availableValue={availableVestedAmount===0n ? '0' : formatTokenToNumber(availableVestedAmount,TokenType.CT)} //IF we set 0 then formatting and CT is not shown
                availableToken={TokenType.CT}
                unstakeDisabled={availableVestedAmount===0n}
                percent={nextRelease?.percentage}
                releasedAt={nextRelease?.formattedDate}
                unstakeFee={vestedStaking.unstakeFee}
                periods={formattedReleaseDates}
              />
            </Stack>
      }
       {flexiStaking && flexiStaking.accountBalance !== '' && 
        <UnstakeDialog
          key={`${isFlexiDialogOpen}-flexi`}
          type={PoolType.FLEXI}
          title="Flexi Unstake"
          amount={formatTokenToNumber(flexiStaking.accountBalance,TokenType.CT)}
          open={isFlexiDialogOpen}
          onClose={() => setIsFlexiDialogOpen(false)}
          onOk={handleUnstake}
        >
          {/* @ts-ignore */}
          {({ value = 0 }) => (
            <Stack gap="4px" sx={{ [theme.breakpoints.up('xsm')]: { mb: '24px' } }}>
              {renderRow('Amount for Witdraw', value ? `${formatTokenToString(toTokenString((value)),TokenType.CT,4,false,true,true)}` : '-')}
              {renderRow(`Withdrawal fee (${flexiStaking?.unstakeFee}%)`, value ? `${formatTokenToString(toTokenString(((value * 5) / 100)),TokenType.CT,4,false,true,true)}` : '-', 'transparent')}
              {renderRow('You will recieve', value ? formatTokenToString(toTokenString((value - (value * 5) / 100)),TokenType.CT,4,false,true,true) : '-')}
            </Stack>
          )}
        </UnstakeDialog>
      }
      {vestedStaking && vestedStaking.accountBalance !== '' && 
        <UnstakeDialog
          key={`${isVestedDialogOpen}-vested`}
          type={PoolType.VESTED}
          title="Vested Unstake"
          amount={formatTokenToNumber(availableVestedAmount,TokenType.CT)}
          open={isVestedDialogOpen}
          onClose={() => setIsVestedDialogOpen(false)}
          onOk={handleUnstake}
          releasedAt={nextRelease?.formattedDate}
        >
          {/* @ts-ignore */}
          {({ value = 0 }) => (
            <Stack gap="4px" sx={{ [theme.breakpoints.up('xsm')]: { mb: '24px' } }}>
              {renderRow('You will recieve', value ? `${formatTokenToString(toTokenString((value)),TokenType.CT,4,false,true,true)}` : '-')}
            </Stack>
          )}
        </UnstakeDialog>
      }
    </BoxWrapper>
  )
}

function getMediaXsm(theme: Theme) {
  return {
    [theme.breakpoints.up('xsm')]: { width: '20px', height: '20px' },
  }
}

function getIcon(theme: Theme, component: RemixiconComponentType) {
  return (
    <Box
      component={component}
      sx={{
        width: '12px',
        height: '12px',
        color: 'primary.main',
        ...getMediaXsm(theme),
      }}
    />
  )
}

function renderRow(label: string, value = '-', bgcolor = 'gray.700') {
  return (
    <Stack
      direction="row"
      p="4px"
      gap="10px"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={bgcolor}
    >
      <Typography variant="paragraphSmall" color="gray.200">
        {label}
      </Typography>
      <Typography variant="bodySmall" color="gray.50">
        {value}
      </Typography>
    </Stack>
  )
}


