import React, { useMemo } from 'react'
import { Slider, styled, SliderProps, alpha } from '@mui/material'

const CustomSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  '&.MuiSlider-root': {
    marginBottom: '0',
    padding: '20px 0',
  },
  '& .MuiSlider-track': {
    borderRadius: 0,
  },
  '& .MuiSlider-rail': {
    borderRadius: 0,
    backgroundColor: theme.palette.gray[900],
    border: `1px solid ${theme.palette.gray[700]}`,
    padding: '1px',
    paddingRight: '2px',
    paddingLeft: '1px',
    opacity: 1,
  },
  '& .MuiSlider-markLabel': {
    ...theme.typography.uiElement,
    top: '43px',
    color: theme.palette.gray[50],
    '&:first-of-type': { top: '20px' },
    '&:last-of-type': { top: '20px' },
    '&:hover': {
      color: theme.palette.gray[0],
    },
    '&.MuiSlider-markLabelActive': {
      // color: theme.palette.primary.main,
    },
  },
  '& .MuiSlider-mark': {
    width: '4px',
    height: '4px',
    borderRadius: 0,
    backgroundColor: theme.palette.gray[600],

    '&.MuiSlider-markActive': {
      backgroundColor: theme.palette.primary.main,
    },

    '&[data-index="0"]': {
      display: 'none',
    },
  },
  '& .MuiSlider-thumb': {
    width: '24px',
    height: '24px',
    '&:before': {
      border: `2px solid ${theme.palette.gray[700]}`,
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.primary.light, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.primary.light, 0.16)}`,
    },
  },
}))

function valuetext(value: number) {
  return `${value}%`
}

type Mark = {
  value: number
  label: string
}

type StakeSliderProps = {
  total?: number
  value: number | string
  onChange: (value: number | number[]) => void
  marks?: Mark[]
  disabled: boolean
}

export default function StakeSlider({
  total = 100,
  value,
  onChange,
  marks = [],
  disabled,
}: StakeSliderProps) {
  const onSliderChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue)
  }

  const markList = useMemo(
    () =>
      total !== 0
        ? [
            {
              value: 0,
              label: '0%',
            },
            {
              value: total / 4,
              label: '25%',
            },
            {
              value: (total / 4) * 2,
              label: '50%',
            },
            {
              value: (total / 4) * 3,
              label: '75%',
            },
            {
              value: (total / 4) * 4,
              label: '100%',
            },
            ...marks,
          ]
        : [],
    [total, marks]
  )

  return (
    <CustomSlider
      aria-label="Stake"
      value={Number(value)}
      onChange={onSliderChange}
      getAriaValueText={valuetext}
      valueLabelDisplay="off"
      // shiftStep={total / 4}
      step={total / 4}
      min={0}
      max={total}
      marks={markList}
      disabled={disabled}
    />
  )
}
