import React,{useState} from 'react';
import { useAppSelector } from 'src/hooks'
import ServiceMessage from 'src/components/ServiceMessage/ServiceMessage'
import { Box } from '@mui/material'
import { useSwitchChain } from '@thirdweb-dev/react';

export function InfoBar() {
    const switchChain = useSwitchChain();
    
    const openModal = () => {
        document.body.classList.add('disabled');
    }
    
    const hideModal = () => {
        document.body.classList.remove('disabled');
    }

    const { show, message, messageType, displayType, reconnect,showChangeChain, chainMessage, chainId } = useAppSelector(state => state.infoPanelReducer);

    if (show) {
        openModal();
       
        if (displayType === 'FLASH') {
            setTimeout(() => { hideModal() }, 1000);
        }
    }
    else {
        hideModal();

    }  
    
    const reload = () =>{
        window.location.reload();
    }

    const switchNetwork = async () =>{
        await switchChain(chainId);
    }
       
    if(show || showChangeChain){
        return (
                <Box position="fixed" bottom={0} zIndex={100} width="100%">
                        {show &&
                            <ServiceMessage 
                                BoxProps={{ bgcolor: 'primary.main', mb: showChangeChain ? 2 : 0 }}
                                LinkProps={{
                                    children: 'Reconnect'  // This line sets the new text for the link
                                }}
                                title={''} 
                                isReadyToReconnect={reconnect} 
                                message={message} 
                                closeToast = {()=>reload()}                            
                            />
                        }
                        {showChangeChain &&
                            <ServiceMessage 
                                BoxProps={{ bgcolor: 'grey' }}
                                LinkProps={{
                                    children: 'Switch'  // This line sets the new text for the link
                                }}
                                title={''} 
                                isReadyToReconnect={true} 
                                message={chainMessage} 
                                closeToast = {()=>switchNetwork()}                            
                            />
                        }
                </Box>
       
        );
    }else{
        return null;
    }
}


