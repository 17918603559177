import React from 'react'
import { styled } from '@mui/material/styles'
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'

const IOSSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 29,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      margin: 2,
      transform: 'translateX(13px)',
      color: theme.palette.common.black,
      '& .MuiSwitch-thumb': {
        position: 'relative',
        width: '12px',
        height: '12px',
        backgroundColor: theme.palette.common.black,
        '&:before': {
          position: 'absolute',
          content: "''",
          width: '4px',
          height: '4px',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
    '&:not(.Mui-checked).Mui-disabled .MuiSwitch-thumb': {
      backgroundColor: theme.palette.gray[25],
      boxShadow: `0px 6.6501px 5.32008px rgba(0, 0, 0, 0.125207), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0871551)`,
    },
    '&:not(.Mui-checked).Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
    },
  },
  '&:has(.Mui-checked.Mui-disabled)': {
    opacity: 0.3,
  },
  '&:has(:not(.Mui-checked).Mui-disabled)': {
    opacity: 0.3,
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 8,
    height: 8,
    backgroundColor: theme.palette.gray[0],
  },
  '& .MuiSwitch-track': {
    borderRadius: 8.571443,
    border: '1px solid',
    borderColor: theme.palette.gray[700],
    backgroundColor: theme.palette.gray[800],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export type SwitchProps = MuiSwitchProps

export default function Switch(props: SwitchProps) {
  return <IOSSwitch {...props} />
}
