import React from 'react'
import { Stack, StackProps, Avatar, AvatarProps } from '@mui/material'
import { PNG_ICONS } from 'src/assets/icons/icons'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type CoinImageWithAmountProps = StackProps & {
  avatarProps?: AvatarProps
  amount?: string | number
  amountTypographyProps?: TypographyProps
}

export default function CoinImageWithAmount({
  avatarProps,
  amount,
  amountTypographyProps,
  ...restRootStackProps
}: CoinImageWithAmountProps) {
  const { src = PNG_ICONS.weth, alt = 'Coin', sx, ...restAvatarProps } = avatarProps || {}
  return (
    <Stack direction="row" alignItems="center" spacing="4px" {...restRootStackProps}>
      <Avatar
        src={src}
        alt={alt}
        sx={{ width: '18px', height: '18px', ...sx }}
        {...restAvatarProps}
      />
      {!amountTypographyProps?.children && ( //amount && 
        <Typography variant="bodySmall" color="gray.50" {...amountTypographyProps}>
          {amount}
        </Typography>
      )}
      {!amount && amountTypographyProps?.children && amountTypographyProps?.children}
    </Stack>
  )
}
