import React from 'react'
import Button, { ButtonProps } from '../Button/Button'

export type ButtonIconProps = Pick<
  ButtonProps,
  'children' | 'size' | 'sx' | 'disabled' | 'onClick'
> & {
  variant?: 'contained' | 'outlined'
}

export default function ButtonIcon({
  children,
  sx,
  variant = 'contained',
  ...restProps
}: ButtonIconProps) {
  return (
    <Button
      sx={{
        '&.MuiButton-sizeSmall': {
          padding: '10px',
          minWidth: '44px',
          height: '44px',
        },
        '&.MuiButton-sizeMedium': {
          padding: '15px',
          minWidth: '54px',
          height: '54px',
        },
        '&.MuiButton-sizeLarge': {
          padding: '26px',
          minWidth: '80px',
          height: '80px',
        },
        '&.MuiButton-sizeBig': {
          padding: '18px',
          minWidth: '64px',
          height: '64px',
        },
        ...sx,
      }}
      variant={variant}
      {...restProps}
    >
      {children}
    </Button>
  )
}
