import { createSelector } from '@reduxjs/toolkit'
import { SortOrder } from 'src/helpers/types';
import type { RootState } from 'src/store/configureStore'


const gameCountSelector = (state:RootState, gameId:string|undefined, gameMode:string|undefined, highlighted:boolean, sortBy: SortOrder|undefined) => state.gameReducer.gameTemplates &&
    (gameId !== undefined ?
        state.gameReducer.gameTemplates.filter((x:any) => x.intGameId === gameId).length
        :
        state.gameReducer.gameTemplates.length
    ); 

const gameTemplateSelector = (state:RootState, gameId:string|undefined, gameMode:string|undefined, highlighted:boolean, sortBy: SortOrder|undefined) => state.gameReducer.gameTemplates &&

    (gameId !== undefined ? 
        (gameMode !== undefined ?
            (highlighted ?
                state.gameReducer.gameTemplates.filter((x:any) => x.intGameId === gameId && x.gameMode === gameMode && x.highlighted) :
                state.gameReducer.gameTemplates.filter((x:any) => x.intGameId === gameId && x.gameMode === gameMode))
                :
            (highlighted ?
                state.gameReducer.gameTemplates.filter((x:any) => x.intGameId === gameId && x.highlighted) :
                state.gameReducer.gameTemplates.filter((x:any) => x.intGameId === gameId)))
        :
    
        (gameMode !== undefined ?
            (highlighted ?
                state.gameReducer.gameTemplates.filter((x:any) => x.gameMode === gameMode && x.highlighted) :
                state.gameReducer.gameTemplates.filter((x:any) => x.gameMode === gameMode))
            :
            (highlighted ?
                state.gameReducer.gameTemplates.filter((x:any) => x.highlighted) :
                state.gameReducer.gameTemplates))
        
    );

const gameTemplatesDisabled = (state:RootState, gameId:string|undefined, gameMode:string|undefined, highlighted:boolean, sortBy: SortOrder|undefined) => state.gameReducer.gameTemplates &&
    (gameId !== undefined ? 
        (gameMode !== undefined ?
            (highlighted ?
                !state.gameReducer.gameTemplates.some((x:any) => x.intGameId === gameId && x.gameMode === gameMode && x.disabled === false && x.highlighted) :
                !state.gameReducer.gameTemplates.some((x:any) => x.intGameId === gameId && x.gameMode === gameMode && x.disabled === false))
                :
            (highlighted ?
                !state.gameReducer.gameTemplates.some((x:any) => x.intGameId === gameId && x.disabled === false && x.highlighted) :
                !state.gameReducer.gameTemplates.some((x:any) => x.intGameId === gameId && x.disabled === false)))
        :
        (gameMode !== undefined ?
            (highlighted ?
                !state.gameReducer.gameTemplates.some((x:any) => x.gameMode === gameMode && x.disabled === false && x.highlighted) :
                !state.gameReducer.gameTemplates.some((x:any) => x.gameMode === gameMode && x.disabled === false))
            :
            (highlighted ?
                !state.gameReducer.gameTemplates.some((x:any) => x.disabled === false && x.highlighted) :
                !state.gameReducer.gameTemplates.some((x:any) => x.disabled === false)))
    );

 const sortOrder = (state:RootState, gameId:string|undefined, gameMode:string|undefined, highlighted:boolean, sortBy: SortOrder|undefined) => sortBy 

export const getSortedGameTemplates = createSelector(
    gameCountSelector, gameTemplateSelector, gameTemplatesDisabled, sortOrder,
    (gameCount, gameTemplateCollection, disabled, orderBy) => {
        if (gameTemplateCollection === null)
            return {
                collection: gameTemplateCollection,
                disabled: true,
                count: gameCount
            }


        const sorted =  gameTemplateCollection.slice().sort((a:any, b:any) => {

            if (a.fee < b.fee) { return (orderBy===undefined ||  orderBy===SortOrder.FEE_LOW) ? -1 : 1; }
            else if (a.fee > b.fee) { return (orderBy===undefined || orderBy===SortOrder.FEE_LOW) ? 1 : -1 ; }
            else {
                if (a.maxParticipans < b.maxParticipans) { return -1; }
                else if (a.maxParticipans > b.maxParticipans) { return 1; }
                return 0;
            }
        });

        return {
            collection: sorted,
            disabled: disabled,
            count: gameCount
        }
    }
);