import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/configureStore';

// Input selector to get activeChallenges from state
const tournamentsSelector = (state: RootState, tournamentId:number) => 
    state.tournamentReducer.onTimeChallenges.find((item:any) => item.tournamentId === tournamentId && item.isTournamentBase);


// Factory function to create a memoized selector for a specific tournamentId
export const selectTournament = createSelector(
    tournamentsSelector, 
    (tournaments) => {
      return tournaments;
    }
);


