import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import Logo from 'src/components/Logo/Logo'
import PatternLinesRegistration from 'src/components/PatternLines/PatternLinesRegistration'
import ThemeProvider from 'src/components/ThemeProvider/ThemeProvider'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import { themes } from 'src/themes'
import { EnvironmentType, config } from 'src/config/config'
import Button from 'src/components/Button/Button'

type ErrorFallbackProps = {
  error: Error
  componentStack: string
  resetError: () => void
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  componentStack,
  resetError,
}) => {
  // Check the specific error message
  const theme = useMemo(() => themes.dark, [])
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  if (error.message.includes('Chain not configured')) {
    //logoutUser();
  }
  return (
    <ThemeProvider>
      <Box
        sx={{
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            padding: { xs: '25px 16px 62px', sm: '49px 0 62px 0' },
            overflowX: 'hidden',
            background: `${theme.palette.gray[900]}`,
            minHeight: '100vh',
          }}
        >
          {/*top*/}
          <PatternLinesRegistration
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '881px',
              height: '587px',
              overflow: 'hidden',
              [theme.breakpoints.down('lg')]: {
                height: '358px',
              },
              [theme.breakpoints.down('sm')]: {
                width: '290px',
                height: '161px',
              },
              '&:after': {
                content: '" "',
                position: 'absolute',
                top: { xs: '-488px', sm: '-402px', lg: '-223px' },
                right: 332,
                transform: 'rotate(120deg)',
                background: `${theme.palette.gray[900]}`,
                width: 650,
                height: 1300,
              },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '86px',
              right: '26px',
              transform: 'rotate(30deg)',
              [theme.breakpoints.down('lg')]: {
                top: '48px',
                right: '-9px',
              },
              [theme.breakpoints.down('sm')]: {
                top: '25px',
                right: '-5px',
              },
            }}
          >
            <Logo
              size="large"
              variant="logo-black"
              sx={{
                width: { xs: '195px', sm: '300px', lg: '449px' },
                height: 'auto',
              }}
            />
          </Box>
          {/*bottom*/}
          <PatternLinesRegistration
            sx={{
              position: 'absolute',
              bottom: '0',
              width: '625px',
              height: '110px',
              left: 0,
              [theme.breakpoints.down('sm')]: {
                width: '312px',
                height: '27px',
              },
              overflow: 'hidden',
              '&:after': {
                content: '" "',
                position: 'absolute',
                top: '-213px',
                right: { xs: '-251px', sm: '-125px', md: '-89px' },
                transform: 'rotate(120deg)',
                background: `${theme.palette.gray[900]}`,
                width: '275px',
                height: '365px',
              },
            }}
          />
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xs: '7px', sm: '16px' },
              }}
            >
              <TitleWithDot
                rootStackProps={{
                  spacing: { xs: '8px', sm: '14px' },
                  ml: { xs: '15px', sm: '21px' },
                }}
                dotProps={{
                  sx: {
                    bottom: 0,
                    [theme.breakpoints.down('sm')]: {
                      width: '6px',
                      height: '6px',
                    },
                  },
                }}
              >
                {() => <a href="/"><Logo size={isMobileView ? 'xs' : 'large'} variant="logo-white" /></a>}
              </TitleWithDot>
            </Box>
            <Box sx={{ textAlign: 'center', marginTop: '36px' }}>
              <Typography variant="labelBig" sx={{ color: theme.palette.primary.light }}>
                {'Ops! Something went wrong.'}
              </Typography>
            </Box>

          

            <Box
              sx={{
                marginBottom: '32px',
                [theme.breakpoints.up('sm')]: {
                  marginBottom: '44px',
                },
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              position: 'relative',
              zIndex: 10,
            }}
          >
            <Box
              sx={{
                width: { sm: '510px', xs: '100%' },
                margin: '0 auto',
              }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                {error.message}
              </Typography>
              {config.environment===EnvironmentType.DEVELOPMENT &&
                <Typography variant="paragraphSmall">
                  {componentStack}
                </Typography>
              }     
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '32px',
                gap: '16px'
              }}
            >
               <Button onClick={resetError}>Try again</Button>
               <a href="/"><Button>Go back</Button></a>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
