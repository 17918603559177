const initialState = {
    carusel: {
        streams: [],
       
    },
   
};

export function caruselReducer(state = initialState, action) {
    switch (action.type) {
        case "TWITCH_STREAMS":
            return {
                ...state,
                carusel: {
                    streams: action.streams,
                }
            }
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }
}