import React from 'react'
import { Stack, StackProps as MuiStackProps } from '@mui/material'
import Link, { LinkProps } from 'src/components/Link/Link'

type LegalLinksProps = {
  /** [The root element of this component uses the Stack component from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  /**
   * [Props applied to the Link element.](/?path=/docs/components-link--primary)
   * */
  LinkProps?: Partial<LinkProps>
}

export default function MobileLegalLinks({ StackProps, LinkProps }: LegalLinksProps) {
  return (
    <Stack direction="row" gap="31px" justifyContent="center" alignItems="center" {...StackProps}>
      <Link href="https://challenge.gg/terms-of-use/" target="_blank" rel="noopener" color="secondary" {...LinkProps}>
        User Agreement
      </Link>
      <Link href="https://challenge.gg/privacy-policy/" target="_blank" rel="noopener" color="secondary" {...LinkProps}>
        Privacy Policy
      </Link>
    </Stack>
  )
}
