export enum EnvironmentType{
    DEVELOPMENT='DEVELOPMENT',
    STAGE='STAGE',
    PRODUCTION='PRODUCTION'
} 

const ludusTest = {
    chainId: "0x7FFE8ADC", 
    chainIdNumber: 2147388124, 
    chainDisplayName: "Ludus-Testnet",
    chainName: "Ludus Testnet", 
    slug: "ludus",
    rpc: "https://ludus-testnet-rpc.eu-north-2.gateway.fm", 
    weth_address: "0x13AE6e27aB6351C28258289f9918f6a3899AdDf2", 
    cc_address: "0x588bc7CF53D5B7d05D56cDC74E79CC8807Fd9fB5", 
    solo_challenge_address: "0x28013FA75C872259e73de454bB04f91f3E631777", 
    flexi_staking_address: "0xF0B098A8c28f79089aa3D95ec0FA390736B30C6A",  //Only fo local testing to use diferent network then Ganache
    vested_staking_address: "0x1bDBC7fa77c36BAfE05B22Ca6689B81DD0069F46", //Only fo local testing to use diferent network then Ganache
    nativeCurrencyName: "Ludus-Testnet ETH",
    explorer:{
        "name": "Blocksout",
        "url": "https://ludus-testnet-blockscout.eu-north-2.gateway.fm",
        "standard": "EIP3091"
      },
    faucet: "https://ludus-testnet-faucet.eu-north-2.gateway.fm",
};

const sepolia = {
    chainId: "0xaa36a7",
    chainIdNumber: 11155111,
    chainDisplayName: "Sepolia Testnet",
    chainName: "sepolia",
    slug: "sepolia",
    rpc: "https://cosmopolitan-serene-smoke.ethereum-sepolia.quiknode.pro/9876196ef9251dec027b35e55001a10e065566b1/",
    cc_address: "0xD0fFcD1D8ae0Ace27e585debA916ad543a35186C",
    weth_address: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
    flexi_staking_address: "0xA020148a5Dc7a59F416818eb0ceE5D5d7A12Acb8",
    vested_staking_address: "0x05aa806C042B75A0644A02d53EaC59BDDD5F732D",
    explorer: {
        name: "Etherscan",
        url: "https://sepolia.etherscan.io",
        standard: "EIP3091"
    },
    faucet: "https://www.alchemy.com/faucets/ethereum-sepolia",
    nativeCurrencyName: "Sepolia-Testnet ETH",
   
};

const ganache = {
    chainId: "0x539",
    chainIdNumber: 1337, 
    chainDisplayName: "Ganache-Testnet",
    chainName: "Ganache",
    slug: "ganache",
    rpc: "http://127.0.0.1:8545", 
    weth_address: "0xf057945264FC5e324D5f08Bea8B21016BCaA5560",
    cc_address: "0x46b9F7874C4ed841473D2C1429E968a86443bb27",
    solo_challenge_address: "0x6d6D92baf778919Ec1fC6373DaF113B822538289",
//    flexi_staking_address: "0xc5d617707333D3Fb24833EAfA34fab0108e0eC83",
//    vested_staking_address: "0xDaD73C306616ce828F830F303fe1eB0D46E5AFAc",
    nativeCurrencyName: "Ganache-Testnet ETH", //Ganache-Testnet ETH
    explorer: {
        name: undefined,
        url: undefined,
        standard: undefined
    },
};

const ethereum = {
    chainId: "0x1",
    chainIdNumber: 1,
    chainDisplayName: "Ethereum Mainnet",
    chainName:"ethereum",
    slug: "ethereum",
    weth_address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    cc_address: "0x2ac29781547EB7501d3CfD9733ce11e38dF5463A",
    flexi_staking_address: "0x9cB59D86A05F313052b85c30c6B7b4bB1f57d8AF",
    vested_staking_address: "0x76a6b79Dd746EbFf71614666846384D5380bd16D",
    solo_challenge_address: "",
    nativeCurrencyName: "Ethereum Mainnet ETH",
    explorer: {
        name: "Etherscan",
        url: "https://etherscan.io",
        standard: "EIP3091"
    },
}

const dev = {
    url: {
        BASE_URL: "https://localhost:44303",
        STORAGE_URL:"https://devfiles.challenge.gg",
    },
    logrocket: {
        key: "i4mmqc/challengegg-dev"
    },
    sentry: {
        dsn: "https://da2fbb6e47b292691c0e3a441ba40992@o4505436167274496.ingest.sentry.io/4505832535293952"
    },
    blockchain: ganache,
    blockchainStaking: ludusTest,
    thirdWeb: {
        clientId: "e595826b6aa63fb0ebd06e28cd390d1c",
        walletConnectProjectId: "633be614e1a54f275007f6cb5dd5327d"
    },
    environment: EnvironmentType.DEVELOPMENT
};

const stage = {
    url: {
        BASE_URL: "https://api.stage.challenge.gg",
        STORAGE_URL: "https://stagefiles.challenge.gg",
    },
    logrocket: {
        key: "i4mmqc/challengegg-stage"
    },
    sentry: {
        dsn: "https://5279652a8114429ebc5ff9290708f20a@o4505436167274496.ingest.sentry.io/4505832590737408"
    },
    blockchain: ludusTest,
    blockchainStaking: sepolia,
    thirdWeb: {
        clientId: "e595826b6aa63fb0ebd06e28cd390d1c",
        walletConnectProjectId: "633be614e1a54f275007f6cb5dd5327d"
    },
    environment: EnvironmentType.STAGE
};

const prod = {
    url: {
        BASE_URL: "https://api.challenge.gg",
        STORAGE_URL: "https://files.challenge.gg",
    },
    logrocket: {
        key: "i4mmqc/challengegg-prod"
    },
    sentry: {
        dsn: "https://9dc60db7d962fd8b4fb26384d53ea123@o4505436167274496.ingest.us.sentry.io/4507259408875520"
    },
    blockchain: ethereum,
    blockchainStaking: ethereum,
    thirdWeb: {
        clientId: "e595826b6aa63fb0ebd06e28cd390d1c",
        walletConnectProjectId: "633be614e1a54f275007f6cb5dd5327d"
    },
    environment:EnvironmentType.PRODUCTION
};

export const config = process.env.NODE_ENV === "development" ? dev : (process.env.REACT_APP_ENV === "stage" ? stage : prod);