import { Box, Stack, StackProps as MuiStackProps, BoxProps } from '@mui/material'
import React from 'react'
import TitleWithStatus, { TitleWithStatusProps } from '../TitleWithStatus/TitleWithStatus'

type StakingBlockTextItemProps = {
  TitleProps?: TitleWithStatusProps
  children?: React.ReactNode
  StackProps?: MuiStackProps
  ChildrenBoxProps?: BoxProps
}

function StakingBlockTextItem({
  TitleProps,
  children,
  StackProps,
  ChildrenBoxProps,
}: StakingBlockTextItemProps) {
  return (
    <Stack direction="column" justifyContent="space-between" {...StackProps}>
      {TitleProps && <TitleWithStatus {...TitleProps} />}
      <Box
        {...ChildrenBoxProps}
        sx={{ height: '43px', display: 'flex', alignItems: 'flex-end', ...ChildrenBoxProps?.sx }}
      >
        {children}
      </Box>
    </Stack>
  )
}

export default StakingBlockTextItem
