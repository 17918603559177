import { Box, BoxProps } from '@mui/material'
import React from 'react'

enum Variants {
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
}
type VariantT = `${Variants}` // Co "Enum Position" values to input options

type CornerProps = BoxProps & {
  variant?: VariantT
  children?: React.ReactNode
}

function Corner({ variant = Variants.BottomRight, children, ...restProps }: CornerProps) {
  const before: React.CSSProperties = {}
  const boxStyle: React.CSSProperties = {}

  switch (variant) {
    case Variants.TopLeft:
      before.borderTop = '4px solid'
      before.borderLeft = '4px solid'
      before.top = 0
      before.left = 0
      boxStyle.padding = '4px 0 0 4px'
      break
    case Variants.TopRight:
      before.borderTop = '4px solid'
      before.borderRight = '4px solid'
      before.top = 0
      before.right = 0
      boxStyle.padding = '4px 4px 0 0'
      break
    case Variants.BottomLeft:
      before.borderBottom = '4px solid'
      before.borderLeft = '4px solid'
      before.bottom = 0
      before.left = 0
      boxStyle.padding = '0 0 4px 4px'
      break
    case Variants.BottomRight:
      before.borderBottom = '4px solid'
      before.borderRight = '4px solid'
      before.bottom = 0
      before.right = 0
      boxStyle.padding = '0 4px 4px 0'
      break
  }

  return (
    <Box
      position="relative"
      display="inline-block"
      alignItems="stretch"
      justifyContent="stretch"
      sx={{
        ...boxStyle,
        '&:before': {
          ...before,
          content: '""',
          display: 'block',
          position: 'absolute',
          borderColor: 'primary.main',
          width: '16px',
          height: '16px',
          zIndex: 1,
          zoom: 1,
        },
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default Corner
