import React from 'react'
import { Stack, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type MediaWithTitleProps = {
  StackProps?: StackProps
  icon?: React.ReactNode
  TypographyProps?: TypographyProps
} & React.PropsWithChildren

export default function MediaWithTitle({
  StackProps,
  TypographyProps,
  icon = null,
  children,
}: MediaWithTitleProps) {
  return (
    <Stack direction="row" gap="8px" alignItems="center" {...StackProps}>
      {icon}
      <Typography variant="paragraphMedium" color="gray.300" {...TypographyProps}>
        {TypographyProps?.children || children}
      </Typography>
    </Stack>
  )
}
