export function goToHome() {
    return { type: "GO_TO_URL", url:'/'};
}

export function goToLogin(from) {
    return { type: "GO_TO_URL", url: '/login' , from: from};
}

//Not used
export function goToRegister() {
    return { type: "GO_TO_URL", url: '/signup' };
}

export function hideAnimation() {
    return { type: "HIDE_CHALLENGE_EVENT"};
}

export function resetWaitDialog(){
    return {type: "RESET_WAIT_DIALOG"}
}

export function showErrorPopup(title, subtitle){
    return {type: "SHOW_ERROR_POPUP", title, subtitle}
}