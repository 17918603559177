const initialState = {
    leaderboard: {
        weeklyLeaderboard: [],
        weeklyLeaderboardEnds: null,
        weeklyPrices: [],
        monthlyLeaderboard: [],
        monthlyLeaderboardEnds: null,
        monthlyPrices: [],
        dailyLeaderboard: [],
        dailyLeaderboardEnds: null,
        dailyPrices: [],
        weeklyLeader: {},
        monthlyLeader: {},
        weeklyScore: 0,
        weeklyPosition: 0,
        monthlyScore: 0,
        monthlyPosition: 0,
        general: {
            mostPlayed: {}
        },
    },
    topLists: []
};

export function leaderboardReducer(state = initialState, action) {
    switch (action.type) {
        case "GENERAL_LEADERBOARD_INFO":
            return {
                ...state,
                leaderboard: {
                    monthlyLeaderboard: action.generalLeaderboard.monthly.leaderboard,
                    monthlyLeaderboardEnds: action.generalLeaderboard.monthly.periodEnds,
                    monthlyPrices: action.generalLeaderboard.monthly.prices,
                    monthlyScore: action.generalLeaderboard.monthly.userScore,
                    monthlyPosition: action.generalLeaderboard.monthly.userPosition,
                    monthlyLeader: action.generalLeaderboard.monthly.leader,

                    weeklyLeaderboard: action.generalLeaderboard.weekly.leaderboard,
                    weeklyLeaderboardEnds: action.generalLeaderboard.weekly.periodEnds,
                    weeklyPrices: action.generalLeaderboard.weekly.prices,
                    weeklyScore: action.generalLeaderboard.weekly.userScore,
                    weeklyPosition: action.generalLeaderboard.weekly.userPosition,
                    weeklyLeader: action.generalLeaderboard.weekly.leader,

                    dailyLeaderboard: action.generalLeaderboard.daily.leaderboard,
                    dailyLeaderboardEnds: action.generalLeaderboard.daily.periodEnds,
                    dailyPrices: action.generalLeaderboard.daily.prices,

                    general: {

                        mostPlayed: action.generalLeaderboard.mostPlayed
                    }
                }
            }
        case "GENERAL_STATISTICS_INFO":
            return {
                ...state,
                topLists: action.generalStatistics
            }
        default:
            return state;
    }
}