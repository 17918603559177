import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { DialogProps, Box, Stack, useMediaQuery, Grid } from '@mui/material'
import DialogWrapper from 'src/components/DialogWrapper/DialogWrapper'
import Typography from 'src/components/Typography/Typography'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Button from 'src/components/Button/Button'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { getETHBalance } from 'src/actions/userActions'
import TextField from '../TextField/TextField'
import { formatTokenToString } from 'src/utils/tokenUtil'
import { TokenType } from 'src/helpers/types'

type Reason = 'backdropClick' | 'escapeKeyDown' | 'btnClick'

export type SwapETHDialogDialogProps = DialogProps & {
  onOk?: (amount?: string) => void
  onCancel?: () => void
}

export default function SwapETHDialog({
  onClose,
  onOk,
  onCancel,
  open,
  ...restDialogProps
}: SwapETHDialogDialogProps) {

    const dispatch =  useAppDispatch();
    
    const [selectedAmount, setSelectedAmount] = useState('0')  
    const inputRef = useRef<HTMLInputElement>(null);
    const theme = useTheme()
    const isUpSmView = useMediaQuery(theme.breakpoints.up('sm'))

    

    const eth = useAppSelector(state => state.userReducer.userInfo.coins?.eth);

    useEffect(() => {
        if(open){
            dispatch(getETHBalance());
        }
    }, [open, dispatch]);
    
    useEffect(() => {
        if (open) {
            setSelectedAmount('0');
            setTimeout(() => {
            if(inputRef.current){
                inputRef.current.select();
                inputRef.current.focus();        
            }
        }, 100); // delay of 100ms, inputRef.current was allways null, a short delay fixed it so it had time to mount
        }
    }, [open]);

  const handleClose = useCallback(
    (event: object, reason: Reason) => {

      if (onClose) {
        // @ts-ignore
        onClose(event, reason)
      }
    },
    [onClose]
  )

    const onChange= (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        // Use a regex to check if the new value is a valid number or decimal
        if (/^\d*\.?\d*$/.test(newValue)) {
            setSelectedAmount(newValue);
        }
    }

  return (
    <DialogWrapper
      open={open}
      {...restDialogProps}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '286px',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            margin: '16px',
          },
        },
      }}
    >
      <Box
        sx={() => ({
          border: '1px solid',
          borderColor: 'gray.700',
        })}
      >
        <Box
          sx={{
            m: '1px',
            bgcolor: 'gray.800',
            p: '24px',
          }}
        >
          <Stack gap="8px">
            <Stack alignItems="flex-end">
              <SvgIcon
                name="close-fill"
                sx={{ width: '18px', height: '18px', cursor: 'pointer' }}
                onClick={(e) => handleClose(e, 'btnClick')}
              />
            </Stack>
            <Typography variant="h5" align="center" color="gray.0">
              Swap ETH to WETH
            </Typography>
          </Stack>
          <Box
            sx={(theme) => ({
              mt: '24px',
              height: '128px',
              overflowY: 'auto',
              px: '13.5px',
              [theme.breakpoints.up('sm')]: {
                px: 0,
                height: '122px',
              },
            })}
          >
            <Grid container gap={{ xs: '8px', sm: '16px' }}>
                <TextField id="amount" name="amount" value={selectedAmount} placeholder="" 
                        label={eth ? `Amount to swap (${formatTokenToString(eth,TokenType.ETH)} ETH)`:''} size="large" 
                        inputRef={inputRef}
                        onChange={onChange}/>
            </Grid>
          </Box>
        </Box>
        <Stack p="1px" direction="row" gap="2px">
          <Button
            variant="contained"
            size="medium"
            onClick={() => onOk && onOk(selectedAmount)}
            fullWidth
            startIcon={<SvgIcon name="check-fill" />}
            sx={{
              maxWidth: '357px',
            }}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            size="medium"
            onClick={() => {
              if (onCancel) {
                onCancel()
              }
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </DialogWrapper>
  )
}
