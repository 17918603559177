import { BigNumber, utils } from "ethers";
import { TokenType } from "src/helpers/types";

const formatTokenToStringInternal= (
    bigNumber: bigint | number | string | undefined,  
    decimals: number = 18, 
    decimalPlaces: number = 6, 
    roundDown: boolean = false, 
    formatResult: boolean = false
): string => {
    if (bigNumber === undefined || bigNumber === "-") {
        return "-";
    }

    // If bigNumber is a string, convert it to a bigint
    if (typeof bigNumber === "string") {
        bigNumber = BigInt(bigNumber);
    }
    const res = parseFloat(utils.formatUnits(bigNumber.toString(), decimals));
    let formattedResult: string;

    if (roundDown) {
        const factor = Math.pow(10, decimalPlaces);
        formattedResult = (Math.floor(res * factor) / factor).toFixed(decimalPlaces);
    } else {
        formattedResult = res.toFixed(decimalPlaces);
    }

    // Remove trailing zeros
    formattedResult = Number(formattedResult).toString();

    if (formatResult) {
        formattedResult = formattedResult.replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(/(\.\d*)\s/g, "$1");
    }  

    return formattedResult;
};

function addCurrencySymbol(amount:string|undefined, tokenType:TokenType) : string {
    if (amount === undefined || amount === "-") {
        return "-";
    }
    switch (tokenType) {
        case TokenType.WETH:
            return `${amount} WETH`;
        case TokenType.CT:
            return `${amount} CT`;
        case TokenType.ETH:
            return `${amount} ETH`;
        default:
            return `${amount}`;
    }
}

const formatTokenToNumberInternal = (
    bigNumber: bigint | string | undefined | null, 
    decimals: number = 18, 
    decimalPlaces: number = 6, 
    roundDown: boolean = false
): number | undefined => {
    // Handle cases where bigNumber is undefined, null, or "-"
    if (bigNumber === undefined || bigNumber === null || bigNumber === "-") {
        return undefined;
    }

    // If bigNumber is a string, convert it to a bigint
    if (typeof bigNumber === "string") {
        bigNumber = BigInt(bigNumber);
    }

    const res = parseFloat(utils.formatUnits(bigNumber, decimals));
    let formattedResult;

    if (roundDown) {
        const factor = Math.pow(10, decimalPlaces);
        formattedResult = Math.floor(res * factor) / factor;
    } else {
        formattedResult = parseFloat(res.toFixed(decimalPlaces));
    }

    return formattedResult;
};

export const formatTokenToString = (
    bigNumber:bigint | number | string | undefined, 
    tokenType:TokenType,  
    decimalPlaces: number = 6, 
    roundDown: boolean = false, 
    formatResult: boolean = false,
    addSymbol: boolean = false ) : string => {
    switch (tokenType) {
        case TokenType.CT: 
        case TokenType.WETH:
        case TokenType.ETH:
            const res = formatTokenToStringInternal(bigNumber, 18, decimalPlaces, roundDown, formatResult);
            if (addSymbol) {
                return addCurrencySymbol(res, tokenType);
            }
            return res;
        default:
            return formatTokenToStringInternal(bigNumber, 18, decimalPlaces, roundDown, formatResult);
    }
}

export const formatTokenToStringWithSymbol = (
    bigNumber:bigint | number | string | undefined, 
    tokenType:TokenType) : string => {
    return formatTokenToString(bigNumber, tokenType, 6, false,false, true);
}

export const formatTokenToStringWithSeparator = (
    bigNumber:bigint | number | string | undefined, 
    decimalPlaces: number,
    tokenType:TokenType) : string => {
    return formatTokenToString(bigNumber, tokenType, decimalPlaces, false,true, false);
}


export const formatTokenToNumber = (
    bigNumber:bigint | string | undefined | null, 
    tokenType:TokenType,  
    decimalPlaces: number = 6, 
    roundDown: boolean = false) : number | undefined => {
    switch (tokenType) {
        case TokenType.CT || TokenType.WETH || TokenType.ETH:
            return formatTokenToNumberInternal(bigNumber, 18, decimalPlaces, roundDown);
        default:
            return undefined;
    }
}

export const toToken = (input:string,decimalPlaces:number=18) : BigNumber => {
    return utils.parseUnits(input, decimalPlaces);
}

export function toTokenString(ether: number | string | undefined,decimalPlaces:number=18): string {
    if (ether === undefined) {
      return ''; // Return an empty string or handle it as needed
    }
    // Convert the ether value to a BigNumber and then to a wei string
    const wei = utils.parseUnits(ether.toString(),decimalPlaces);
    return wei.toString();
  }


