import React from 'react'
import { Box, Stack, StackProps } from '@mui/material'

type CustomHorizontalDividerProps = {
  thickLineColor?: string
  thinLineColor?: string
  thinLineOpacity?: number
  rootStackProps?: StackProps
}

export default function CustomHorizontalDivider({
  thickLineColor = 'gray.600',
  thinLineColor = 'gray.600',
  thinLineOpacity = 0.4,
  rootStackProps,
}: CustomHorizontalDividerProps) {
  return (
    <Stack direction="row" {...rootStackProps}>
      <Box width="37px" height="4px" bgcolor={thickLineColor} />
      <Box
        width="50px"
        height="1px"
        bgcolor={thinLineColor}
        sx={{
          opacity: thinLineOpacity,
        }}
      />
    </Stack>
  )
}
