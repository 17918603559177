import React from 'react'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import { Stack, StackProps } from '@mui/material'
import ImageBox, { ImageBoxProps } from 'src/components/ImageBox/ImageBox'
import Image, { ImageProps } from 'src/components/Image/Image'

export type ImageWithTitleProps = {
  rootStackProps?: StackProps
  imageBoxProps?: ImageBoxProps
  imageProps: ImageProps
  typographyProps?: TypographyProps
  title: string
}

export default function ImageWithTitle(props: ImageWithTitleProps) {
  const { title, rootStackProps, imageBoxProps, imageProps, typographyProps } = props

  const { sx: typographySx, ...restTypographyProps } = typographyProps || {}

  return (
    <Stack direction="row" alignItems="center" spacing="14px" {...rootStackProps}>
      <ImageBox {...imageBoxProps}>
        <Image alt={title} {...imageProps} />
      </ImageBox>
      <Typography
        variant="h4"
        textTransform="uppercase"
        {...restTypographyProps}
        sx={{ transform: 'translateY(2px)', ...typographySx }}
      >
        {title}
      </Typography>
    </Stack>
  )
}
