import React from 'react'
import { Box, BoxProps, useTheme } from '@mui/material'

type BgLinesWrapperProps = {
  BoxProps?: BoxProps
  children: React.ReactNode
}

export default function BgLinesWrapper({ children, ...restProps }: BgLinesWrapperProps) {
  const theme = useTheme()
  return (
    <Box
      {...restProps?.BoxProps}
      sx={{
        m: '1px',
        mt: '2px',
        p: '8px',
        border: '1px solid',
        borderColor: 'gray.800',
        background: theme.palette.background.linesFooter,
        ...restProps?.BoxProps?.sx,
      }}
    >
      {children}
    </Box>
  )
}
