import React from 'react'
import { Box, Stack, StackProps as MuiStackProps } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import Comment from '../Comment/Comment'

enum Variants {
  Win = 'win',
  Lose = 'lose',
}
type VariantsT = `${Variants}` // Co "Enum Position" values to input options

type ProfileRateBoxProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  title: string
  totalGames: number
  currentValue: number
  showPercentage?: boolean
  variant?: VariantsT
}

export default function ProfileRateBox({
  title,
  totalGames,
  currentValue,
  showPercentage = true,
  variant = Variants.Win,
  ...StackProps
}: ProfileRateBoxProps) {
  const heightPercent = (currentValue / (totalGames / 100)).toFixed(2);
  const showValue = showPercentage ? `${heightPercent}%` : currentValue
  const variantColor = variant === Variants.Win ? 'orange' : 'gray.400'
  return (
    <Stack
      minWidth="99px"
      height={{ xs: '153px', sm: '95px' }}
      sx={{
        background:
          'repeating-linear-gradient(41deg, transparent, transparent 3px, #121A2A 3px, #121A2A 5px)',
      }}
      {...StackProps}
    >
      <Stack justifyContent="center" bgcolor="gray.900" height="20px" pl="2px">
        <Comment rootStackProps={{ spacing: '2px' }}>{`${title}`}</Comment>
      </Stack>

      <Box display="flex" flexGrow={1}>
        <Box
          height={`${heightPercent}%`}
          bgcolor={variantColor}
          position="relative"
          mt="auto"
          width="100%"
          sx={{
            transition: 'height 0.4s ease-in-out',
          }}
        >
          <Typography
            variant="h7"
            color={variantColor}
            marginTop="auto"
            textAlign="right"
            position="absolute"
            top="-19px"
            right="2px"
          >
            {showValue}
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}
