import { Stack, useMediaQuery, useTheme } from '@mui/material'
import React, { useMemo, useState } from 'react'
import StakingBlockItem, { BlockItemData } from '../StakingBlockItem/StakingBlockItem'
import Comment from '../Comment/Comment'
import { RiGift2Line, RiPercentLine, RiSafeLine } from '@remixicon/react'
import SimpleTabs, { SimpleTabPanel, SimpleTabsWrapper } from '../SimpleTabs/SimpleTabs'
import { FlexiStakingType, VestedStakingType } from 'src/helpers/stakingTypes'
import { emptyStakingData, flexiStakingData, vestedStakingData } from 'src/helpers/stakingData'

export type BlockItemT = {
  title: string
  bottomTitle: string
  data: BlockItemData
}

type StakingBlocksWrapperProps = {
  // Add your props here
  flexiStaking?: FlexiStakingType
  vestedStaking?: VestedStakingType
}

function StakingBlocksWrapper({flexiStaking,vestedStaking}: StakingBlocksWrapperProps) {
  const theme = useTheme()
  const isLg2 = useMediaQuery(theme.breakpoints.down('lg2'))
  const themeColor = theme.palette.gray[300];
 
  const currentFlexiStakingData = useMemo(() => {
      return  flexiStaking!==undefined ? flexiStakingData(flexiStaking, themeColor) : emptyStakingData('Flexi staking', themeColor);
  }, [flexiStaking,themeColor]);  
  
  const currentVestedStakingData= useMemo(() => {
    return vestedStaking!==undefined ? vestedStakingData(vestedStaking, themeColor): emptyStakingData('Vested staking', themeColor);
  }, [vestedStaking,themeColor]);  
  
  const data: BlockItemT[] = [currentFlexiStakingData,currentVestedStakingData];

  const [activeTab, setActiveTab] = useState<number>(0)
  const tabs = ['Flexi', 'Vested']

  const handleTabs = (val: number) => {
    setActiveTab(val)
  }

  return (
    <>
      <Comment>Staking pools</Comment>
      {isLg2 ? (
        <SimpleTabsWrapper showBorder BoxProps={{ mb: { xs: '37px', md834: '49px' } }}>
          <SimpleTabs list={tabs} value={activeTab} onChange={handleTabs} showBorder={false} />
          <Stack direction="row" gap="27px">
            {data?.map((item, index) => (
              <SimpleTabPanel
                key={`keyId2${index}`}
                value={activeTab}
                index={index}
                BoxProps={{
                  sx: {
                    width: '100%',
                    bgcolor: 'transparent',
                    '& > div': { bgcolor: 'transparent', p: 0, m: 0 },
                  },
                }}
              >
                <StakingBlockItem
                  key={`keyId3${index}`}
                  {...item}
                  StackProps={{ sx: { border: 'none', width: '100%' } }}
                />
              </SimpleTabPanel>
            ))}
          </Stack>
        </SimpleTabsWrapper>
      ) : (
        <Stack direction="row" gap="27px" mb="49px">
          {data?.map((item) => (
            <StakingBlockItem key={item.title} {...item} />
          ))}
        </Stack>
      )}
    </>
  )
}

export default StakingBlocksWrapper
