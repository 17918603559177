import React from 'react'
import { Typography, Stack, StackProps as MuiStackProps, styled, useTheme } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TitleWithDot from '../TitleWithDot/TitleWithDot'

type ProfileHotStreakBoxProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  title: string
  data: {
    title: string
    value: number
  }[]
}

function ProfileHotStreakBox({ title, data, ...StackProps }: ProfileHotStreakBoxProps) {
  const theme = useTheme()
  return (
    <Stack
      bgcolor="gray.900"
      height={{ xs: '128px', sm: '90px', md: '74px' }}
      direction="row"
      gap={{ xs: '29px', sm: '17px' }}
      border="1px solid"
      borderColor="gray.700"
      sx={{
        transition: 'border-color 0.3s ease-in-out',
        '&:hover': { borderColor: 'gray.500' },
      }}
      {...StackProps}
    >
      <Stack
        direction="row"
        alignItems="center"
        pl="18px"
        minWidth="160px"
        width="31.7%"
        gap="10px"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '29.7%',
          },
          [theme.breakpoints.down('sm')]: {
            width: '86px',
            minWidth: 'unset',
            justifyContent: 'flex-start',
            paddingTop: '15px',
          },
        }}
      >
        <SvgIcon name="medal" sx={{ fontSize: '21px' }} />
        <TitleWithDot
          typographyProps={{ variant: 'h7' }}
          dotProps={{
            width: { xs: '7px', sm: '4px' },
            left: { xs: '-5px', sm: 'unset' },
            height: '4px',
            bottom: '5px',
          }}
          rootStackProps={{ spacing: { xs: '0', sm: '5px' }, alignItems: 'center' }}
        >
          {title}
        </TitleWithDot>
      </Stack>

      <Stack
        direction="row"
        flexWrap="wrap"
        flexGrow={1}
        sx={{
          [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            paddingTop: '15px',
            gap: '12px 0',
          },
        }}
      >
        {data?.map((val, index) => (
          <StyledInfoStack key={index} direction="column">
            <Typography variant="paragraphSmall" color="gray.500" mb="3px">
              {val.title}
            </Typography>
            <Typography variant="h7">{val.value}</Typography>
          </StyledInfoStack>
        ))}
      </Stack>
    </Stack>
  )
}

const StyledInfoStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  paddingTop: '1px',
  gap: '0',
  '&:first-of-type': {
    width: '23%',
  },
  '&:nth-of-type(2)': {
    width: '26%',
  },
  '&:nth-of-type(3)': {
    width: '25%',
  },
  '&:nth-of-type(4)': {
    width: '26%',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '0',
    '&:first-of-type': {
      width: '26.5%',
    },
    '&:nth-of-type(2)': {
      width: '24%',
    },
    '&:nth-of-type(3)': {
      width: '25%',
    },
    '&:nth-of-type(4)': {
      width: '24.5%',
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-of-type, &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4)': {
        width: '50%',
      },
    },
  },
}))

export default ProfileHotStreakBox
