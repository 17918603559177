import { useEffect, useState } from "react";
import LobbyStatusBar, { LobbyStatusBarProps, Status } from "./LobbyStatusBar";
import { ActiveChallengeType, ChallengeType, TournamentType } from "src/helpers/types";
import { localeDateFormat } from "src/utils/datetime";
import dayjs from "dayjs";

export type LobbyStatusBarChangingTextProps = LobbyStatusBarProps & {
    startTime: Date
}


export default function LobbyStatusBarChangingText({
    status = Status.Waiting,
    text,
    stackProps,
    hideButton = false,
    btnProps,
    closeToast,
    startTime,
    ...restProps
  }: LobbyStatusBarChangingTextProps){

    text = getMessage(getDiff(startTime),startTime);
    const [message, setMessage] = useState(text);



    useEffect(() => {
        const interval = setInterval(() => {

          const timeDiff = getDiff(startTime);   
          setMessage(getMessage(timeDiff,startTime));
          if(timeDiff <= 0){
            clearInterval(interval);
          }
          
        }, 10000); // Update every 10 seconds
    
        return () => clearInterval(interval); // Clear interval on component unmount
      }, [startTime]);
  

    return (
        <LobbyStatusBar status={status} text={message} stackProps={stackProps} 
            hideButton={hideButton} btnProps={btnProps} closeToast={closeToast} {...restProps}/>
    );  


}

function getDiff(date: Date) {
    const now = new Date();
    const date2 = new Date(date);
    return date2.getTime() - now.getTime(); // difference in milliseconds
}

function getMessage(timeDiff:number,date: Date){
    if (timeDiff <= 5 * 60 * 1000 && timeDiff > 0) { // Less than 5 minutes
        return 'Tournament starts in less than 5 minutes!';
    } else if (timeDiff <= 0) {
        return 'Tournament is starting!';
    }
    else {
        return `Tournament starts\n\r ${dayjs(date).format('DD MMM YYYY HH:mm A')}`;
    }
}
