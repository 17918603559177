import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import  MainLayout  from './layouts/MainLayout'
import { LayoutBasic } from './layouts/BasicLayout'
import { RequireAuth } from './utils/requireAuth'
import Logout from './pages/logout'
import { logout } from "./actions/userActions";
import { changedVisibility } from "./actions/infoPanelActions";
import NotFound from './pages/notFound';
import Faq from './pages/faq';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import PageVisibility from 'react-page-visibility';
import  Home  from './pages/index';
import { Registration } from 'src/pages/registration';
import RootLayout from './layouts/RootLayout'
import Challenges from './pages/challenges'
import Game from './pages/challenges/game'
import Lobby from './pages/lobby'
import LobbyHistory from './pages/lobby/history'
import  Leaderboard  from './pages/leaderboard';
import  Profile  from './pages/profile';
import  TournamentSignup  from './pages/tournament/signup';
import Tournament from './pages/tournament';
import Staking from './pages/staking';
import { useAppDispatch } from 'src/hooks';
import { ThirdwebProvider, metamaskWallet, embeddedWallet, walletConnect } from "@thirdweb-dev/react";
import { Ganache, Sepolia, Ethereum } from "@thirdweb-dev/chains";
import { EnvironmentType, config } from 'src/config/config';
import { ROUTE_PATH } from 'src/routes';
import { HelmetProvider } from 'react-helmet-async';
import Info from 'src/pages/info';

export default function App() {
    const loggedIn = useSelector(state => state.userReducer.loggedIn);
    const user = useSelector(state => state.userReducer.userInfo);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isStakingPage = true; //location.pathname===ROUTE_PATH.staking;
   
    //Ganache
    const localhost = {
            ...Ganache,
            name:config.blockchain.chainName,
            slug:config.blockchain.slug,
            title:config.blockchain.chainDisplayName,
            chainId:config.blockchain.chainIdNumber,
            rpc:[config.blockchain.rpc],
            explorers:[config.blockchain.explorer],
            faucets:[config.blockchain.faucet],
            nativeCurrency: {
                name: config.blockchain.nativeCurrencyName,
                symbol: "ETH",
                decimals: 18
            }
    };  

    //Ludus
    const localhostStaking = {
        ...Sepolia,
        name:config.blockchainStaking.chainName,
        slug:config.blockchainStaking.slug,
        title:config.blockchainStaking.chainDisplayName,
        chainId:config.blockchainStaking.chainIdNumber,
        rpc:[config.blockchainStaking.rpc],
        explorers:[config.blockchainStaking.explorer],
        faucets:[config.blockchainStaking.faucet],
        nativeCurrency: {
            name: config.blockchainStaking.nativeCurrencyName,
            symbol: "ETH",
            decimals: 18
        }
};  
    //Ludus
    const stage = {
        ...Sepolia,
        name:config.blockchain.chainName,
        slug:config.blockchain.slug,
        title:config.blockchain.chainDisplayName,
        chainId:config.blockchain.chainIdNumber,
        rpc:[config.blockchain.rpc],
        explorers:[config.blockchain.explorer],
        faucets:[config.blockchain.faucet],
        nativeCurrency: {
            name: config.blockchain.nativeCurrencyName,
            symbol: "ETH",
            decimals: 18
        }
    };

    //Ludus
    const stageStaking = {
        ...Sepolia,
        name:config.blockchainStaking.chainName,
        slug:config.blockchainStaking.slug,
        title:config.blockchainStaking.chainDisplayName,
        chainId:config.blockchainStaking.chainIdNumber,
        rpc:[config.blockchainStaking.rpc],
        explorers:[config.blockchainStaking.explorer],
        faucets:[config.blockchainStaking.faucet],
        nativeCurrency: {
            name: config.blockchainStaking.nativeCurrencyName,
            symbol: "ETH",
            decimals: 18
        }
    };

    const SelectedChain = config.environment === EnvironmentType.DEVELOPMENT 
        ? 
        (isStakingPage ? localhostStaking: localhost) 
        : 
        (config.environment === EnvironmentType.STAGE 
            ? 
            (isStakingPage ? stageStaking: stage)  
            : 
            Ethereum
        );
            
        useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => onAccountsChanged(accounts));
            window.ethereum.on('chainChanged',(chainId) =>  onChainChanged(chainId));
        
        }
        window.addEventListener("focus", onFocus)

        return function cleanup() {
            if (window.ethereum) {
                window.ethereum.removeListener("accountsChanged", onAccountsChanged);
                window.ethereum.removeListener("accountsChanged",onChainChanged);
            }
            window.removeEventListener("focus", onLostFocus);
        };
    }, [loggedIn]); 

    const onFocus = () => {
        console.log('onFocus');
    }


    const onLostFocus = () => {
        console.log('onLostFocus');
    }

    const onAccountsChanged= (accounts) => {
       {/* console.log('accountsChanges', accounts);
        if (loggedIn) {
            dispatch(logout());
        } */}
    }

    const onChainChanged= (chainId) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        // window.location.reload();
        console.log('chain changed', chainId);
       /* if (loggedIn) {
            dispatch(logout());
        }*/
    }

      
    useEffect(() => {
        // Initialize dataLayer
        window.dataLayer = window.dataLayer || [];
        
        function gtag() { window.dataLayer.push(arguments); }
        // Ensure gtag() uses window.dataLayer explicitly if there's a scope issue
        window.gtag = gtag;
    
        if (config.environment === EnvironmentType.PRODUCTION) {
          const script = document.createElement('script');
          script.src = 'https://www.googletagmanager.com/gtag/js?id=G-RQFKT44FMY';
          script.async = true;
          document.head.appendChild(script);
    
          // Load the Google Analytics script
          script.onload = () => {
            window.gtag('js', new Date());
            window.gtag('config', 'G-RQFKT44FMY');
          };
        }
      }, []);
    
    return (     
            <ThirdwebProvider activeChain={SelectedChain} clientId={config.thirdWeb.clientId}
                supportedWallets={[ metamaskWallet(),embeddedWallet(),walletConnect({projectId: config.thirdWeb.walletConnectProjectId, qrModal: "walletConnect"})]}>
                <HelmetProvider>
                    <PageVisibility onChange={(isVisible) => dispatch(changedVisibility(isVisible))}>
                        <Routes>
                        <Route element={<RootLayout />}>
                        
                            {/* If we go to registraion when we are already setup we redirect to root*/}
                            <Route path="/registration" element={loggedIn && user.nickname ? <Navigate replace to={"/"}/> : <LayoutBasic />}>
                                <Route index 
                                    element={<RequireAuth redirectTo="/"> {<Registration />} </RequireAuth>} 
                                />
                            </Route>
                        

                            <Route path="/logout" element={<LayoutBasic />}>
                                <Route index element={<Logout />} />
                            </Route>
                
                            <Route path='*' element={<MainContainer user={user} loggedIn={loggedIn} />} />
                        
                        </Route>
                        </Routes>
                    </PageVisibility>
                </HelmetProvider>
            </ThirdwebProvider> 
    );
    
}



const MainContainer = ({user,loggedIn}) => (   
    <Routes>
        <Route path="/"  element={ loggedIn && !user.nickname ? <Navigate replace to={"/registration"} state={{ from: location.pathname }}/>  : <MainLayout />  }>

            <Route index element={ <Staking /> } />  {/*<Home />*/}


       {/*     <Route exact path='/challenges' element={<Challenges/>} />
          
            <Route path='/challenges/game/:gameId' element={<Game/>} />

            <Route path='/leaderboard' element={<Leaderboard/>} />

            <Route path='/tournaments/:tournamentId/signup' element={<TournamentSignup/>} />

            <Route path='/staking' element={<Staking/>} />
          
            <Route path='/lobby/:challengeId' 
                element={<RequireAuth redirectTo="/"> {<Lobby/>} </RequireAuth>} 
             />
            <Route path='/lobby/history/:challengeId' 
                element={<RequireAuth redirectTo="/"> {<LobbyHistory/>} </RequireAuth>} 
             /> 
           

            <Route path='/tournaments/:tournamentId' 
                element={<RequireAuth redirectTo="/"> {<Tournament/>}</RequireAuth>} 
            />
            */}
             <Route path='/profile' 
                element={<RequireAuth redirectTo="/"> {<Profile/>}</RequireAuth>} 
            />

            <Route path='/info' element={<Info/>} />

{/*
            <Route path='/faq' element={<Faq/>} />
            <Route path='/terms-of-agreement' element={<Terms/>} />
            <Route path='/privacy-policy' element={<Privacy/>} />
*/}
           <Route path='*' element={<NotFound/>} />
        </Route>
    </Routes>  
)


