import React from 'react'
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle'
import { TypographyProps as TextTypographyProps } from 'src/components/Typography/Typography'
import { useMediaQuery, useTheme } from '@mui/material'

type ImageWithTitleSmallProps = {
  text: string
  icon: string
  /** [The root element of this component uses the TypographyProps from Material UI](https://mui.com/material-ui/react-typography/) */
  TypographyProps?: TextTypographyProps
  mobileHideTitle?: boolean
}
function ImageWithTitleSmall({
  text,
  icon,
  mobileHideTitle,
  TypographyProps,
}: ImageWithTitleSmallProps) {
  const { sx: typographySx, ...restTypographyProps } = TypographyProps || {}
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const hideMobile = mobileHideTitle ? isSmView : false
  return (
    <ImageWithTitle
      imageBoxProps={{
        width: '24px',
        height: '24px',
      }}
      imageProps={{
        width: '18px',
        height: '18px',
        src: icon,
      }}
      title={text}
      typographyProps={{
        variant: 'uiElement',
        display: hideMobile ? 'none' : 'block',
        sx: {
          ml: '4px !important',
          ...typographySx,
        },
        ...restTypographyProps,
      }}
    />
  )
}

export default ImageWithTitleSmall
