import ICONS, { PNG_ICONS } from './icons/icons'
import VIDEOS from './video'

const assets = {
  svg: ICONS,
  png: PNG_ICONS,
  video: VIDEOS,
}

export default assets
