import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Stack, Box, Avatar } from '@mui/material'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import OnlineBlock from 'src/components/OnlineBlock/OnlineBlock'

export type HeaderUserCellProps = {
  user: {
    name: string
    avatarUrl: string
    isOnline: boolean
  }
  onClick?: () => void
  breakpoint?: number
}

const HeaderUserCell = ({ user, onClick }: HeaderUserCellProps) => {
  const theme = useTheme()
  const isLessThan720 = useMediaQuery(theme.breakpoints.down('md720'))

  return (
    <Stack
      direction="row"
      gap="8px"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        border: '1px solid',
        borderColor: 'gray.700',
        padding: '2px',
        width: '207px',
        height: '64px',
        '& .AvatarBlock': {
          '& .MuiAvatar-root': {
            transform: 'translateY(-58px)',
            transition: 'transform 0.3s ease-in-out',
          },
          '& .MuiButtonBase-root': {
            transform: 'translateY(-58px)',
            transition: 'transform 0.3s ease-in-out',
          },
        },
        '&:hover': {
          borderColor: 'gray.500',
          '& .MuiTypography-labelSmall': {
            color: 'gray.0',
          },
          '& .AvatarBlock': {
            '& .MuiAvatar-root': {
              transform: 'translateY(58px)',
              transition: 'transform 0.3s ease-in-out',
            },
            '& .MuiButtonBase-root': {
              transform: 'translateY(0px)',
            },
          },
        },

        [theme.breakpoints.down('md720')]: {
          border: 'none',
          p: 0,
          width: '125px',
          height: '44px',
          '& .AvatarBlock': {
            '& .MuiAvatar-root': {
              transform: 'translateY(-44px)',
            },
            '& .MuiButtonBase-root': {
              transform: 'translateY(-44px)',
            },
          },
          '&:hover': {
            '& .MuiTypography-paragraphSmall': {
              color: 'gray.0',
            },
            '& .AvatarBlock': {
              '& .MuiAvatar-root': {
                transform: 'translateY(44px)',
              },
              '& .MuiButtonBase-root': {
                transform: 'translateY(0px)',
              },
            },
          },
        },
      }}
    >
      <Stack
        direction="column"
        gap={isLessThan720 ? '6px' : '4px'}
        alignItems="flex-end"
        flexGrow={1}
      >
        <Typography variant={isLessThan720 ? 'paragraphSmall' : 'labelSmall'} color="gray.25">
          {user.name}
        </Typography>
        <OnlineBlock isOnline={user.isOnline} />
      </Stack>
      <Box
        className="AvatarBlock"
        sx={{
          width: '58px',
          height: '58px',
          overflow: 'hidden',
          [theme.breakpoints.down('md720')]: {
            width: '44px',
            height: '44px',
          },
        }}
      >
        {isLessThan720 ? (
          <ButtonIcon variant="contained" size="small" onClick={onClick}>
            <SvgIcon name="more-line" />
          </ButtonIcon>
        ) : (
          <Box bgcolor="gray.800" p="2px">
            <ButtonIcon variant="contained" size="medium" onClick={onClick}>
              <SvgIcon name="more-line" />
            </ButtonIcon>
          </Box>
        )}
        <Avatar
          src={user.avatarUrl}
          variant="square"
          sx={{
            width: '58px',
            height: '58px',
            [theme.breakpoints.down('md720')]: {
              width: '44px',
              height: '44px',
              p: '1px',
              border: '1px solid',
              borderColor: 'gray.700',
              '&:hover': {
                borderColor: 'gray.500',
              },
            },
          }}
        />
      </Box>
    </Stack>
  )
}

export default HeaderUserCell
