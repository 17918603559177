import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableContainerProps as MuiTableContainerProps,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import Typography from '../Typography/Typography'
import { useAppSelector } from 'src/hooks'
import { filteredGlobalEvents } from 'src/selectors/globalLogEventsSelector'
import { globalLiveEventTableMapper } from 'src/helpers/data'

// Typing of the table Data
export type TableDataT = {
  headRow: { cell: { value: string; color?: string; width?: string }[] }
  bodyRow: {
    cell: { value: string | (() => React.ReactNode); color?: string }[] | null
  }[]
}

type LiveActivityNewProps = {
  /** [The root element of this component uses the TableContainerProps from Material UI](https://mui.com/material-ui/react-table/) */
  TableContainerProps?: MuiTableContainerProps
  gameId?: string
 // data: TableDataT
}

function LiveActivityNew({ TableContainerProps, gameId }: LiveActivityNewProps) {
  const { sx, ...restProps } = TableContainerProps || {}
  const theme = useTheme();
  const isBelowLg3 = useMediaQuery(theme.breakpoints.down('lg3'));
  const data = globalLiveEventTableMapper(useAppSelector(state => filteredGlobalEvents(state, gameId)));
  
  return (
    <TableContainer
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        overflow: 'visible',
        ...sx,
      }}
      {...restProps}
    >
      <Stack direction="row" sx={{ width: 'auto' }}>
        {data?.headRow?.cell?.map((th, indexHead) => (
          <StyledHeadCell key={indexHead}>
            <Typography variant="uiElement">{th.value}</Typography>
          </StyledHeadCell>
        ))}
      </Stack>
      <Box
        overflow="auto"
        width="100%"
        maxHeight="auto"
        position="relative"
        sx={{ transition: 'max-height 0.4s ease-in-out' }}
      >
        <StyledTable isblowlg3={isBelowLg3.toString()} aria-label="customized table">
          <TableBody>
            {data.bodyRow.map((row, index) => (
              <StyledTableRow key={index}>
                <>
                  {row?.cell?.map((cell, indexCell) => (
                    <StyledTableCell isblowlg3={isBelowLg3.toString()} key={indexCell}>
                      {typeof cell.value === 'function'
                        ? cell.value()
                        : cell.value && (
                            <Typography variant="bodySmall" {...textTypographyColor(cell.color)}>
                              {cell.value}
                            </Typography>
                          )}
                    </StyledTableCell>
                  ))}
                </>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    </TableContainer>
  )
}

// ===
// === Stylization table START
const tableStyleParams = {
  common: {
    td: {
      height: 50,
      firstWidth: '21%',
      secondWidth: '30%',
      thirdWidth: '27%',
      fourthWidth: '22%',
    },
  },
}

const StyledHeadCell = styled(Box)(({ theme }) => ({
  padding: '2px 0px 0 9px',
  borderBottom: 'none',
  backgroundColor: theme.palette.gray[700],
  color: theme.palette.gray[500],
  height: '30px',
  flexGrow: 1,
  alignItems: 'center',
  display: 'flex',
  '&:first-of-type': {
    width: tableStyleParams.common.td.firstWidth,
  },
  '&:nth-of-type(2)': {
    width: tableStyleParams.common.td.secondWidth,
  },
  '&:nth-of-type(3)': {
    width: tableStyleParams.common.td.thirdWidth,
  },
  '&:nth-of-type(4)': {
    width: tableStyleParams.common.td.fourthWidth,
  },
  [theme.breakpoints.down('md')]: {
    width: '25%',
  },
}))

// Apply styles to the Table, isblowlg3 must be a string and lowercase,otherwise warning in browser console
const StyledTable = styled(Table)<{ isblowlg3?: string }>(({ isblowlg3 }) => ( isblowlg3==='false' ? {
  width: '100%',
  tableLayout: 'fixed', // Ensure the table layout is fixed
}:
{}
));

//isblowlg3 must be a string and lowercase,otherwise warning in browser console
const StyledTableCell = styled(TableCell)<{ isblowlg3?: string }>(({ theme, isblowlg3 }) => ({
  padding: '0 0 0 8px',
  borderBottom: 'none',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    width: tableStyleParams.common.td.firstWidth,
  },
  '&:nth-of-type(2)': {
    width: tableStyleParams.common.td.secondWidth,
    overflow: isblowlg3==='false' ? 'hidden' : 'visible',
    textOverflow: isblowlg3==='false' ? 'ellipsis' : 'clip',
  },
  '&:nth-of-type(3)': {
    width: tableStyleParams.common.td.thirdWidth,
  },
  '&:nth-of-type(4)': {
    width: tableStyleParams.common.td.fourthWidth,
  },
  [`&.${tableCellClasses.body}`]: {
    height: `${tableStyleParams.common.td.height}px`,
    color: theme.palette.gray[25],
  },
  [theme.breakpoints.down('md')]: {
    width: '25%',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Common styles for <tr>
  backgroundColor: theme.palette.gray[900],
  border: `1px solid ${theme.palette.gray[700]}`,
  borderTopWidth: '2px',
  '&:first-of-type': {
    borderTopWidth: 0,
  },
  position: 'relative',
  '& td:first-of-type:before': {
    content: '" "',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: '#4B5873',
    opacity: 0,
    transition: 'opacity ease-in-out 0.25s',
    zIndex: 2,
  },
  '&:hover': {
    '& td:first-of-type:before': {
      opacity: 1,
    },
  },
}))
// === Stylization table END
// ===

const textTypographyColor = (val: string | undefined) => {
  let out = {}
  if (val) {
    out = { color: val }
  }
  return out
}

export default LiveActivityNew
