import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'

const leaderboardSelector = (state:RootState, gameId:string|undefined, gameMode:string|undefined) =>
   (gameMode!==undefined ? 
        state.leaderboardReducer.topLists && state.leaderboardReducer.topLists.filter((x:any) => x.intGameId === gameId && x.gameMode === gameMode)
        :
        state.leaderboardReducer.topLists && state.leaderboardReducer.topLists.filter((x:any) => x.intGameId === gameId)
   )
export const getLeaderboardCollection = createSelector(
    leaderboardSelector,
    (leaderboardCollection) => {
          return leaderboardCollection;
    }
);