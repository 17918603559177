export function clear() {
    return { type: "CLEAR_USER" };
}

export function logout() {
    return { type: "LOGOUT_USER" };
}

export function login(username, password) {
    return { type: "LOGIN_USER", username: username, password: password };
}

export function loginWallet(from) {
    return { type: "LOGIN_USER_WALLET", from: from };
}

export function forgotPassword(email) {
    return { type: "FORGOT_PASSWORD", email: email };
}

export function resetPassword(id, code, password) {
    return { type: "RESET_PASSWORD", id: id, code: code, password: password };
}
export function getUser() {

    return { type: "GET_USER", forceRefresh : false};
}

export function refreshUser() {
    return { type: "GET_USER", forceRefresh: true };
}

export function registrationResult(result) {
    return { type: "NICKNAME_RESULT", result: result };
}

export function registerUser(firstName, lastName, countryIso, username, password, confirmPassword) {
    return { type: "REGISTER_USER", firstName: firstName, lastName: lastName, countryIso: countryIso, username: username, password: password, confirmPassword: confirmPassword};
}

export function registerUserStepTwo(firstName, lastName, address1, address2, zip, city, state, countryIso) {
    return { type: "REGISTER_USER_STEP_TWO", firstName: firstName, lastName: lastName, address1: address1, address2: address2, zip: zip, city: city, state: state, countryIso: countryIso };
}

export function registerUserStepThree(email, password) {
    return { type: "REGISTER_USER_STEP_THREE", email: email, password: password}
}

export function epicVerification2FA(code) {
    return { type: "EPIC_VERIFICATION_2FA", code: code}
}

export function getEpicAccountFromCode(code) {
    return { type: "EPIC_ACCOUNT_FROM_CODE", code: code }
}

export function verifyEmail(id, code) {
    return { type: "VERIFY_EMAIL", id: id, code: code }
}

export function displayError(e) {
    return { type: "ERROR_CODE", e}
}

export function getTransactions() {
    return { type: "GET_TRANSACTIONS" }
}

export function updateUser(firstName, lastName, address1, address2, zip, city, state, countryIso) {
    return { type: "UPDATE_USER", firstName: firstName, lastName: lastName, address1: address1, address2: address2, zip: zip, city: city, state: state, countryIso: countryIso };
}

/*
export function updateUserNickname(nickname) {
    return { type: "UPDATE_USER_NICKNAME", nickname: nickname };
}

export function registerPlayFabUser(password) {
    return { type: "REGISTER_PLAYFAB_USER", password: password };
}*/


export function getMatchesPlayed() {
    return { type: "GET_MATCHES_PLAYED" };
}

export function uploadProfilePicture(file) {
    return { type: "UPLOAD_PROFILE_PICTURE", file: file };
}

export function changeAvatar(id, src) {
    return { type: "CHANGE_AVATAR", id ,src };
}

export function deposit(amount, transactionFee) {
    return { type: "DEPOSIT", amount: amount, transactionFee: transactionFee };
}

export function verifyDeposit(orderId,payerId) {
    return { type: "VERIFY_DEPOSIT", orderId: orderId, payerId: payerId };
}

export function cancelDeposit(orderId) {
    return { type: "CANCEL_DEPOSIT", orderId: orderId};
}

export function withdraw(amount, email) {
    return { type: "WITHDRAW", amount: amount, email: email };
}

export function redeemCode(code) {
    return { type: "REDEEM_CODE", code: code };
}

export function resetTryCount(code) {
    return { type: "RESET_TRY_COUNT"};
}

export function addTryCount(code) {
    return { type: "ADD_TRY_COUNT" };
}

export function validateGameUser(gameId, gameName, address, nonce) {
    return { type: "VALIDATE_GAME_USER", gameId: gameId, gameName: gameName, address: address, nonce: nonce };
}
export function userError(error) {
    return { type: "USER_ERROR", error: error };
}
export function updateBalance() {
    return { type: "UPDATE_BALANCE"};
}

export function setBalance(amount, balance, tokenType) {
    return { type: "SET_BALANCE", amount: amount, balance: balance, tokenType: tokenType };
}

export function userActionPreConfirm() {
    return { type: "USER_ACTION_PRE_CONFIRM" };
}

export function getETHBalance() {
    return { type: "GET_ETH_BALANCE" };
}   

export function swapETHToWETH(amount) {
    return { type: "SWAP_ETH_TO_WETH", amount: amount };
}