import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const MuiSvgIcon: {
  variants?: ComponentsVariants['MuiSvgIcon']
  styleOverrides?: ComponentsOverrides<Theme>['MuiSvgIcon']
} = {
  variants: [
    {
      props: { color: 'grey200' },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.grey[200],
      }),
    },
    {
      props: { color: 'gray300' },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.gray[300],
      }),
    },
    {
      props: { fontSize: 'extraLarge' },
      style: {
        fontSize: '40px',
        width: '40px',
      },
    },
    {
      props: { fontSize: 'extraSmall' },
      style: {
        fontSize: '18px',
        width: '18px',
      },
    },
  ],
}

export default MuiSvgIcon
