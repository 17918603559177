import React from 'react'
import { useTheme } from '@mui/material/styles'
import Link, { LinkProps } from 'src/components/Link/Link'

export type MenuItemProps = Omit<LinkProps, 'color'>

export default function MenuItem(props: MenuItemProps) {
  const theme = useTheme()
  return (
    <Link
      sx={{
        ...(props.sx || {}),
        padding: '28px 0px 23px',
        [theme.breakpoints.down('sm')]: {
          padding: '16px 0px',
        },
        color: theme.palette.gray[25],
        '& a': {
          textDecoration: 'none',
        },
        '& span': {
          height: '13px',
          '& div': {
            display: 'flex',
            alignItems: 'center',
            height: 'inherit',
          },
        },
        '& span:after': {
          content: '""',
          display: 'block',
          width: '37px',
          height: '4px',
          top: '4px',
          position: 'relative',
          background: 'transparent',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            // top: '6px',
          },
        },
        '.MuiTypography-root, &.active': {
          '& span:after': {
            background: theme.palette.gray[800],
            [theme.breakpoints.down('sm')]: {
              background: theme.palette.gray[700],
            },
          },
          color: theme.palette.common.white,
        },
        '&:hover': {
          color: theme.palette.gray[0],
        },
        '.MuiTypography-root, &.Mui-disabled': {
          color: theme.palette.gray[600],
        },
      }}
      {...props}
    >
      <div>{props.children}</div>
    </Link>
  )
}
