import React, { useCallback } from 'react'
import { Stack, StackProps as MuiStackProps } from '@mui/material'
import MenuItemButton, { MenuItemButtonProps } from 'src/components/MenuItemButton/MenuItemButton'

export type MenuItemButtonListProps = {
  /** The root element of this component uses the StackProps from Material UI  */
  StackProps?: MuiStackProps
  list?: (MenuItemButtonProps & { id: string; label?: string })[]
  selectedId?: string
  onMenuItemClick?: (id: string) => void
}

export default function MenuItemButtonList({
  StackProps,
  list = [],
  selectedId,
  onMenuItemClick,
}: MenuItemButtonListProps) {
  const handleMenuItemClick = useCallback(
    (id: string) => () => {
      if (onMenuItemClick) {
        onMenuItemClick(id)
      }
    },
    [onMenuItemClick]
  )

  return (
    <Stack direction="column" {...StackProps}>
      {list.map(({ id, selected, disabled, startIcon, label, onClick, ...restItemProps }) => (
        <MenuItemButton
          key={id || label}
          selected={selected || selectedId === id}
          disabled={disabled}
          startIcon={startIcon}
          onClick={onClick || handleMenuItemClick(id)}
          {...restItemProps}
        >
          {label}
        </MenuItemButton>
      ))}
    </Stack>
  )
}
