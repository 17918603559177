import { createTheme } from '@mui/material/styles'
/* bebas-neue-regular - latin */
import BebasNeueEot from 'src/assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.eot'
import BebasNeueWoff2 from 'src/assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.woff2'
import BebasNeueWoff from 'src/assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.woff'
import BebasNeueTtf from 'src/assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.ttf'
/* lexend-deca-regular - latin */
import LexendDecaEot from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-regular.eot'
import LexendDecaWoff2 from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-regular.woff2'
import LexendDecaWoff from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-regular.woff'
import LexendDecaTtf from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-regular.ttf'
/* lexend-deca-500 - latin */
import LexendDeca500Eot from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-500.eot'
import LexendDeca500Woff2 from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-500.woff2'
import LexendDeca500Woff from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-500.woff'
import LexendDeca500Ttf from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-500.ttf'
/* lexend-deca-600 - latin */
import LexendDeca600Eot from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-600.eot'
import LexendDeca600Woff2 from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-600.woff2'
import LexendDeca600Woff from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-600.woff'
import LexendDeca600Ttf from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-600.ttf'
/* lexend-deca-700 - latin */
import LexendDeca700Eot from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-700.eot'
import LexendDeca700Woff2 from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-700.woff2'
import LexendDeca700Woff from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-700.woff'
import LexendDeca700Ttf from 'src/assets/fonts/lexend-deca-v17-latin/lexend-deca-v17-latin-700.ttf'

const fontFaces = `
  /* bebas-neue-regular - latin */
  @font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: url(${BebasNeueEot}); /* IE9 Compat Modes */
    src: local(''),
      url(${BebasNeueEot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
      url(${BebasNeueWoff2}) format('woff2'), /* Super Modern Browsers */
      url(${BebasNeueWoff}) format('woff'), /* Modern Browsers */
      url(${BebasNeueTtf}) format('truetype'); /* Safari, Android, iOS */
  }
  /* lexend-deca-regular - latin */
  @font-face {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 400;
    src: url(${LexendDecaEot}); /* IE9 Compat Modes */
    src: local(''),
       url(${LexendDecaEot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(${LexendDecaWoff2}) format('woff2'), /* Super Modern Browsers */
       url(${LexendDecaWoff}) format('woff'), /* Modern Browsers */
       url(${LexendDecaTtf}) format('truetype'); /* Safari, Android, iOS */
  }
  /* lexend-deca-500 - latin */
  @font-face {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 500;
    src: url(${LexendDeca500Eot}); /* IE9 Compat Modes */
    src: local(''),
       url(${LexendDeca500Eot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(${LexendDeca500Woff2}) format('woff2'), /* Super Modern Browsers */
       url(${LexendDeca500Woff}) format('woff'), /* Modern Browsers */
       url(${LexendDeca500Ttf}) format('truetype'); /* Safari, Android, iOS */
  }
  /* lexend-deca-600 - latin */
  @font-face {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 600;
    src: url(${LexendDeca600Eot}); /* IE9 Compat Modes */
    src: local(''),
       url(${LexendDeca600Eot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(${LexendDeca600Woff2}) format('woff2'), /* Super Modern Browsers */
       url(${LexendDeca600Woff}) format('woff'), /* Modern Browsers */
       url(${LexendDeca600Ttf}) format('truetype'); /* Safari, Android, iOS */
  }
  /* lexend-deca-700 - latin */
  @font-face {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 700;
    src: url(${LexendDeca700Eot}); /* IE9 Compat Modes */
    src: local(''),
       url(${LexendDeca700Eot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(${LexendDeca700Woff2}) format('woff2'), /* Super Modern Browsers */
       url(${LexendDeca700Woff}) format('woff'), /* Modern Browsers */
       url(${LexendDeca700Ttf}) format('truetype'); /* Safari, Android, iOS */
  }
`

const variantMapping = {
  h7: 'h6',
  labelBig: 'label',
  labelSmall: 'label',
  bodyBig: 'div',
  bodyMedium: 'div',
  bodySmall: 'div',
  paragraphBig: 'p',
  paragraphMedium: 'p',
  paragraphSmall: 'p',
  buttonMedium: 'span',
  buttonMassive: 'span',
  uiElement: 'span',
  description: 'div',
}

const BebasNeue = {
  style: {
    fontFamily: ['"Bebas Neue"', 'Roboto', 'sans-serif'].join(','),
  },
}

const LexendDeca = {
  style: {
    fontFamily: ['"Lexend Deca"', 'Roboto', 'sans-serif'].join(','),
  },
}

const defaultTheme = createTheme()

const typography = {
  fontFamily: BebasNeue.style.fontFamily,
  h1: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '96px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  h2: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '64px',
    lineHeight: '70px',
    fontWeight: '400',
  },
  h3: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '48px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  h4: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '40px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  h5: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '36px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  h6: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '28px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  h7: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '20px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  labelBig: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '24px',
    lineHeight: '130%',
    fontWeight: '700',
  },
  labelMedium: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '18px',
    lineHeight: '130%',
    fontWeight: '700',
  },
  labelSmall: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '16px',
    lineHeight: '130%',
    fontWeight: '700',
  },
  bodyBig: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '18px',
    lineHeight: '130%',
    fontWeight: '400',
  },
  bodyMedium: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '15px',
    lineHeight: '130%',
    fontWeight: '400',
  },
  bodySmall: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '12px',
    lineHeight: '130%',
    fontWeight: '400',
  },
  paragraphBig: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '18px',
    lineHeight: '130%',
    fontWeight: '600',
  },
  paragraphMedium: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '15px',
    lineHeight: '130%',
    fontWeight: '600',
  },
  paragraphSmall: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '12px',
    lineHeight: '130%',
    fontWeight: '600',
  },
  buttonMedium: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '18px',
    lineHeight: '95%',
    fontWeight: '400',
    letterSpacing: '0.035em',
  },
  buttonMassive: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '32px',
    lineHeight: '100%',
    fontWeight: '400',
    letterSpacing: '0.035em',
  },
  buttonBig: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '24px',
    lineHeight: '100%',
    fontWeight: 400,
    letterSpacing: '0.035em',
  },
  uiElement: {
    fontFamily: BebasNeue.style.fontFamily,
    fontSize: '14px',
    lineHeight: '110%',
    fontWeight: '400',
  },
  description: {
    fontFamily: LexendDeca.style.fontFamily,
    fontSize: '10px',
    lineHeight: '100%',
    fontWeight: '500',
  },
}

const getTypography = (theme = defaultTheme) => {
  return {
    ...typography,
    bodyBig: {
      ...typography.bodyBig,
      [theme.breakpoints.down('md')]: {
        ...typography.bodySmall,
      },
    },
    labelBig: {
      ...typography.labelBig,
      [theme.breakpoints.down('sm')]: {
        ...typography.labelSmall,
      },
    },
    paragraphBig: {
      ...typography.paragraphBig,
      [theme.breakpoints.down('sm')]: {
        ...typography.paragraphMedium,
      },
    },
    buttonMassive: {
      ...typography.buttonMassive,
      [theme.breakpoints.down('sm')]: {
        ...typography.buttonBig,
      },
    },
  }
}

const typographySettings = {
  typography,
  getTypography,
  variantMapping,
  fontFaces,
}

export default typographySettings
