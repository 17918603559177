import { takeEvery, delay } from 'redux-saga/effects';
import { getFlexiStakingContract,getVestedStakingBaseContract,getWethContract, getChallengeCoinContract } from "../utils/ethersUtil";
import { parseMetamaskError } from "src/utils/errorParser";
import { showErrorToast } from "../components/Toast/Toasts";
import { put, call, all, select } from 'redux-saga/effects';
import SignerManager from 'src/utils/signerManager';
import { StakingType, TokenType } from 'src/helpers/types';
import { internalUpdateBalanceWorker } from './apiSagas';
import { ethers } from 'ethers';
import { apiStakingSlice, Tags } from 'src/api/stakingApi';
import { compareStrings } from 'src/utils/stringUtils';
import { toToken,formatTokenToString } from 'src/utils/tokenUtil';

export default function* stakingSagas() {
    yield takeEvery("STAKE", stakeWorker);
    yield takeEvery("UNSTAKE", unstakeWorker);
    yield takeEvery("CLAIM_REWARD", claimRewardWorker);
}



function* stakeWorker(action) {
    try {
        const signer = SignerManager.signer;
        if (!signer) {
            throw new Error('No signer found');
        }    

        const walletAccount = yield signer.getAddress();
        const account = yield select(state => state.userReducer.userInfo.username);
        console.log("walletAccount:" + walletAccount);
        if(compareStrings(walletAccount,account) !== 0){
             showErrorToast('Incorrect account', "Please switch account in your wallet to the same as you have logged in with");
             return;
        }

        const amount = action.amount;
        const stakingType = action.stakeType; 
        let amountInWei = toToken(amount.toString());
        console.log("amountInWei:" + amountInWei);

        yield put({ type: "SHOW_WAIT_DIALOG", title: `Getting ready to stake ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}`, subtitle: 'Checking balance'});

        const [challengeCoin, contract] =  yield all(
            [
                call(getChallengeCoinContract,signer),
                stakingType == StakingType.FLEXI ? call(getFlexiStakingContract, signer) : call(getVestedStakingBaseContract, signer)
            ]
        );        
       
        const balance = yield call(challengeCoin.balanceOf,walletAccount);
        const balanceBN = ethers.BigNumber.from(balance);
        console.log("balance:" + balanceBN);
        const newBalance = balanceBN.sub(amountInWei);              
       
        if (balanceBN.lt(amountInWei)) {
            yield put({ type: "UPDATE_WAIT_DIALOG", title:'Staking failed',  subtitle: 'You do not have enough tokens', status: 'error', timeout: 3000 });
            return;
        }
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Checking allowance' });
        const allowance = yield call(challengeCoin.allowance, walletAccount, contract.address);
        const allowanceBN = ethers.BigNumber.from(allowance);

        if (allowanceBN.lt(amountInWei)) {
            yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Fee is bigger than allowance, please approve allowance in Wallet' });
            const tx = yield call(challengeCoin.approve, contract.address, amountInWei);
            yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
            const txdone = yield tx.wait();
        }
        yield put({ type: "UPDATE_WAIT_DIALOG",  title:'Staking', subtitle: `Please sign transaction to stake ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}` });
        
        const tx2 = yield call(contract.stake, amountInWei);
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
        const tx2done = yield tx2.wait();
        yield put({ type: "UPDATE_WAIT_DIALOG", title:'Staking completed',  subtitle: `You have staked ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}`, status: 'success', timeout: 3000 });

        yield internalUpdateBalanceWorker(newBalance, TokenType.CT); //update balance in store

        yield delay(2000); //Make sure transaction is mined before updating balance
         // Then invalidate RTK Query cache
         stakingType===StakingType.FLEXI 
            ? 
            yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainFlexiStaking, Tags.StakingSummary])) 
                : 
            yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainVestedStaking, Tags.StakingSummary]));
    }
    catch (e) {
        let erorMessage = parseMetamaskError(e);
        console.log("stakeWorker failed," + erorMessage)
        if(erorMessage.includes("execution reverted") ){
            erorMessage = `${action.stakeType} Staking is not available at the moment`;
        }
        yield put({ type: "SHOW_WAIT_DIALOG", showWait: true, title:'Stake failed', subtitle: erorMessage, status: 'error', timeout: 0 });;
              
    }   
    
}


function* unstakeWorker(action) {
    try {
        const signer = SignerManager.signer;
        if (!signer) {
            throw new Error('No signer found');
        }    

        const walletAccount = yield signer.getAddress();
        const account = yield select(state => state.userReducer.userInfo.username);
        console.log("walletAccount:" + walletAccount);
        if(compareStrings(walletAccount,account) !== 0){
             showErrorToast('Incorrect account', "Please switch account in your wallet to the same as you have logged in with");
             return;
        }
        const amount = action.amount;
        const stakingType = action.stakeType;
        let fee = action.fee;
        fee = Number(fee);
        let amountInWei = toToken(amount.toString());
        console.log("amountInWei:" + amountInWei);
        
        yield put({ type: "SHOW_WAIT_DIALOG", title: `Getting ready to unstake ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}`, subtitle: 'Checking balance'});
       
        const [challengeCoin, contract] =  yield all(
            [
                call(getChallengeCoinContract,signer),
                stakingType == StakingType.FLEXI ? call(getFlexiStakingContract, signer) : call(getVestedStakingBaseContract, signer)
            ]
        );    
       
        const balance = yield call(challengeCoin.balanceOf,walletAccount);
        const balanceBN = ethers.BigNumber.from(balance);
        console.log("balance:" + balanceBN);
        const returnedAmount = amountInWei.sub(amountInWei.mul(fee).div(100));
        const newBalance = balanceBN.add(returnedAmount); //add fee and amount to balance     
        const formatedReturnedAmount =  formatTokenToString(returnedAmount, TokenType.CT,6,false,true,true);        

      
        yield put({ type: "UPDATE_WAIT_DIALOG", title:'Unstaking', subtitle: `Please sign transaction to unstake ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}` });
        
        const tx2 = yield call(contract.unstake, amountInWei);
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
        const tx2done = yield tx2.wait();
        yield put({ type: "UPDATE_WAIT_DIALOG", title:'Unstaking completed',  subtitle: `You have recieved ${formatedReturnedAmount}`, status: 'success', timeout: 3000 });

        yield internalUpdateBalanceWorker(newBalance, TokenType.CT); //update balance in store
        
        yield delay(2000); //Make sure transaction is mined before updating balance
        // Then invalidate RTK Query cache
        stakingType===StakingType.FLEXI 
        ? 
        yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainFlexiStaking, Tags.StakingSummary])) 
            : 
        yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainVestedStaking, Tags.StakingSummary]));
    }
    catch (e) {
        const erorMessage = parseMetamaskError(e);
        console.log("stakeWorker failed," + erorMessage)
        yield put({ type: "SHOW_WAIT_DIALOG", showWait: true, title:'Unstake failed', subtitle: erorMessage, status: 'error', timeout: 0 });;
              
    }   
    
}

function* claimRewardWorker(action){
    try {
        const signer = SignerManager.signer;
        if (!signer) {
            throw new Error('No signer found');
        }    
        
        const walletAccount = yield signer.getAddress();
        const account = yield select(state => state.userReducer.userInfo.username);
        console.log("walletAccount:" + walletAccount);
        if(compareStrings(walletAccount,account) !== 0){
             showErrorToast('Incorrect account', "Please switch account in your wallet to the same as you have logged in with");
             return;
        }

        const stakingType = action.stakeType;
        const amount = action.amount;
        
        yield put({ type: "SHOW_WAIT_DIALOG", title: 'Getting ready to claim', subtitle: 'Checking reward'});
       
        const [cc, contract] =  yield all(
            [
                call(getChallengeCoinContract,signer),
                stakingType == StakingType.FLEXI ? call(getFlexiStakingContract, signer) : call(getVestedStakingBaseContract, signer)
            ]
        );    

        const ccBefore = yield call(cc.balanceOf,walletAccount);
        yield put({ type: "UPDATE_WAIT_DIALOG",  subtitle: `Please sign transaction to claim reward` });        
        const reward = yield call(contract.claimReward);
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
        const rewardDone = yield reward.wait();
        const ccAfter = yield call(cc.balanceOf,walletAccount);

        let rewardAmount;
        if(ccAfter.eq(ccBefore)){
            //Balance not yes updated
            rewardAmount = amount;
            console.log("Balance not updated:" + rewardAmount);
        }
        else{
            //Balance updated           
            rewardAmount = ccAfter.sub(ccBefore);
            console.log("Balance updated!:" + rewardAmount);
        }
         
        const formatedReturnedAmount =  formatTokenToString(rewardAmount, TokenType.CT);        
        
        yield put({ type: "UPDATE_WAIT_DIALOG", title:'Reward claimed',  subtitle: `You have recieved ${formatedReturnedAmount} ${TokenType.CT}`, status: 'success', timeout: 3000 });

        yield internalUpdateBalanceWorker(ccAfter, TokenType.CT); //update balance in store

        yield delay(2000); //Make sure transaction is mined before updating balance
        // Then invalidate RTK Query cache
        stakingType===StakingType.FLEXI 
        ? 
        yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainFlexiStaking, Tags.StakingSummary])) 
            : 
        yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainVestedStaking, Tags.StakingSummary]));
    }
    catch (e) {
        const erorMessage = parseMetamaskError(e);
        console.log("stakeWorker failed," + erorMessage)
        yield put({ type: "SHOW_WAIT_DIALOG", showWait: true, title:'Claim reward failed', subtitle: erorMessage, status: 'error', timeout: 0 });;
              
    }

}