import * as React from 'react'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { FormHelperText, TextFieldProps as MuiTextFieldProps } from '@mui/material'

export type TextFieldProps = MuiTextFieldProps &
  InputBaseProps & {
    label?: string
  }

export default function TextField({
  id,
  name,
  label,
  fullWidth,
  error,
  helperText,
  InputLabelProps,
  InputProps,
  FormHelperTextProps,
  ...restInputBaseProps
}: TextFieldProps) {
  const fieldId = id || name || ''

  return (
    <FormControl error={error} variant="standard" fullWidth={fullWidth}>
      {label && (
        <InputLabel
          {...InputLabelProps}
          error={!!error}
          shrink
          htmlFor={fieldId}
          color={helperText ? 'warning' : undefined}
        >
          {label}
        </InputLabel>
      )}
      <InputBase
        id={fieldId}
        name={name}
        fullWidth={fullWidth}
        error={error}
        {...InputProps}
        color={helperText ? 'warning' : undefined}
        {...restInputBaseProps}
      />
      <FormHelperText
        {...FormHelperTextProps}
        error={!!error}
        id={fieldId}
        color={helperText ? 'warning' : undefined}
      >
        {helperText || ' '}
      </FormHelperText>
    </FormControl>
  )
}
