import { Tooltip, TooltipProps as MuiTooltipProps, tooltipClasses, styled } from '@mui/material'
import React from 'react'
import Typography from '../Typography/Typography'

type ExtendedTooltipProps = {
  maxWidth?: number
  px?: string
  py?: string
}

type TooltipStyledProps = {
  /** [The root element of this component uses the TooltipProps from Material UI](https://mui.com/material-ui/react-tooltip/) */
  TooltipProps?: Omit<MuiTooltipProps, 'title' | 'children'> & ExtendedTooltipProps
  title?: string
  content: React.ReactNode
  children: React.ReactElement
}

function TooltipStyled({ title, content, children, TooltipProps }: TooltipStyledProps) {
  const toolTipContent = (
    <>
      {title && (
        <Typography variant="paragraphSmall" color="white" mb="4px">
          {title}
        </Typography>
      )}
      <Typography variant="bodySmall" color="gray.100">
        {content}
      </Typography>
    </>
  )

  return (
    <CustomTooltip title={toolTipContent} {...TooltipProps}>
      {children}
    </CustomTooltip>
  )
}

const CustomTooltip = styled(({ className, ...props }: MuiTooltipProps & ExtendedTooltipProps) => (
  <Tooltip classes={{ popper: className }} {...props} />
))(({ theme, maxWidth, px, py }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.gray[600],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.gray[600],
    borderRadius: 0,
    padding: `${py || '8px'} ${px || '12px'}`,
    maxWidth: maxWidth || 320,
    filter:
      'drop-shadow(0px 4px 12px rgba(7, 8, 12, 0.25)) drop-shadow(0px 12px 24px rgba(7, 8, 12, 0.25))',
  },
}))

export default TooltipStyled
