import React from 'react'
import { Stack, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type CommentProps = {
  children: React.ReactNode
  rootStackProps?: StackProps
  typographySlashProps?: TypographyProps
  typographyProps?: TypographyProps
}

export default function Comment({
  children,
  rootStackProps,
  typographySlashProps,
  typographyProps,
}: CommentProps) {
  return (
    <Stack direction="row" spacing="8px" alignItems="center" {...rootStackProps}>
      <Typography variant="uiElement" color="gray.700" {...typographySlashProps}>
        {'// '}
      </Typography>
      <Typography variant="uiElement" color="gray.500" {...typographyProps}>
        {children}
      </Typography>
    </Stack>
  )
}
