import React from 'react'
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  AvatarProps,
} from '@mui/material'
import Typography from '../Typography/Typography'
import PlayerStatus from '../PlayerStatus/PlayerStatus'
import TrophyNumber from '../TrophyNumber/TrophyNumber'
import GradientBorder from '../GradientBorder/GradientBorder'
import GradientLeaderBoard from '../GradientLeaderBoard/GradientLeaderBoard'
import GradientBox from '../GradientBox/GradientBox'

// Typing of the table Data
type TableDataT = {
  headRow: { cell: { value: string; color?: string; width?: string }[] }
  bodyRow: {
    cell: { value: string | (() => React.ReactNode); src?: string; color?: string }[] | null
    noPlayers?: boolean
  }[]
}

type TableLeaderBoardProps = TableContainerProps & {
  data: TableDataT
  withTrophy?: boolean // displays trophies in the table
  rowCount?: number // limits the number of visible lines
  trophyLimit?: number // limits the number of trophies from 3 to 1
  rootAvatarProps?: AvatarProps
}

function TableLeaderBoard({
  data,
  withTrophy,
  rowCount = 8,
  trophyLimit,
  rootAvatarProps = {},
  ...restProps
}: TableLeaderBoardProps) {
  function getCellCustomWidth(index: number) {
    if (index) {
      return { sx: { width: `${data.headRow.cell[index].width} !important` || 'auto' } }
    }
    return {}
  }

  const showTruncate = data.bodyRow.length < rowCount ? false : true

  return (
    <TableContainer
      component="div"
      sx={{
        display: 'block',
        width: 'auto',
        overflow: 'visible',
        position: 'relative',
        right: '-2px',
      }}
      {...restProps}
    >
      <Table
        sx={{ width: 'calc(100% - 4px)', display: 'block', height: '30px', mb: '2px' }}
        aria-label="customized table"
      >
        <TableHead sx={{ width: '100%', display: 'block' }}>
          <StyledTableRow>
            <StyledTableCell>#</StyledTableCell>
            {data?.headRow?.cell?.map((th, indexTh) => (
              <StyledTableCell key={indexTh} {...getCellCustomWidth(indexTh || 0)}>
                <Typography variant="uiElement">{th.value}</Typography>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
      </Table>
      <Box
        overflow="auto"
        width={`calc(100% - ${showTruncate ? 0 : 4}px)`}
        height={getTableHeight(rowCount)}
        sx={{ transition: 'max-height 0.4s ease-in-out' }}
      >
        <Table aria-label="customized table">
          <TableBody>
            {data.bodyRow.map((row, index) => (
              <StyledTableRow key={index}>
                <>
                  <StyledTableCell>
                    {getPlaceType(index + 1, trophyLimit, withTrophy)}
                  </StyledTableCell>
                  {row?.cell?.map((cell, indexCell) => (
                    <StyledTableCell key={indexCell} {...getCellCustomWidth(indexCell || 0)}>
                      {cell.src && (
                        <PlayerStatus
                          src={cell.src}
                          text={typeof cell.value !== 'function' ? cell.value : ''}
                          AvatarProps={rootAvatarProps}
                        />
                      )}
                      {!cell.src && (
                        <>
                          {typeof cell.value === 'function'
                            ? cell.value()
                            : cell.value && (
                                <Typography
                                  variant="bodySmall"
                                  {...textTypographyColor(cell.color)}
                                >
                                  {cell.value}
                                </Typography>
                              )}
                        </>
                      )}
                    </StyledTableCell>
                  ))}
                </>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {showTruncate && (
          <GradientBox
            width="calc(100% - 4px)"
            position="absolute"
            bottom={0}
            zIndex={1}
            height={tableStyleParams.common.truncateHeight}
          />
        )}
      </Box>
    </TableContainer>
  )
}

// ===
// === Stylization table START
const tableStyleParams = {
  common: {
    rowGap: 2,
    truncateHeight: 13,
    td: {
      height: 48,
      firstWith: '37px',
      secondWith: 'auto',
      thirdWidth: '50px',
      fifthWidth: '77px',
      baseWith: '70px',
    },
  },
}

const commonCellStyle = {
  width: tableStyleParams.common.td.baseWith,
  '&:first-of-type': {
    width: tableStyleParams.common.td.firstWith,
    display: 'flex',
    alignItems: 'center',
  },
  '&:nth-of-type(2)': {
    padding: '0 6px 0 8px',
    width: tableStyleParams.common.td.secondWith,
    flexGrow: 1,
  },
  '&:nth-of-type(3)': {
    width: tableStyleParams.common.td.thirdWidth,
  },
  '&:nth-of-type(5)': {
    width: tableStyleParams.common.td.fifthWidth,
  },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0 8px',
  borderBottom: 'none',
  zIndex: 1,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '3px',
  },
  '&:first-of-type': {
    position: 'initial',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
    },
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.gray[700],
    color: theme.palette.gray[500],
    height: '30px',
    border: 'none',
    ...commonCellStyle,
  },
  [`&.${tableCellClasses.body}`]: {
    height: `${tableStyleParams.common.td.height}px`,
    color: theme.palette.gray[25],
    ...commonCellStyle,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Common styles for <tr>
  backgroundColor: theme.palette.gray[900],
  border: 'none',
  borderTopWidth: '2px',
  position: 'relative',
  display: 'flex',
  marginBottom: tableStyleParams.common.rowGap,
  transition: 'opacity ease-in-out 0.3s',
  '& td:first-of-type:before': {
    content: '" "',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: '#4B5873',
    opacity: 0,
    transition: 'opacity ease-in-out 0.25s',
    zIndex: 2,
  },
  '&:hover': {
    '& td:first-of-type:before': {
      opacity: 1,
    },
  },
  '&:first-of-type': {
    borderTopWidth: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    height: `${tableStyleParams.common.td.height}px`,
    color: theme.palette.gray[25],
    ...commonCellStyle,
  },
}))
// === Stylization table END
// ===

const textTypographyColor = (val: string | undefined) => {
  let out = {}
  if (val) {
    out = { color: val }
  }
  return out
}

const getPlaceType = (val: number, trophyLimit: number | undefined, withTrophy?: boolean) => {
  const limit = trophyLimit ? trophyLimit : 3
  let showTrophy = false
  if (val <= limit && withTrophy) {
    showTrophy = true
  }
  return (
    <>
      <Box position="relative" margin={showTrophy ? '0 auto' : '0'} zIndex={1}>
        {showTrophy ? <TrophyNumber place={val} /> : val}
      </Box>
      {showTrophy && (
        <GradientBorder
          place={val}
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          display="flex"
          alignItems="stretch"
          justifyContent="stretch"
          childrenBoxProps={{ width: '100%', height: '100%' }}
        >
          <GradientLeaderBoard
            place={val}
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
          />
        </GradientBorder>
      )}
    </>
  )
}

// Getting the height of the table for smooth altitude changes
const getTableHeight = (rowCount: number) => {
  const height = tableStyleParams.common.td.height
  const gap = tableStyleParams.common.rowGap
  const truncate = tableStyleParams.common.truncateHeight
  // Calculation of the height of the table
  return (height + gap) * rowCount + truncate
}

export default TableLeaderBoard
