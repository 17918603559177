import * as Yup from 'yup'

const messages = {
  REQUIRED_NICKNAME: 'The nickname is required',
  NICKNAME_MIN_CHARS: 'The nickname is too short - must be 3 chars minimum',
  NICKNAME_MAX_CHARS: 'The nickname is too long - must be 20 chars maximum',
  NICKNAME_ALLOWED_CHARS: 'Username only allows a-z and 0-9',
  REQUIRED_PASSWORD: 'The password is required',
  PASSWORD_MIN_CHARS: 'The password is too short - must be 6 chars minimum.',
  PASSWORD_MAX_CHARS: 'The password is too long - must be 100 chars maximum',
  PASSWORD_MIN_NUM: 'The password requires a number',
  PASSWORD_MIN_LOWERCASE: 'The password requires a lowercase letter',
  PASSWORD_MIN_UPPERCASE: 'The password requires an uppercase letter',
  REQUIRED_REPEAT_PASSWORD: 'Please confirm the password',
  PASSWORDS_MUST_MATCH: 'Passwords must match',
  REQUIRED_AMOUNT: 'The amount is required',
  AMOUNT_MAX_VALUE: 'The amount is too high',
  AMOUNT_DECIMALS_ERROR: 'The amount must have 6 decimal places maximum',
  GREATER_THAN_ZERO: 'The amount must be greater than 0',
}

export const username = ({
  requiredMsg = messages.REQUIRED_NICKNAME,
  minMsg = messages.NICKNAME_MIN_CHARS,
  maxMsg = messages.NICKNAME_MAX_CHARS,
  allowedMsg = messages.NICKNAME_ALLOWED_CHARS
} = {}) => 
  Yup.string()
    .required(requiredMsg)
    .min(3, minMsg)
    .max(20,maxMsg)
    .matches(/^[a-zA-Z0-9]*$/, allowedMsg)    

export const requiredPassword = ({
  requiredMsg = messages.REQUIRED_PASSWORD,
  minCharsMsg = messages.PASSWORD_MIN_CHARS,
  maxCharsMsg = messages.PASSWORD_MAX_CHARS,
} = {}) =>
  Yup.string()
    .required(requiredMsg)
    .min(6, minCharsMsg)
    .max(100, maxCharsMsg)

export const repeatPassword = ({
  requiredMsg = messages.REQUIRED_REPEAT_PASSWORD,
  refKey = 'newPassword',
} = {}) =>
  Yup.string()
    .required(requiredMsg)
    .oneOf([Yup.ref(refKey)], messages.PASSWORDS_MUST_MATCH)

export const notRequiredPassword = ({
  minCharsMsg = messages.PASSWORD_MIN_CHARS,
  minNumMsg = messages.PASSWORD_MIN_NUM,
  minLowercaseMsg = messages.PASSWORD_MIN_LOWERCASE,
  minUppercaseMsg = messages.PASSWORD_MIN_UPPERCASE,
} = {}) =>
  Yup.string()
    .min(8, minCharsMsg)
    .matches(/[0-9]/, minNumMsg)
    .matches(/[a-z]/, minLowercaseMsg)
    .matches(/[A-Z]/, minUppercaseMsg)

export const amount = (externalValue: number, {
  requiredMsg = messages.REQUIRED_AMOUNT,
  maxMsg = messages.AMOUNT_MAX_VALUE,
  decimalMsg = messages.AMOUNT_DECIMALS_ERROR,
  greaterThanZeroMsg = messages.GREATER_THAN_ZERO,
  maxDecimalPlaces = 6,
  maxValue = 99999999.999999, // Adjust the maximum value as needed
} = {}) =>
  Yup.number()
    .required(requiredMsg)
    .max(maxValue, maxMsg)
    .moreThan(0, greaterThanZeroMsg)
    .test(
      'max-decimal-places',
      decimalMsg,
      number => Number.isFinite(number) && (!Number.isInteger(number) ? String(number).split('.')[1]?.length <= maxDecimalPlaces : true)
    )
    .test(
      'is-less-than-external',
      `Value must be less than or equal to ${externalValue}`,
      value => value <= externalValue
    );
