import React from 'react'
import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableContainerProps,
  TableRow,
  useTheme,
} from '@mui/material'
import Typography from '../Typography/Typography'
import PlayerStatus from '../PlayerStatus/PlayerStatus'
import SvgIcon from '../SvgIcon/SvgIcon'
import Link from '../Link/Link'
import CountTotalWithLabel from '../CountTotalWithLabel/CountTotalWithLabel'
import { TournamentType } from 'src/helpers/types'

// Custom class helpers (to change classes in one place)
enum CustomClasses {
  AfterRow = 'styled-row',
  AfterCell = 'styled-cell',
  PaddingNone = 'padding-none',
}

// Typing of the table Data
type TableDataT = {
  headRow: { cell: { value: string; color?: string }[] }
  bodyRow: {
    cell: { value: string; src?: string; color?: string }[] | null
    userId?: string
  }[]
}

type TableTournamentProps = TableContainerProps & {
  data: TableDataT
  rowCount?: number
  currentUserId: string
  challenge: TournamentType
}

function TableTournament({
  data,
  rowCount = 20,
  currentUserId,
  challenge,
  ...restProps
}: TableTournamentProps) {
  const theme = useTheme()
  const tableDesktopHeight = tableStyleParams.td.height * rowCount
  const tableTabletHeight = tableStyleParams.td.height * 17 + 12

  return (
    <TableContainer
      component="div"
      sx={{ display: 'inline-block', width: '100%', overflow: 'visible', maxWidth: '634px' }}
      {...restProps}
    >
      <CountTotalWithLabel
        mb={{ xs: '0', md834: '-18px', md: '-3px' }}
        count={challenge.numberOfParticipants}
        label="Players"
        total={challenge.maxParticipans}
      />
      <Stack direction="row" alignItems="flex-end" mb="10px">
        <Link
          size="small"
          href="#"
          onClick={() => scrollToTargetUser(currentUserId)}
          sx={{ ml: 'auto' }}
          color="gray.25"
          startIcon={<SvgIcon name="user-line" />}
        >
          Find me
        </Link>
      </Stack>
      <Stack direction="row" sx={{ width: 'auto' }}>
        <StyledHeadCell className={`${CustomClasses.AfterCell}`}>#</StyledHeadCell>
        {data?.headRow?.cell?.map((th, indexHead) => (
          <StyledHeadCell key={indexHead} className={`${CustomClasses.AfterCell}`}>
            <Typography variant="uiElement">{th.value}</Typography>
          </StyledHeadCell>
        ))}
      </Stack>
      <Box
        overflow="auto"
        id={tableWrapperId}
        maxHeight={`${tableDesktopHeight}px`}
        mr="-4px"
        sx={{
          [theme.breakpoints.down('md')]: {
            maxHeight: tableTabletHeight,
          },
        }}
      >
        <Table sx={{ maxWidth: { xs: '100%', sm2: '1200px' } }} aria-label="customized table">
          <TableBody>
            {data.bodyRow.map((row, index) => (
              <StyledTableRow
                id={`${userIdPrefix}${row.userId}`}
                className={`${CustomClasses.AfterRow}`}
                key={index}
              >
                <>
                  <StyledTableCell className={`${CustomClasses.AfterCell}`}>
                    {index + 1}
                  </StyledTableCell>
                  {!row.cell && (
                    <StyledTableCell
                      className={CustomClasses.PaddingNone}
                      colSpan={data?.headRow?.cell?.length}
                    >
                      <PlayerStatus variant="searching" text="Waiting for Player..." />
                    </StyledTableCell>
                  )}
                  {row?.cell?.map((cell, indexCell) => (
                    <StyledTableCell key={indexCell} className={`${CustomClasses.AfterCell}`}>
                      {cell.src && <PlayerStatus src={cell.src} text={cell.value} />}
                      {!cell.src && (
                        <Typography variant="bodySmall" {...textTypographyColor(cell.color)}>
                          {cell.value ? cell.value : '-'}
                        </Typography>
                      )}
                    </StyledTableCell>
                  ))}
                </>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  )
}

// ===
// === Stylization table START
const tableStyleParams = {
  td: {
    height: 34,
    firstWith: '32px',
    secondWith: 'auto',
    thirdWidth: '137px',
  },
  tdTablet: {
    firstWith: '32px',
    secondWith: 'auto',
    thirdWidth: '100px',
  },
  tdMobile: {
    firstWith: '32px',
    secondWith: 'auto',
    thirdWidth: '90px',
  },
}

const cellStyleCommonObj = {
  [`&.${CustomClasses.AfterCell}`]: {
    '&:first-of-type': { width: tableStyleParams.td.firstWith },
    '&:nth-of-type(2)': { width: tableStyleParams.td.secondWith },
    '&:nth-of-type(3)': { width: tableStyleParams.td.thirdWidth },
  },
}

const cellStyleCommonTabletObj = {
  [`&.${CustomClasses.AfterCell}`]: {
    '&:first-of-type': { width: tableStyleParams.tdTablet.firstWith },
    '&:nth-of-type(2)': { width: tableStyleParams.tdTablet.secondWith },
    '&:nth-of-type(3)': { width: tableStyleParams.tdTablet.thirdWidth },
  },
}

const cellStyleCommonMobileObj = {
  [`&.${CustomClasses.AfterCell}`]: {
    '&:first-of-type': { width: tableStyleParams.tdMobile.firstWith },
    '&:nth-of-type(2)': { width: tableStyleParams.tdMobile.secondWith },
    '&:nth-of-type(3)': { width: tableStyleParams.tdMobile.thirdWidth },
  },
}

const StyledHeadCell = styled(Box)(({ theme }) => ({
  padding: '2px 0px 0 8px',
  borderBottom: 'none',
  backgroundColor: theme.palette.gray[700],
  color: theme.palette.gray[500],
  height: '30px',
  // flexGrow: 1,
  alignItems: 'center',
  display: 'flex',
  '&:first-of-type': {
    borderRight: `1px solid ${theme.palette.gray[700]}`,
    flexGrow: '0',
  },
  '&:nth-of-type(2)': {
    flexGrow: '1',
  },
  '&:nth-of-type(3)': {
    flexGrow: '0',
  },
  ...cellStyleCommonObj,
  [theme.breakpoints.down('md')]: {
    ...cellStyleCommonTabletObj,
  },
  [theme.breakpoints.down('sm')]: {
    ...cellStyleCommonMobileObj,
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0 0 0 8px',
  borderBottom: 'none',
  [`&.${tableCellClasses.body}`]: {
    height: `${tableStyleParams.td.height}px`,
    color: theme.palette.gray[25],
    borderRight: `1px solid ${theme.palette.gray[700]}`,
    '&:last-of-type': {
      borderRight: `2px solid ${theme.palette.gray[700]}`,
    },
    '&:nth-of-type(2)': { padding: '0 2px' },
    // '&:nth-of-type(3)': { borderLeft: `1px solid ${theme.palette.gray[700]}` },
    ...cellStyleCommonObj,
    [theme.breakpoints.down('md')]: {
      ...cellStyleCommonTabletObj,
    },
    [theme.breakpoints.down('sm')]: {
      ...cellStyleCommonMobileObj,
    },
    [`&.${CustomClasses.PaddingNone}`]: { padding: 0 },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Common styles for <tr>
  backgroundColor: theme.palette.gray[900],
  border: `1px solid ${theme.palette.gray[700]}`,
  borderTopWidth: '2px',
  '&:first-of-type': {
    borderTopWidth: 0,
  },
  '&.active': {
    outline: `1px solid ${theme.palette.primary.main}`,
    outlineOffset: '-1px',
  },
}))
// === Stylization table END
// ===

const textTypographyColor = (val: string | undefined) => {
  let out = {}
  if (val) {
    out = { color: val }
  }
  return out
}

const userIdPrefix = 'user-id-'
const tableWrapperId = 'scrollTableWrapper'
function scrollToTargetUser(currentUserId: string) {
  const scrollWrapper = document.getElementById(tableWrapperId)
  const currentUser = document.getElementById(`${userIdPrefix}${currentUserId}`)

  if (scrollWrapper && currentUser) {
    const targetOffsetTop = currentUser.offsetTop
    const duration = 300

    const startScrollTop = scrollWrapper.scrollTop
    const distance = targetOffsetTop - startScrollTop

    const animateScroll = () => {
      const elapsed = performance.now() - startTime
      const progress = Math.min(elapsed / duration, 1)
      const scrollTo = startScrollTop + distance * progress

      scrollWrapper.scrollTop = scrollTo

      if (elapsed < duration) {
        setTimeout(animateScroll, 0)
      } else {
        currentUser.classList.add('active')

        setTimeout(() => {
          currentUser.classList.remove('active')
        }, 1000)
      }
    }

    const startTime = performance.now()
    animateScroll()
  }
}

export default TableTournament
