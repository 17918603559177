import React, { useMemo } from 'react'
import MuiSvgIcon, { SvgIconProps as MuiSvgIconProps } from '@mui/material/SvgIcon'
import ICONS from 'src/assets/icons/icons'

export interface SvgIconProps extends Omit<MuiSvgIconProps, 'fontSize'> {
  name: keyof typeof ICONS
  fontSize?: MuiSvgIconProps['fontSize'] | 'extraSmall' | 'extraLarge'
}

function SvgIcon({ name, ...restProps }: SvgIconProps) {
  const component = useMemo(() => ICONS[name], [name])

  return <MuiSvgIcon {...restProps} component={component} inheritViewBox />
}

SvgIcon.ICONS = Object.keys(ICONS)

export default SvgIcon
