import React from 'react'
import { useTheme, Avatar, Box, Stack, useMediaQuery } from '@mui/material'
import { RiLockLine, RiGift2Line, RiHandCoinLine } from '@remixicon/react'
import { PNG_ICONS } from 'src/assets/icons/icons'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import MediaBoxWithText from 'src/components/MediaBoxWithText/MediaBoxWithText'
import Button from 'src/components/Button/Button'
import Typography from 'src/components/Typography/Typography'
import LabelWithValue from 'src/components/LabelWithValue/LabelWithValue'
import { TotalValueOrStatus } from 'src/components/StakingTotal/StakingTotal'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import PeriodInfo from 'src/components/PeriodInfo/PeriodInfo'

enum PoolType {
  FLEXI = 'flexi',
  VESTED = 'vested',
}

type StakePoolProps = {
  type: `${PoolType}`
  stakedValue?: string
  unstakeDisabled?: boolean
  onUnstake?: () => void
  rewardValue?: string | number
  token?: string
  onClaim?: () => void
  availableValue?: string | number
  availableToken?: string
  percent?: string
  releasedAt?: string
  unstakeFee: number
  periods: string[]
}

/*const periods = [
  '7 days,  // 7 days from start',
  '1 * 30 days, // 1 month from start',
  '2 * 30 days, // 2 months from start',
  '3 * 30 days, // 3 months from start',
  '6 * 30 days, // 6 months from start',
  '9 * 30 days, // 9 months from start',
  '12 * 30 days, // 12 months from start',
  '15 * 30 days  // 15 months from start',
]*/

export default function StakePool({
  type,
  stakedValue,
  unstakeDisabled,
  onUnstake,
  rewardValue,
  token,
  onClaim,
  availableValue,
  availableToken,
  percent,
  releasedAt,
  unstakeFee,
  periods,
}: StakePoolProps) {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('xsm'))
  const isTabletView = useMediaQuery(theme.breakpoints.up('xsm'))

  return (
    <Box border="1px solid" borderColor="gray.700">
      <Stack
        gap="12px"
        p={{ xs: '8px', md834: '6px 8px 9px 8px' }}
        bgcolor="gray.700"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <MediaBoxWithText
          StackProps={{
            sx: {
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
              [theme.breakpoints.up('xsm')]: {
                gap: '12px',
              },
            },
          }}
          MediaBox={{
            children: (
              <Avatar
                src={PNG_ICONS.coin}
                alt="Coin"
                sx={{
                  width: '18px',
                  height: '18px',
                  [theme.breakpoints.up('xsm')]: {
                    width: '20px',
                    height: '20px',
                  },
                }}
              />
            ),
            sx: {
              width: '44px',
              height: '44px',
              [theme.breakpoints.up('xsm')]: {
                width: '48px',
                height: '48px',
              },
            },
          }}
          TitleTypographyProps={{
            children: stakedValue ?? 'Not Staked',
            color: stakedValue ? 'gray.0' : 'gray.600',
            sx: { [theme.breakpoints.up('xsm')]: { mb: '4px' } },
          }}
          SubTitleTypographyProps={{
            children: 'You Staked',
            variant: 'paragraphSmall',
          }}
        />
        {type === PoolType.VESTED && stakedValue && percent && releasedAt && isMobileView && (
          <PeriodInfo percent={percent} releasedAt={releasedAt} periods={periods} />
        )}
        {(stakedValue || isTabletView) && (
          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            sx={{
              [theme.breakpoints.up('xsm')]: {
                width: 'auto',
                gap: '24px',
              },
            }}
          >
            {type === PoolType.FLEXI && stakedValue && (
              <Typography
                variant="description"
                color="gray.50"
                maxWidth="150px"
                sx={{
                  [theme.breakpoints.up('xsm')]: {
                    maxWidth: '100%',
                  },
                }}
              >
                Available now for a withdrawal fee of {unstakeFee}%
              </Typography>
            )}
            {type === PoolType.VESTED &&
              stakedValue &&
              isMobileView &&
              renderLabelWithValue(availableValue, availableToken)}
            {type === PoolType.VESTED && stakedValue && percent && releasedAt && isTabletView && (
              <PeriodInfo percent={percent} releasedAt={releasedAt} periods={periods} />
            )}
            {type === PoolType.VESTED && stakedValue && availableValue && isTabletView && (
              <Box minWidth="143px">{renderLabelWithValue(availableValue, availableToken)}</Box>
            )}
            {(stakedValue || isTabletView) && (
              <Button
                variant="outlined"
                size="small"
                startIcon={stakedValue ? <SvgIcon name="refund-CC-line" /> : <RiLockLine />}
                disabled={
                  unstakeDisabled !== undefined
                    ? unstakeDisabled
                    : !stakedValue || (type === PoolType.VESTED && !availableValue)
                }
                onClick={onUnstake}
                sx={{
                  minWidth: '107px',
                }}
              >
                Unstake
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        gap="16px"
        p={{ xs: '7px 8px', md834: '8px 8px 10px 17px' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        bgcolor={stakedValue ? 'gray.800' : 'gray.700'}
        sx={{
          background:
            (type === PoolType.FLEXI && stakedValue) || (type === PoolType.VESTED && rewardValue)
              ? 'unset'
              : `repeating-linear-gradient(40deg, transparent, transparent 5px, ${theme.palette.gray[700]} 5px, ${theme.palette.gray[700]} 8px)`,
          [theme.breakpoints.up('xsm')]: {
            p: '9px',
            pl: '17px',
          },
        }}
      >
        <TitleWithDot
          typographyProps={{ variant: 'h7', color: rewardValue ? 'common.white' : 'gray.500' }}
          dotProps={{
            bgcolor: rewardValue ? 'primary.main' : 'gray.500',
            width: '4px',
            height: '4px',
            bottom: { xs: '5px' },
          }}
          rootStackProps={{
            spacing: { xs: '7px' },
            display: 'none',
            sx: {
              [theme.breakpoints.up('xsm')]: { display: 'flex' },
            },
          }}
        >
          your rewards
        </TitleWithDot>
        <Stack
          gap="16px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          flexGrow={1}
          sx={{
            [theme.breakpoints.up('xsm')]: {
              flexGrow: 'unset',
              gap: '39px',
            },
          }}
        >
          <LabelWithValue
            StackProps={{
              gap: '4px',
            }}
            MediaWithTitleProps={{
              StackProps: { gap: '6px' },
              icon: <RiGift2Line size={18} color={theme.palette.gray[300]} />,
              TypographyProps: {
                children: 'Available Reward',
                variant: 'paragraphSmall',
              },
            }}
          >
            <TotalValueOrStatus
              BoxProps={{ ml: '24px' }}
              value={rewardValue}
              token={token}
              status={'No rewards'}
              StatusTypographyProps={{
                variant: 'h7',
              }}
            />
          </LabelWithValue>
          <Button
            variant="contained"
            size="small"
            startIcon={<RiHandCoinLine />}
            disabled={isDisabled(rewardValue)}
            onClick={onClaim}
            sx={{
              minWidth: '106px',
            }}
          >
            Claim
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

const isDisabled = (rewardValue: any) => {
  return rewardValue === undefined || rewardValue === null || rewardValue === '' || rewardValue === 0 || rewardValue === '0';
};

function renderLabelWithValue(value?: number | string, token?: string) {
  return (
    <LabelWithValue
      StackProps={{
        gap: '4px',
      }}
      MediaWithTitleProps={{
        StackProps: { gap: '6px' },
        icon: (
          <SvgIcon
            name="refund-CC-line"
            sx={{ width: '18px', height: '18px', color: 'gray.300' }}
          />
        ),
        TypographyProps: {
          children: 'Available',
          variant: 'paragraphSmall',
        },
      }}
    >
      <TotalValueOrStatus
        BoxProps={{ ml: '24px' }}
        value={value ?? '00.00'}
        token={token}
        CountWithTokenProps={{
          CountTypographyProps: {
            color: value ? 'gray.0' : 'gray.600',
          },
          TokenTypographyProps: {
            color: value ? 'gray.50' : 'gray.600',
          },
        }}
      />
    </LabelWithValue>
  )
}
