import React from 'react'
import { Stack, Box, BoxProps as MuiBoxProps, useTheme } from '@mui/material'
import Link, { LinkProps } from '../Link/Link'
import SvgIcon from '../SvgIcon/SvgIcon'
import Typography from '../Typography/Typography'

export type ServiceMessageProps = {
  /** The root element of this component uses the BoxProps from Material UI  */
  BoxProps?: MuiBoxProps
  title?: string
  message: string
  isReadyToReconnect?: boolean
  /**
   * [Props applied to the Link element.](/?path=/docs/components-link--primary)
   * */
  LinkProps?: Partial<LinkProps>
  closeToast?: () => void
}

export default function ServiceMessage({
  BoxProps,
  title = 'Servers is on Maintenance',
  message,
  isReadyToReconnect,
  LinkProps,
  closeToast,
}: ServiceMessageProps) {
  const theme = useTheme()
  const onReconnect = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (LinkProps?.onClick) {
      LinkProps.onClick(event)
    }
    if (closeToast) {
      closeToast()
    }
  }

  return (
    <Box bgcolor="primary.main" padding="4px" width="100%" {...BoxProps}>
      <Stack color="gray.900" direction="row" alignItems="stretch">
        <Stack
          direction={{ xs: 'column', sm2: 'row' }}
          border="2px solid"
          borderColor="gray.900"
          alignItems="center"
          flexGrow={1}
        >
          <Stack
            direction="row"
            gap="8px"
            alignItems="center"
            height="100%"
            borderRight="2px solid"
            borderColor="gray.900"
            padding="8px 12px"
            sx={{
              [theme.breakpoints.down('sm2')]: {
                borderRight: 'none',
                borderBottom: '2px solid',
                width: '100%',
                justifyContent: 'center',
                padding: '5px 12px',
              },
            }}
          >
            <SvgIcon
              name="InformationLine"
              sx={{ [theme.breakpoints.down('sm2')]: { fontSize: '18px' } }}
            />
            <Typography variant="h7">{title}</Typography>
          </Stack>

          <Typography
            variant="bodySmall"
            textAlign="initial"
            flexGrow={1}
            padding="0 16px"
            sx={{
              [theme.breakpoints.down('sm2')]: {
                padding: '2px 16px',
                minHeight: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              },
            }}
          >
            {message}
          </Typography>

          {isReadyToReconnect && (
            <Box
              display="flex"
              height="100%"
              bgcolor="gray.900"
              padding="0 10px"
              minWidth="125px"
              textAlign="center"
              justifyContent="center"
              sx={{
                [theme.breakpoints.down('sm2')]: {
                  minHeight: '36px',
                  width: '100%',
                },
              }}
            >
              <Link
                href="#"
                color="secondary"
                startIcon={<SvgIcon name="repeat-line" />}
                {...LinkProps}
                onClick={onReconnect}
              >
                {LinkProps?.children || 'Reconnect'}
              </Link>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
