import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'


const playedGamesSelector = (state:RootState, gameId:string) =>state.userReducer.userInfo.statistics?.currentMonth.gameStats &&
        state.userReducer.userInfo.statistics.currentMonth.gameStats.filter((x:any) => x.intGameId === gameId)
       
  
export const filteredPlayedGames = createSelector(
    playedGamesSelector,
    (playedGames) => {
        if(playedGames!==undefined && playedGames.length>0){
            return {
                playedGamesCount: playedGames[0].playedGamesCount,
                wonGamesCount: playedGames[0].wonGamesCount,
                lostGamesCount: playedGames[0].lostGamesCount,
            };
        }
        else{
            return {
                playedGamesCount: 0,
                wonGamesCount: 0,
                lostGamesCount: 0,
            };
        };
        
    }
);