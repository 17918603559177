import React, {useEffect} from 'react';
import { useAppSelector, useAppDispatch } from 'src/hooks'
import JoinLobbyDialog from 'src/components/JoinLobbyDialog/JoinLobbyDialog'
import { resetWaitDialog } from 'src/actions/dialogActions';

export function WaitDialog() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false)

    const {title, subtitle, showWait, status, timeout}= useAppSelector(state => state.dialogReducer.joindialog);


    useEffect(() => {
       
        setOpen(showWait)
        if(showWait && status && (status==='error'|| status==='success')){
            if(timeout>0){
              let timer =  setTimeout(() => { dispatch(resetWaitDialog()) }, timeout);
              return () => {
                clearTimeout(timer);
              };  
            }
            
        }          
        return;

    }, [showWait,status])
    
    return (
     
        <JoinLobbyDialog
            closable={false}
            open={open}
            loading={status===undefined}
            onBackdropClick={() => {}}
            onClose={()=> dispatch(resetWaitDialog())}
            onJoinLobby={function noRefCheck() {}}
            subtitle={subtitle}
            status={status}
            title={title}
            />
            
    )
}