import { StakingType } from "src/helpers/types";
/*
export function getFlexiStakingInfo() {
    return { type: "GET_FLEXI_STAKING_INFO"};
}

export function getVestedStakingInfo() {
    return { type: "GET_VESTED_STAKING_INFO"};
}

export function getBackendStakingData() {
    return { type: "GET_BACKEND_STAKING_DATA"};
}*/

export function stake(amount: number, stakeType: StakingType) {
    return { type: "STAKE", amount,stakeType};
}

export function unstake(amount: number, fee:number, stakeType: StakingType) {
    return { type: "UNSTAKE", amount, fee, stakeType};
}

//You allways claim full amount, amount is just to show expected amount
export function claimRewards(stakeType: StakingType, amount: string) {
    return { type: "CLAIM_REWARD", stakeType,amount};
}