import React from 'react'
import { Stack, StackProps } from '@mui/material'
import MediaWithTitle, { MediaWithTitleProps } from 'src/components/MediaWithTitle/MediaWithTitle'

export type LabelWithValueProps = React.PropsWithChildren & {
  StackProps?: StackProps
  MediaWithTitleProps?: MediaWithTitleProps
}

export default function LabelWithValue({
  StackProps,
  MediaWithTitleProps,
  children,
}: LabelWithValueProps) {
  return (
    <Stack gap="12px" {...StackProps}>
      <MediaWithTitle {...MediaWithTitleProps} />
      {children}
    </Stack>
  )
}
