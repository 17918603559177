import { JsonHubProtocol, HttpTransportType, HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { authToken } from "../utils/authHeader";
import { config, EnvironmentType } from "../config/config";
import {
    lobbyInfo, lobbiesInfo, lobbyNotFound, availableChallenges, availableSpecialChallenges, challengeUpdate, challengeAdd, joinedChallenge, leftChallenge, challengeEvent,
    challengeParticipantEvent, challengeResult, challengeRefund, challengeError, challengeParticipantsError,
    streamerChallengeEvent, streamerChallengeParticipantEvent, streamerChallengeResult, closeStreamerLobby, streamerChallengeError, streamerChallengeParticipantsError, challengeLogEvent, challengeLogEvents, challengeRoundResultEvent, initGlobalEvents, globalEvents
} from "../actions/challengeActions";
import { setAvailableGames } from "../actions/gameActions";
import { onTimeChallenges, onTimeChallengeNew, onTimeEvent, onTimeError, tournamentEvent, tournamentChallengeEvent, tournamentUpdated, tournamentRoundResultEvent,tournamentResult } from "../actions/tournamentActions";
import { refreshUser, validateGameUser, userError, setBalance, userActionPreConfirm } from "../actions/userActions";
import { showErrorPanel, showErrorPanelWithReconnect, hidePanel, flashInfoPanel } from "../actions/infoPanelActions";
import pako from 'pako';

const challengeUrl = `${config.url.BASE_URL}/challenges`;
const streamerUrl = `${config.url.BASE_URL}/streamer`;


export function signalrMiddleware({ getState, dispatch }) {
    let challengeConnection = null;
    let challengeConnected = false;
    let lostChallengeConnection = false;

    let streamerConnection = null;
    let streamerConnected = false;
    let currentStreamerNick = null;

    const protocol = new JsonHubProtocol();
    const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
    const options = {
        transport,
        logMessageContent: config.environment === EnvironmentType.PRODUCTION ? false : true,
        logger: config.environment === EnvironmentType.PRODUCTION ? LogLevel.Error : LogLevel.Trace,
        accessTokenFactory: () => authToken()
    };

    return function (next) {
        return function (action) {
            // do your stuff
            if (action.type === "SUBSCRIBE_CHALLENGES") {

                if (!challengeConnected) {
                    console.log("Subscribe to challenges!!");

                    initConnection();
                    start(false, 1, null, null);
                }
                else {
                    console.log("Already connected to challenges");
                }
            }
            else if (action.type === "SUBSCRIBE_TO_LOBBY") {
                if (!challengeConnected) {
                    console.log("Subscribe to challenges!!!");

                    initConnection();
                    start(false, 1, action.id, action.isTournament);
                }
                else {
                    console.log("Is connected, only subscribing");
                    subscribeLobby(action.id, action.isTournament)
                }
               
            }
            else if (action.type === "SUBSCRIBE_TO_STREAMER_LOBBY") {
                if (!streamerConnected) {
                    console.log("Subscribe to streamer!!");

                    initStreamerConnection();
                    startStreamer(false, 1, action.nick);
                }
                else {
                    subscribeStreamerLobby(action.nick)
                }
            }
            else if (action.type === "UNSUBSCRIBE_LOBBY") {
                console.log("Unsubscribe to lobby");
                unsubscribeLobby(action.id);
                // lobbyConnection.stop();
            }
            else if (action.type === "UNSUBSCRIBE_CHALLENGES") {
                console.log("Unsubscribe challenges and disconnect");
                challengeConnected = false;
                if (challengeConnection != null) {
                    challengeConnection.stop();
                }
            }
            else if (action.type === "CHANGED_VISIBILITY" && action.visible) {              
                if (lostChallengeConnection) {
                    console.log("Connection was lost, recoonecting after page visible again!");
                    lostChallengeConnection = false;
                    start(true, 1, null, null);
                }
            }

            return next(action);
        };
    };

    function initConnection() {
        // create the connection instance
        challengeConnection = new HubConnectionBuilder()
            .withUrl(challengeUrl, options)
            .withHubProtocol(protocol)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 210000) {
                        // If we've been reconnecting for less than 210 seconds so far,
                        // wait between 5 and 30 seconds before the next reconnect attempt.
                        return (5 + Math.floor(Math.random() * 30)) * 1000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .build();

        challengeConnection.on("ServiceStatus", onServiceStatus);

        challengeConnection.on("AvailableGames", onAvailableGames);
        challengeConnection.on("AvailableChallenges", onAvailableChallenges);
        challengeConnection.on("SpecialChallenges", onSpecialChallenges);

        challengeConnection.on("OnTimeChallenges", onOnTimeChallenges);
        challengeConnection.on("OnTimeChallengeNew", onOnTimeChallengeNew);
        challengeConnection.on("OnTimeEvent", onOnTimeEvent);
        challengeConnection.on("OnTimeError", onOnTimeError);

        challengeConnection.on("ChallengeUpdate", onChallengeUpdate);

        challengeConnection.on("JoinedChallenge", onJoinedChallenge);
        challengeConnection.on("LeftChallenge", onLeftChallenge);
        challengeConnection.on("ChallengeEvent", onChallengeEvent);
        challengeConnection.on("ChallengeRoundResultEvent", onChallengeRoundResultEvent);
        challengeConnection.on("ChallengeParticipantEvent", onChallengeParticipantEvent);
        challengeConnection.on("ChallengeResult", onChallengeResult);
        challengeConnection.on("ChallengeRefund", onChallengeRefund);
        challengeConnection.on("ChallengeError", onChallengeError);
        challengeConnection.on("ChallengeParticipantsError", onChallengeParticipantsError);
        challengeConnection.on("NewChallenge", onNewChallenge);

        challengeConnection.on("LogEvent", onChallengeLogEvent);  
        challengeConnection.on("LogEvents", onChallengeLogEvents);  

        challengeConnection.on("TournamentUpdated", onTournamentUpdated);
        challengeConnection.on("TournamentEvent", onTournamentEvent);
        challengeConnection.on("TournamentChallengeEvent", onTournamentChallengeEvent);
        challengeConnection.on("TournamentRoundResultEvent", onTournamentRoundResultEvent);
        challengeConnection.on("TournamentChallengeResult",onTournamentChallengeResult);

        challengeConnection.on("ValidateGameConnection", onValidateGameConnection)
        challengeConnection.on("UserError", onUserError)
        challengeConnection.on("UpdateBalance", onUpdateBalance);

        challengeConnection.on("UserActionPreConfirm", onUserActionPreConfirm);

        challengeConnection.on("InitGlobalEvents", onInitGlobalEvents);  
        challengeConnection.on("GlobalEvents", onGlobalEvents);  

        challengeConnection.onreconnecting((err) => onChallengeReconnecting(err))
        challengeConnection.onreconnected((connectionId) => onChallengeReconnected(connectionId))
        challengeConnection.onclose((err) => onChallengeClosed(err))
    }

    function initStreamerConnection() {
        // create the connection instance
        streamerConnection = new HubConnectionBuilder()
            .withUrl(streamerUrl, options)
            .withHubProtocol(protocol)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 210000) {
                        // If we've been reconnecting for less than 210 seconds so far,
                        // wait between 5 and 30 seconds before the next reconnect attempt.
                        return (5 + Math.floor(Math.random() * 30)) * 1000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .build();


        streamerConnection.on("JoinedChallenge", onJoinedChallenge);
        streamerConnection.on("LeftChallenge", onLeftChallenge);
        streamerConnection.on("ChallengeEvent", onStreamerChallengeEvent);
        streamerConnection.on("ChallengeParticipantEvent", onStreamerChallengeParticipantEvent);
        streamerConnection.on("ChallengeResult", onStreamerChallengeResult);
        streamerConnection.on("ChallengeError", onStreamerChallengeError);
        streamerConnection.on("ChallengeParticipantsError", onStreamerChallengeParticipantsError);

        streamerConnection.on("StreamerJoinedChallenge", onStreamerJoinedChallenge);
        streamerConnection.on("StreamerLeftChallenge", onStreamerLeftChallenge);

        streamerConnection.onreconnecting((err) => onStreamerReconnecting(err))
        streamerConnection.onreconnected((connectionId) => onStreamerReconnected(connectionId))
        streamerConnection.onclose((err) => onStreamerClosed(err))
    }

    async function start(reconnected,attemptCount,id,isTournament) {
        try {
            await challengeConnection.start();
            console.assert(challengeConnection.state === HubConnectionState.Connected);
            if (id !== null) {
                challengeConnection.invoke("SubscribeToCurrentLobbies", id, isTournament)
                    .then((result) => onLobbiesInfo(result))
                    .catch(err => onLobbyError(err));
            }
            else {
                challengeConnection.invoke("SubscribeToCurrentLobbies", null, null)
                    .then((result) => onLobbiesInfo(result))
                    .catch(err => { console.log("Error when invoking SubscribeToCurrentLobbies") });
            }
            onChallengeConnected(reconnected);
        } catch (err) {
            console.assert(challengeConnection.state === HubConnectionState.Disconnected);
            if (attemptCount < 8) {
                onChallengeConnectionError(err)
                console.log("Attempt count=" + attemptCount);
                setTimeout(() => start(true, attemptCount + 1, id), 8000);
            }
            else {
                lostChallengeConnection = true;
                dispatch(showErrorPanel("Could not connect to backend, please reload page"));
            }
        }
    };

    async function startStreamer(reconnected, attemptCount, nick) {
        try {
            await streamerConnection.start();
            console.assert(streamerConnection.state === HubConnectionState.Connected);
            if (nick !== null) {
                streamerConnection.invoke("SubscribeToStreamerLobby", nick)
                    .then((result) => onStreamerLobbyInfo(result))
                    .catch(err => onStreamerLobbyError(err));
            }
            onStreamerConnected(reconnected);
        } catch (err) {
            console.assert(streamerConnection.state === HubConnectionState.Disconnected);
            if (attemptCount < 8) {
                onStreamerConnectionError(err)
                setTimeout(() => startStreamer(true, attemptCount + 1,nick), 8000);
            }
            else {
                dispatch(showErrorPanelWithReconnect("Could not establish a connection, click to reload page"));
            }
        }
    };

    function onChallengeConnected(reconnected) {
        console.log("Challenge connected!!");
        challengeConnected = true;
        if (reconnected) {
            dispatch(flashInfoPanel("Connection established!"));
            dispatch(refreshUser());
        }
        //Hub will send challenges on clientConnected
    }

    function onStreamerConnected(reconnected) {
        console.log("Streamer connected!!");
        streamerConnected = true;
        if (reconnected) {
            dispatch(flashInfoPanel("Connection established!"));
        }
    }

    function onChallengeReconnecting(err) {
        console.assert(challengeConnection.state === HubConnectionState.Reconnecting);
        //Inform user
        console.log("Connection lost reconnecting...");
        dispatch(showErrorPanel("Connection lost reconnecting..."));
    }

    function onStreamerReconnecting(err) {
        console.assert(streamerConnection.state === HubConnectionState.Reconnecting);
        //Inform user
        console.log("Streamer connection lost reconnecting...");
        dispatch(showErrorPanel("Connection lost reconnecting..."));
    }

    function onChallengeReconnected(connectionId) {
        console.assert(challengeConnection.state === HubConnectionState.Connected);
        challengeConnected = true;
        //Reconnect to hub and get missed info
        console.log("Connection reestablished!");

        var id = null;
        var isTournament = null;
        if (window.location.pathname.startsWith('/lobby/')) {
            id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
            isTournament = false;
        }
        else if (window.location.pathname.startsWith('/tournament/')) {
            id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
            isTournament = true;
        }

        challengeConnection.invoke("SubscribeToCurrentLobbies",id,isTournament)
            .then((result) => onLobbiesInfo(result))
            .catch(err => { console.log("Error when challenge reconnected and invoking SubscribeToCurrentLobbies") }); 

        dispatch(refreshUser());        
        dispatch(flashInfoPanel("Connection reestablished!"));
    }

    function onStreamerReconnected(connectionId) {
        console.assert(streamerConnection.state === HubConnectionState.Connected);
        streamerConnected = true;
        //Reconnect to hub and get missed info
        console.log("Streamer connection reestablished!");

        if (currentStreamerNick !== null) {
            streamerConnection.invoke("SubscribeToStreamerLobby", currentStreamerNick)
                .then((result) => onStreamerLobbyInfo(result))
                .catch(err => onStreamerLobbyError(err));
        }
        dispatch(flashInfoPanel("Connection reestablished!"));
    }

    function onChallengeConnectionError(err) {
        challengeConnected = false;
        console.error('SignalR Challenge Connection Error, will try to reconnect. Error= ', err)
        dispatch(showErrorPanel("Could not connect to backend, retrying..."));

    }

    function onStreamerConnectionError(err) {
        streamerConnected = false;
        currentStreamerNick = null;
        console.error('SignalR Streamer Connection Error, will try to reconnect. Error= ', err)
        dispatch(showErrorPanel("Could not connect to backend, retrying..."));

    }

    function onChallengeClosed(err) {
        console.log("Challenge connection closed with err="+err);
        console.assert(challengeConnection.state === HubConnectionState.Disconnected);
        if (challengeConnected) {
            challengeConnected = false;
            lostChallengeConnection = true;
            dispatch(showErrorPanel("Could not connect to backend, please wait"));
            //dispatch(showErrorPopupWithAction("Lost connection, click to reload page","Reload"));
        }
        else {
            console.log("Challenge connection closed on purpose");
        }
    }

    function onStreamerClosed(err) {
        console.log("Streamer connection closed with err=" + err);
        console.assert(streamerConnection.state === HubConnectionState.Disconnected);
        if (streamerConnected) {
            streamerConnected = false;
            dispatch(showErrorPanelWithReconnect("Lost connection, click to reload page"));
        }
        else {
            console.log("Streamer connection closed on purpose");
        }
    }

    function subscribeLobby(id,isTournament) {
        challengeConnection.invoke("SubscribeToLobby", id, isTournament)
            .then((result) => onLobbyInfo(result))
            .catch(err => onLobbyError(err));
    }

    function subscribeStreamerLobby(nick) {
        streamerConnection.invoke("SubscribeToStreamerLobby", nick)
            .then((result) => onStreamerLobbyInfo(result))
            .catch(err => onStreamerLobbyError(err));
    }

    function unsubscribeStreamerLobby(challengeId) {
        streamerConnection.invoke("UnsubscribeToStreamerLobby", challengeId)
          
    }

    function unsubscribeLobby(id) {
        challengeConnection.invoke("UnsubscribeToLobby", id)
            .catch(err => console.error(err));
    }

    function onLobbyInfo(res) {
        if (res.id !== 0) {
            const userId = getState().userReducer.userInfo.id;
            dispatch(lobbyInfo(res, userId));
           
        }
        else {
            console.log("No challenge found or user not logged in");
        }
    }

    function onLobbiesInfo(res) {
        if (res.length !== 0) {
            const userId = getState().userReducer.userInfo.id;
            dispatch(lobbiesInfo(res, userId));
        }
        else {
            console.log("No active lobbies found or user not logged in");
        }
    }

    function onStreamerLobbyInfo(res) {
        console.log("Info recieved from StreamerLobbyInfo" + res);
        if (res.streamerId !== 0) {
            currentStreamerNick = res.streamerNickname;
            dispatch(lobbyInfo(res.challenge, res.streamerId));
        }
        else {
            console.log("Streamer not playing or not found");
            currentStreamerNick = null;
            dispatch(lobbyNotFound());
        }
    }

    function onLobbyError(err) {
        console.log("Error subscribing to lobby because: " + err);
        dispatch(lobbyNotFound());
    }

    function onStreamerLobbyError(err) {
        console.log("Error subscribing to streamer lobby because: " + err);
        currentStreamerNick = null;
        dispatch(lobbyNotFound());
    }

    function onAvailableGames(res) {
        dispatch(setAvailableGames(res));
    }

    function onAvailableChallenges(res) {
        dispatch(availableChallenges(res));
    }

    function onSpecialChallenges(res) {
        dispatch(availableSpecialChallenges(res));
    }

    function onOnTimeChallenges(res) {
        dispatch(onTimeChallenges(res));
    }

    function onOnTimeChallengeNew(event) {
        const decompressedData = decompress(event);
        const jsonData = JSON.parse(decompressedData);
        dispatch(onTimeChallengeNew(jsonData)); //Will be caught by reducer*/
    }

    function onOnTimeEvent(event) {
        dispatch(onTimeEvent(event)); //Will be caught by reducer
    }

    function onOnTimeError(event) {
        dispatch(onTimeError(event)); //Will be caught by reducer
    }

    function onChallengeUpdate(res) {
        console.log("Info recieved from onChallengeUpdate " + res.challengeId);
        const userId = getState().userReducer.userInfo === undefined ? undefined : getState().userReducer.userInfo.id;        
        dispatch(challengeUpdate(res, userId));
    /*    if (res.challengeReady === true) { //Challenge is full and will start
            dispatch(challengeRemoveWithDelay(res.challengeId));
        }
        if (res.newChallengeCreated === true) {
            dispatch(challengeAdd(res.newChallenge));
        }*/
    }

    function onNewChallenge(res) {
        dispatch(challengeAdd(res));
    }

  /*  function onLobbyConnected(id) {
        console.log("Lobby connected!!");
        lobbyConnected = true;
        lobbyConnection.invoke("JoinLobby", id)
            .catch(err => console.error(err));
        lobbyConnection.invoke("SendLobbyInfo", id)
            .catch(err => console.error(err));
    }

    function onLobbyConnectionError(err) {
        console.error('SignalR Lobby Connection Error: ', err)
        dispatch(showErrorPopup("Lost connection, please reload page"));
    }       

    function onLobbyClosed() {
        console.log("Lobby connection closed");
        lobbyConnected = false;
    }*/

    function onJoinedChallenge(res) {
        console.log("Info recieved from onJoinedChallenge" + res);
        dispatch(joinedChallenge(res));
    }

    function onLeftChallenge(res) {
        console.log("Info recieved from onLeftChallenge" + res);
        dispatch(leftChallenge(res));
    }

    function onInitGlobalEvents(events) {
        dispatch(initGlobalEvents(events));
    }

    function onGlobalEvents(event) {
        dispatch(globalEvents(event));
    }

    function onChallengeLogEvent(event) {
        dispatch(challengeLogEvent(event)); //Will be caught by saga to refresh log
    }

    function onChallengeLogEvents(event) {
        dispatch(challengeLogEvents(event)); //Will be caught by saga to refresh log
    }

    function onChallengeEvent(event) {
        dispatch(challengeEvent(event)); //Will be caught by saga to refresh user and full game refresh
    }

    function onChallengeRoundResultEvent(event) {
        dispatch(challengeRoundResultEvent(event)); //Will be caught by saga 
    }

    function onStreamerChallengeEvent(event) {
        dispatch(streamerChallengeEvent(event)); //Will be caught by saga to do full game refresh
    }

    function onChallengeParticipantEvent(event) {
        dispatch(challengeParticipantEvent(event)); //Will only update store with info from this event
    }

    function onStreamerChallengeParticipantEvent(event) {
        dispatch(streamerChallengeParticipantEvent(event)); //Will only update store with info from this event
    }

    function onChallengeResult(result) {
        dispatch(challengeResult(result)) ////Will be caught by saga to refresh user and full game refresh
    }

    function onChallengeRefund(result) {
        dispatch(challengeRefund(result)) ////Will be caught by saga to refresh user and full game refresh
    }

    function onStreamerChallengeResult(result) {
        dispatch(streamerChallengeResult(result)) ////Will be caught by saga for full game refresh
    }

    function onChallengeError(error) { ////Will be caught by saga to refresh user and full game refresh
        dispatch(challengeError(error))
    }

    function onStreamerChallengeError(error) { ////Will be caught by saga to refresh user and full game refresh
        dispatch(streamerChallengeError(error))
    }

    function onChallengeParticipantsError(error) {//Will only update store with info from this event
        dispatch(challengeParticipantsError(error))
    }

    function onStreamerChallengeParticipantsError(error) {//Will only update store with info from this event
        dispatch(streamerChallengeParticipantsError(error))
    }
    
    function onTournamentEvent(event) {
        dispatch(tournamentEvent(event)); //Will be caught by reducer
    }

    function onTournamentChallengeEvent(event) {
        dispatch(tournamentChallengeEvent(event)); //Will be caught by reducer
    }    

    function onTournamentUpdated(event) {
        dispatch(tournamentUpdated(event)); //Will be caught by reducer
    }

    function onTournamentRoundResultEvent(event) {
        dispatch(tournamentRoundResultEvent(event)); //Will be caught by reducer 
    }
    function onTournamentChallengeResult(event) {
        dispatch(tournamentResult(event)); //Will be caught by reducer 
    }    

    function onValidateGameConnection(validate) {
        console.log("onValidateGameConnection:" + validate.publicAddress + " " + validate.nonce);

        dispatch(validateGameUser(validate.gameId, validate.gameName, validate.publicAddress, validate.nonce));
    }

    function onUserError(event) {
        dispatch(userError(event))
    }

    function onUpdateBalance(res) {
        dispatch(setBalance(res.amount,res.balance, res.tokenType))
    }

    function onUserActionPreConfirm() {
        dispatch(userActionPreConfirm())
    }

    

    function onStreamerJoinedChallenge(res) {
        console.log("Streamer joined challenge");
        console.log("Currently connected to " + currentStreamerNick);
        const streamer = getState().challengeReducer.streamer;    
        console.log("Currently following " + streamer);
        if (res.nickname === streamer) {
            //Streamer joined challenge
            subscribeStreamerLobby(streamer);
        }
    }

    function onStreamerLeftChallenge(res) {
        console.log("Streamer left challenge");
        console.log("Currently connected to " + currentStreamerNick);
        const streamer = getState().challengeReducer.streamer;
        console.log("Currently following " + streamer);
        if (res.nickname === streamer) {
            //Streamer left challenge
            unsubscribeStreamerLobby(res.challengeId);
            dispatch(closeStreamerLobby(res.challengeId));
        }
    }

    function onServiceStatus(status) {
        if (!status.showMessage) {
            dispatch(hidePanel());
        }
        else if (status.showMessage)  {
            dispatch(showErrorPanel(status.message));
        }
    }

    
    function decompress(base64Data) {
        try {
            // Decode the Base64 string to get the byte array
            const byteChars = atob(base64Data);
            const byteArray = new Uint8Array(Array.from(byteChars).map(char => char.charCodeAt(0)));
    
            // Decompress
            const decompressed = pako.ungzip(byteArray);
    
            // Convert Uint8Array back to a string
            return new TextDecoder().decode(decompressed);
        } catch (error) {
            console.error("Decompression error:", error);
            return null;
        }
    }

    
    
    
    
    
    
  
    
    
    
    
}

