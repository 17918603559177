import { ethers, Contract, utils } from 'ethers';
import { config } from "../config/config";
import detectEthereumProvider from '@metamask/detect-provider';
import { TokenType } from '../helpers/types';

const getBlockchain = () =>
    new Promise(async (resolve, reject) => {

        // this returns the provider, or null if it wasn't detected
        const ethProvider = await detectEthereumProvider();
        // Modern dapp browsers...
        if (ethProvider) {

            if (ethProvider !== window.ethereum) {
                console.error('Do you have multiple wallets installed?');
            }

            try {
                // Request account access if needed
                //await ethProvider.request({ method: 'eth_requestAccounts' });   // old  await window.ethereum.enable();
                // Accounts now exposed
                
                const provider = new ethers.providers.Web3Provider(ethProvider);
                const signer = await provider.getSigner();

                provider.send("eth_requestAccounts", []);
                // Accounts now exposed
                const chainId = await ethProvider.request({ method: 'eth_chainId' });
                const networkVersion = await ethProvider.request({ method: 'net_version' });

                console.log("chainId:" + chainId);
                console.log("networkVersion:" + networkVersion);
                console.log("config.blockchain.chainId:" + config.blockchain.chainId);      
                console.log("config.blockchain.weth_address:" + config.blockchain.weth_address);          

                if (config.blockchain.chainId === chainId) {
                    const gameServer = new ethers.Contract(
                        config.blockchain.solo_challenge_address,
                        soloChallengeABI,
                        signer
                    );

                    const challengeCoin = new ethers.Contract(
                        config.blockchain.cc_address,
                        erc20ABI,
                        signer
                    );

                    const weth = new ethers.Contract(
                        config.blockchain.weth_address,
                        erc20ABI,
                        signer
                    );

                    const wethSwap = new ethers.Contract(
                        config.blockchain.weth_address,
                        wethSwapABI,
                        signer
                    );

                    resolve({ provider, signer, gameServer, challengeCoin, weth, wethSwap });
                }
                else {
                    reject(new Error("Please change network in wallet to " + config.blockchain.chainDisplayName));
                }

               

            } catch (error) {
                console.log("Error:"+ error)
                reject(error);
            }
        }       
        else {
          
            reject(new Error("No wallet found"));
        }

    });



const getGameServerContract = (signer) => {
    return new ethers.Contract(
        config.blockchain.solo_challenge_address,
        soloChallengeABI,
        signer
    );  
}    

const getWethContract = async (signer) => {
    const chainId = await signer.getChainId();
    return new ethers.Contract(
        chainId === config.blockchain.chainIdNumber ? config.blockchain.weth_address : config.blockchainStaking.weth_address,
        erc20ABI,
        signer
    );
}   

const getWethSwapContract = (signer) => {
    return new ethers.Contract(
        config.blockchain.weth_address,
        wethSwapABI,
        signer
    );
} 

const getChallengeCoinContract = async (signer) => {
    const chainId = await signer.getChainId();
    return new ethers.Contract(
        chainId === config.blockchain.chainIdNumber ? config.blockchain.cc_address : config.blockchainStaking.cc_address,
        erc20ABI,
        signer
    );
}

const getFlexiStakingContract = (signer) => {
    return new ethers.Contract(
        config.blockchainStaking.flexi_staking_address,
        stakingABI,
        signer
    );
}

const getVestedStakingBaseContract = (signer) => {
    return new ethers.Contract(
        config.blockchainStaking.vested_staking_address,
        stakingABI,
        signer
    );
}

const getVestedStakingExtendedContract = (signer) => {
    return new ethers.Contract(
        config.blockchainStaking.vested_staking_address,
        vestedStakingABI,
        signer
    );
}


const isWalletConnected = async () => {
    const ethProvider = await detectEthereumProvider();
    if (!ethProvider) throw new Error("No crypto wallet found");
    
    return ethProvider.isConnected();
  
}



const watchChallengeAsset = async () => {
    const ethProvider = await detectEthereumProvider();
    if (!ethProvider) throw new Error("No crypto wallet found");

    const chainId = await ethProvider.request({ method: 'eth_chainId' });
    const networkVersion = await ethProvider.request({ method: 'net_version' });

    if (config.blockchain.chainId === chainId) {
        await ethProvider.request({
            method: "wallet_watchAsset",
            params: {
                type: 'ERC20',
                options: {
                    address: config.blockchain.cc_address,
                    symbol: 'CT',
                    decimals: 18,
                    image: 'https://challenge.gg/icons/icon-256x256.png',
                },
            },
        });
    }
}

const changeNetwork = async () => {

    const ethProvider = await detectEthereumProvider();
    if (!ethProvider) throw new Error("No crypto wallet found");

    const chainId = await ethProvider.request({ method: 'eth_chainId' });
    if (config.blockchain.chainId !== chainId) {       
        try{
            await ethProvider.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: config.blockchain.chainId
                    }
                ]
            });
        } catch (error) {
            // This error code indicates that the chain has not been added to MetaMask
            if (error.code === 4902) {
                try {
                    await ethProvider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                ...networks[config.blockchain.chainName]
                            }
                        ]
                    });
                }
                catch (addError) {
                    throw new Error("Could not add network to wallet");
                }
            }
            else {
                // handle other "switch" errors
                throw new Error("Could not switch network in wallet");
            }
        }
        
        
    }
};

const networks = {
    ethereum: {
        chainId: `0x${Number(1).toString(16)}`,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Etherum Native Token",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: [
            "https://mainnet.infura.io/v3/",

        ],
        blockExplorerUrls: ["https://etherscan.io"]
    },
    sepolia: {
        chainId: `0x${Number(11155111).toString(16)}`,
        chainName: "Sepolia Testnet",
        nativeCurrency: {
            name: "Etherum Native Token",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: [
            "https://eth-sepolia.g.alchemy.com/v2/D1JWkIwq-YX3NY5NKpUZvf45dudgy9na"

        ],
        blockExplorerUrls: ["https://sepolia.etherscan.io"]
    },
    ganache: {
        chainId: `0x${Number(1337).toString(16)}`,
        chainName: "Ganache Local test",
        nativeCurrency: {
            name: "Etherum Native Token",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: [
            "https://127.0.0.1:8545"

        ],
        blockExplorerUrls: ["https://127.0.0.1:8545"]
    },
    ludus: {
        chainId: `0x${Number(2147388124).toString(16)}`,
        chainName: "Ludus Testnet",
        nativeCurrency: {
            name: "Etherum Native Token",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: [
            "https://ludus-testnet-rpc.eu-north-2.gateway.fm"

        ],
        blockExplorerUrls: ["https://ludus-testnet-blockscout.eu-north-2.gateway.fm"]
    }


};

//ABI for SoloChallenge contract to call joinChallenge, joinChallengeById, leave
const soloChallengeABI = [
  {
    "inputs": [
      {
        "internalType": "uint16",
        "name": "gameTemplateId",
        "type": "uint16"
      }
    ],
    "name": "joinChallenge",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "challengeId",
        "type": "uint256"
      }
    ],
    "name": "joinChallengeById",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "challengeId",
        "type": "uint256"
      }
    ],
    "name": "leaveChallenge",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

//ABI for ERC20 token to call approve, balanceOf and allowance
const erc20ABI = [
    {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          }
        ],
        "name": "allowance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
];

const wethSwapABI = [
    {
        "inputs": [],
        "name": "deposit",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "wad",
            "type": "uint256"
          }
        ],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
];

const stakingABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "claimReward",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "earned",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getRewardForDuration",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "lastTimeRewardApplicable",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rewardPerToken",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "stake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "unstake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "unstakeFeePercentage",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "getAccountStakingInfo",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "totalStaked",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "stakerCount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "currentRewardsDuration",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "currentPeriodFinish",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "currentRewardPerToken",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "rewardForDuration",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "unstakeFee",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "accountBalance",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "accountEarned",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
];

const vestedStakingABI = [
    {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getAvailableAmount",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getVestingSchedule",
        "outputs": [
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          },
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          },
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          },
          {
            "internalType": "uint8",
            "name": "",
            "type": "uint8"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getNextUnlockTimestamp",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
];

export { getBlockchain, changeNetwork, watchChallengeAsset, isWalletConnected, getGameServerContract, 
    getWethContract, getChallengeCoinContract, getWethSwapContract, 
    getFlexiStakingContract, getVestedStakingBaseContract, getVestedStakingExtendedContract};
