import React from 'react'
import { useField } from 'formik'
import {
  TextFieldComponentProps,
  FormikTextFieldProps,
} from 'src/components/FormikFields/FormikTextField'
import PasswordField from 'src/components/PasswordField/PasswordField'

type PasswordFieldComponentProps = TextFieldComponentProps

function PasswordFieldComponent({
  field,
  meta,
  id,
  label,
  ...restProps
}: PasswordFieldComponentProps) {
  const error = meta.touched && meta.error

  return (
    <PasswordField
      id={id || field.name}
      label={label}
      {...field}
      {...restProps}
      error={!!error}
      helperText={error}
    />
  )
}

export type FormikPasswordFieldProps = FormikTextFieldProps

export default function FormikPasswordField({ name, ...restProps }: FormikPasswordFieldProps) {
  const [field, meta] = useField({ name })

  return <PasswordFieldComponent field={field} meta={meta} {...restProps} />
}
