import React from 'react'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { RiGift2Line, RiTeamLine } from '@remixicon/react'
import StakingSumItem from '../StakingSumItem/StakingSumItem'
import { PNG_ICONS } from 'src/assets/icons/icons'
import Image from '../Image/Image'
import { TokenType } from 'src/helpers/types'
import { formatTokenToString } from 'src/utils/tokenUtil'

type StakingSumsWrapperProps = {
  // Add your props here
  totalStaked?: bigint
  totalRewards?: bigint
  usersInvolved?: bigint
}


function StakingSumsWrapper({totalStaked,totalRewards,usersInvolved}: StakingSumsWrapperProps) {
  const theme = useTheme()
  const isMd834 = useMediaQuery(theme.breakpoints.down('md834'))
  const isMd720 = useMediaQuery(theme.breakpoints.down('md720'))

  const mockData = [
    {
      title: formatTokenToString(totalStaked, TokenType.CT,2,false,true,true),
      description: 'Total Staked',
      icon: <Image src={PNG_ICONS.coins} width={20} height={20} alt="coins" />,
    },
    {
      title: formatTokenToString(totalRewards, TokenType.CT,2,false,true,true),
      description: 'Total Rewards',
      icon: <RiGift2Line size={20} color={theme.palette.primary.main} />,
    },
    {
      title: usersInvolved!==undefined ? usersInvolved.toString(): '',
      description: 'Users Involved',
      icon: <RiTeamLine size={20} color={theme.palette.primary.main} />,
    },
  ]

  return (
    <Stack
      direction="row"
      gap={3}
      position="relative"
      sx={{
        width: '100%',
        maxWidth: '696px',
        mt: '4px',
        ml: !isMd834 ? '21px' : '0',
        p: '7px',
        border: `1px solid ${theme.palette.gray[700]}`,
        [theme.breakpoints.down('md834')]: {
          maxWidth: '100%',
          gap: '16px',
        },
      }}
    >
      {!isMd834 && (
        <Box
          sx={{
            width: '16px',
            position: 'absolute',
            top: -1,
            bottom: -1,
            left: -21,
            background: `${theme.palette.background.linesFooter}`,
          }}
        />
      )}

      {mockData.map((item, index) => (
        <StakingSumItem
          key={`keyId-${index}`}
          title={item.title}
          description={item.description}
          TitleTypographyProps={{
            sx: {
              [theme.breakpoints.down('md720')]: {
                mb: '3px',
              },
            },
          }}
          DescriptionTypographyProps={{ variant: isMd720 ? 'description' : 'paragraphSmall' }}
          icon={item.icon}
          StackProps={{
            sx: {
              [theme.breakpoints.down('md720')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px',
              },
            },
          }}
          IconBoxProps={{
            sx: {
              [theme.breakpoints.down('md720')]: {
                width: '28px',
                height: '28px',
                '& > *': {
                  width: '12px',
                  height: '12px',
                },
              },
            },
          }}
        />
      ))}
    </Stack>
  )
}

export default StakingSumsWrapper
