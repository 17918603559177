import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Stack, SwipeableDrawer } from '@mui/material'
import Comment from 'src/components/Comment/Comment'
import MenuItem from 'src/components/MenuItem/MenuItem'
import ExchangeRates from 'src/components/ExchangeRates/ExchangeRates'
import TokenControls from 'src/components/TokenControls/TokenControls'
import Button from 'src/components/Button/Button'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import MobileLegalLinks from 'src/components/MobileLegalLinks/MobileLegalLinks'
import MobileSocialLinks from 'src/components/MobileSocialLinks/MobileSocialLinks'
import { getHeaderLinks, getProfileHeaderLinks } from 'src/routes'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ExchangeRateTicker } from 'src/components/Ticker/ExchangeRateTicker'
import { TokenType, UserType } from 'src/helpers/types'
import { BrowserClient, feedbackIntegration, getClient } from "@sentry/react";
import { EnvironmentType, config } from 'src/config/config'
import { formatTokenToStringWithSymbol } from 'src/utils/tokenUtil'

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

const drawerWidth = 375

type DrawerMenuContentProps = {
  open?: boolean
  isWalletConnected?: boolean
  onLogout?: (event: React.KeyboardEvent | React.MouseEvent) => void
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void
  onOpen: (event: React.KeyboardEvent | React.MouseEvent) => void
  onConnect:  () => void
  user: UserType,
  stakingMenu: boolean

}

export default function Drawer({
  open,
  onClose,
  onOpen,
  isWalletConnected,
  onLogout,
  onConnect,
  user,
  stakingMenu

}: DrawerMenuContentProps) {
  const [headerLinks, setHeaderLinks] = useState(() => getHeaderLinks(stakingMenu))

  // Use useEffect to update headerLinks when stakingMenu changes
  useEffect(() => {
    setHeaderLinks(getHeaderLinks(stakingMenu));
  }, [stakingMenu]); 
  
  //This is only run once when the component is mounted. To update the headerLinks when stakingMenu changes, use useEffect
  const [profileHeaderLinks] = useState(() => getProfileHeaderLinks())

  const theme = useTheme()
  const isLessThan834 = useMediaQuery(theme.breakpoints.down('md834'))
  const isLessThan720 = useMediaQuery(theme.breakpoints.down('md720'))
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const client = getClient<BrowserClient>();
  const feedback = feedbackIntegration(client);
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        height: `calc(100% - ${
          (!isWalletConnected && isLessThan834) || (isWalletConnected && isLessThan720)
            ? '60px'
            : '84px'
        })`,
        top: 'unset',
        width: isSmView ? '100%' : drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isSmView ? '100%' : drawerWidth,
          boxSizing: 'border-box',
          bgcolor: 'gray.900',
          backgroundImage: 'none',
          height: `calc(100% - ${
            (!isWalletConnected && isLessThan834) || (isWalletConnected && isLessThan720)
              ? '60px'
              : '84px'
          })`,
          top: 'unset',
          bottom: 0,
        },
        '& .MuiBackdrop-root': {
          height: `calc(100% - ${
            (!isWalletConnected && isLessThan834) || (isWalletConnected && isLessThan720)
              ? '60px'
              : '84px'
          })`,
          top: 'unset',
          backgroundColor: 'rgba(4, 8, 17, 0.84)',
        },
      }}
    >
      <Box
        sx={{ p: '8px', pt: 0, pb: '6px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        <Comment>Menu</Comment>
        <Box
          mt="2px"
          border="1px solid"
          borderColor="gray.700"
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <Stack
            direction="column"
            flexGrow={1}
            spacing="18px"
            bgcolor="gray.800"
            m="1px"
            p="12px"
            minHeight={isWalletConnected ? '195px' : '455px'}
          >
            {/* @ts-ignore */}
            {headerLinks.map(({ path = '#', label, disabled }) => (
              <MenuItem key={path} href={path} disabled={disabled} onClick={onClose}>
                {label}
              </MenuItem>
            ))}
          </Stack>
          {isWalletConnected && (
            <Stack
              direction="column"
              spacing="18px"
              bgcolor="gray.800"
              m="1px"
              mt="2px"
              p="12px"
              minHeight="258px"
            >
              {/* @ts-ignore */}
              {profileHeaderLinks.map(({ path = '#', label, disabled }) => (
                <MenuItem key={path} href={path} disabled={disabled} onClick={onClose}>
                  {label}
                </MenuItem>
              ))}
              <MenuItem href="/" onClick={onLogout}>
                Logout
              </MenuItem>
            </Stack>
          )}
          <Stack direction="column" spacing="12px" bgcolor="gray.800" m="1px" mt="2px" p="12px">
           <ExchangeRateTicker  ExchangeRateProps={{
                BoxProps: {
                  bgcolor: 'gray.700',
                },
              }}/>
            { config.environment!==EnvironmentType.PRODUCTION && feedback &&
              <Button 
                onClick={async () => {
                  const form = await feedback.createForm({});
                  form.appendToDom();
                  form.open();
                }}>
                  report a bug
              </Button>
            }
            {isWalletConnected ? (
              <TokenControls
                isMobile
                isBuyDisabled={true}
                isWithDrawDisabled={true}
                TotalTokensProps={{
                  total: formatTokenToStringWithSymbol(user.coins?.weth,TokenType.WETH),
                }}
              />
            ) : (
              <Button  onClick={ onConnect } endIcon={<SvgIcon name="wallet-line" />}>Connect wallet</Button>
            )}
            <Box p="10px" pt="37px">
              <MobileLegalLinks />
              <Box mt="22px">
                <MobileSocialLinks />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}
