export const ChallengeStatus = {
    NOT_STARTED: 'NOT_STARTED',
    READY_TO_START: 'READY_TO_START',
    RUNNING: 'RUNNING',
    PRE_COOLDOWN_DONE: 'PRE_COOLDOWN_DONE',
    PLAY_TIME_OVER: 'PLAY_TIME_OVER',
    FINAL_DONE: 'FINAL_DONE',
    CANCELLED: 'CANCELLED',
}

export const ActiveChallengeStatus = {
    NOT_LOADED: 'NOT_LOADED',
    LOADED: 'LOADED',
    NOT_FOUND: 'NOT_FOUND',
}

export const ChallengeEventType = {
    CREATED: 'CREATED',
    JOINED: 'JOINED',
    LEFT: 'LEFT',
    QUEUED_FOR_START: 'QUEUED_FOR_START',
    GAME_ROUND_COMPLETE: 'GAME_ROUND_COMPLETE',
    GAME_STARTED: 'GAME_STARTED',
    GAME_FINISHED: 'GAME_FINISHED',
    GAME_ERROR: 'GAME_ERROR',
    PLAYER_ERROR: 'PLAYER_ERROR',
    PLAY_TIME_OVER: 'PLAY_TIME_OVER',
    CHALLENGE_PAYOUT: 'CHALLENGE_PAYOUT',
    REFUNDED_CHALLENGE_FEE: 'REFUNDED_CHALLENGE_FEE'
}

export const TournamentStatus = {
    NOT_STARTED: 'NOT_STARTED',
    QUALIFIER_RUNNING: 'QUALIFIER_RUNNING',
    QUALIFIER_DONE: 'QUALIFIER_DONE',
    FINAL_RUNNING: 'FINAL_RUNNING',
    FINAL_DONE: 'FINAL_DONE',
}

export const ParticipantStatus = {
    JOINED: 'JOINED',
    PLAYING: 'PLAYING',
    DONE: 'DONE',
}


export const TransactionType = {
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL',
    PAYED_CHALLENGE_FEE: 'PAYED_CHALLENGE_FEE',
    REFUNDED_CHALLENGE_FEE: 'REFUNDED_CHALLENGE_FEE',
    CHALLENGE_PAYOUT: 'CHALLENGE_PAYOUT',
    COMMISSION: 'COMMISSION',
    PROMOTION: 'PROMOTION',
    LEADERBOARD_PAYOUT: 'LEADERBOARD_PAYOUT',
    DISCOUNT_CODE: 'DISCOUNT_CODE',
}

export enum GameId  {
    FORTNITE= 'FORTNITE',
    FRONTLAND= 'FRONTLAND'
}

export enum GameType  {
    SOLO= 'SOLO',
    DUO= 'DUO',
    ONE_VS_ONE= 'ONE_VS_ONE',
}

export enum GameMode  {
    GENERAL = 'GENERAL',
    NO_BUILD ='NO_BUILD',
    BUILD = 'BUILD',
    ROOF_TOP = 'ROOF_TOP',
    ROOF_TOP_2 = 'ROOF_TOP_2',
    BRIDGE = 'BRIDGE',
    HACIENDA = 'HACIENDA',
    MAP_3 = 'MAP_3',
    MAP_4 = 'MAP_4',
    MAP_5 = 'MAP_5',
    MAP_6 = 'MAP_6',
}

export const ChallengeType = {
    FREE: 'FREE',
    MINI_MASH: 'MINI_MASH',
    BIG_BUCKS: 'BIG_BUCKS',
    MAJOR_MAYHEM: 'MAJOR_MAYHEM',
    SPECIAL_1: 'SPECIAL_1',
    SPECIAL_2: 'SPECIAL_2',
    QUALIFIER: 'QUALIFIER',
    FINAL: 'FINAL'
}

export const WithdrawalStatus = {
    REQUESTED: 'REQUESTED',
    COMPLETE: 'COMPLETE',
    DENIED: 'DENIED',
    FAILED: 'FAILED'
}


export const LeaderboardWinnerType = {
    DAILY_SCORE: 'DAILY_SCORE',
    WEEKLY_SCORE: 'WEEKLY_SCORE',
    MONTHLY_SCORE: 'MONTHLY_SCORE'
}

export const DiscountCodeStatus = {
    CREATED : 'CREATED',
    DISTRIBUTED : 'DISTRIBUTED',
    USED : 'USED',
    REVOKED : 'REVOKED'
}

export const PayoutType = {
    CASH : 'CASH',
    MERCHANDISE: 'MERCHANDISE'
}


export const BlockchainEventType = {
    DEPOSIT: 'DEPOSIT',
    CREATE_CHALLENGE:'CREATE_CHALLENGE',
    JOIN_CHALLENGE:'JOIN_CHALLENGE',
    LEAVE_CHALLENGE: 'LEAVE_CHALLENGE',
    START_CHALLENGE: 'START_CHALLENGE'
}

export const BlockchainEventStatus = {
    MINED: 'MINED',
    PROCESSING_SUCCEEDED: 'PROCESSING_SUCCEEDED',
    PROCESSING_FAILED: 'PROCESSING_FAILED'
}

