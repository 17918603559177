import React, { useState } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import Button from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'
import Typography from '../Typography/Typography'
import TextField from '../TextField/TextField'
import Comment from 'src/components/Comment/Comment'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { debounce } from "lodash";
import * as schemas from 'src/helpers/schemas'
import { config } from "../../config/config";
import { authToken } from "../../utils/authHeader";
import { setCredentials } from "../../sagas/apiSagas";
import {useAppDispatch} from "src/hooks";
import { registrationResult } from "src/actions/userActions";
import { showErrorToast } from "src/components/Toast/Toasts";
import { showErrorPopup } from "src/actions/dialogActions";
import { Avatar } from '../SelectAvatar/SelectAvatar'
import { clearHttpHttpsAndDomain } from 'src/helpers/commonData'

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000;

const ValidationSchema = yup.object().shape({
  username: schemas.username(),
  newPassword: schemas.requiredPassword(),
  repeatPassword: schemas.repeatPassword(),
});

const validationFunction = async (value: any, resolve: (arg0: boolean) => void) => {
    try {
        const token = authToken();
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', `application/json`);
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: headers
        };

        const response = await fetch(`${config.url.BASE_URL}/api/users/${value}/available`, requestOptions)
        if (response.status === 200) {
            resolve(true);
        }
        else {
            resolve(false);
        }
    } catch (error) {
        resolve(false);
    }
};
const validationDebounced = debounce(validationFunction, ASYNC_VALIDATION_TIMEOUT_IN_MS);


export default function RegistrationForm({ selectedAvatar }: {selectedAvatar?: Avatar}) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [usernameOk, setUsernameOk] = useState(true);

    const formik = useFormik({
        initialValues: {
            username: '',
            newPassword: '',
            repeatPassword: ''
        },
        validateOnChange: true,
        validateOnMount: true,
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
          formik.setStatus('');  
          setCredentials(values.username,values.newPassword,clearHttpHttpsAndDomain(selectedAvatar?.src))
          .then(result => {
            dispatch(registrationResult(result));
        })
        .catch((error) => {
            dispatch(showErrorPopup('Sign up error', error.message));
            //showErrorToast('Sign up error', error.message);
            //formik.setStatus(error.message);
        })
        .finally(() => formik.setSubmitting(false))
        }
    });

    const onKeyUpValue = (event: React.KeyboardEvent<HTMLInputElement>, formik: any) => {
        var username = (event.target as HTMLInputElement).value;
        if (username === undefined || username.length < 3) {
            setUsernameOk(true);
            return;
        }             
        const result = new Promise(resolve => validationDebounced(username, resolve))
        result.then(val => {
                if (val) {
                    setUsernameOk(true);
                }
                else {
                    setUsernameOk(false);

                }
            })
            .catch(error => {
                setUsernameOk(true);
            });
        }

  return (
    <Box>
      <Comment>Registration</Comment>
      <Stack
          direction="column"
          sx={{
            border: `1px solid ${theme.palette.gray[700]}`,
            padding: '1px',            
          }}
        >
         <form onSubmit={formik.handleSubmit}>

        <Stack
          direction="column"
          gap="5px"
          sx={{
            background: theme.palette.gray[800],
            padding: { xs: '18px 16px 28px', sm: '25px 23px 35px' },
            marginBottom: '2px',
          }}
        >
          <Typography
            variant="bodySmall"
            align="center"
            color={theme.palette.gray[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
              [theme.breakpoints.down('sm')]: {
                m: '0 auto 18px auto',
                width: '100%',
              },
            }}
          >
            First thing, create your Login and Password, this Login will be used later on to login
            in game Frontland and as your Nickname on Challenge.gg platform
          </Typography>
	        <Typography  variant="bodyMedium"
            align="center"
            color={theme.palette.error[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
            }}
            style={{color:'red'}}>
              
              {formik.status}
          </Typography>
         
                  <TextField id="username" name="username" placeholder="Enter your login" label="Login" size="large"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          onKeyUp={(val: React.KeyboardEvent<HTMLInputElement>) => onKeyUpValue(val, formik)}
                          error={(formik.touched.username && Boolean(formik.errors.username)) || !usernameOk}
                          helperText={!usernameOk ? 'Username is already taken' : formik.touched.username && formik.errors.username !== undefined ? formik.errors.username : undefined }
                      />
                  <Box
                    mt={{ xs: '2px', sm: '4px' }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                  <TextField id="newPassword" name="newPassword" placeholder="Enter password" label="Password" size="large" type="password"
                              value={formik.values.newPassword}
                              onChange={formik.handleChange}
                              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                              helperText={formik.touched.newPassword && formik.errors.newPassword !== undefined ? formik.errors.newPassword : undefined}
                          />
                  </Box>
                   <TextField id="repeatPassword" name="repeatPassword" placeholder="Repeat password" size="large" type="password"
                          value={formik.values.repeatPassword}
                          onChange={formik.handleChange}
                          error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                          helperText={formik.touched.repeatPassword && formik.errors.repeatPassword !== undefined ? formik.errors.repeatPassword : undefined}
                      />

                  <Typography
                    variant="description"
                    align="center"
                    maxWidth={{ xs: '255px', sm: '270px' }}
                    mx="auto"
                    color={theme.palette.gray[200]}
                  >
                    Password must contain minimum 6 symbols.
                  </Typography>
                </Stack>
                  <Button variant="spaceBetween" size="large" endIcon={<SvgIcon name="arrow-right-line" sx={{ mr: '4px' }} />} type="submit" disabled={!formik.dirty || formik.isSubmitting || !usernameOk} >
                   <Typography variant="buttonMassive">Sign up</Typography>
                </Button>
                </form>
            </Stack>        
    </Box>
  )
}
