import { Stack, StackProps as MuiStackProps, useTheme } from '@mui/material'
import React from 'react'
import TitleWithDot from '../TitleWithDot/TitleWithDot'
import StakingBlockTextItem from '../StakingBlockTextItem/StakingBlockTextItem'
import LabelWithValue from '../LabelWithValue/LabelWithValue'
import TimerStaking from '../TimerStaking/TimerStaking'
import Typography from '../Typography/Typography'
import CoinImageWithAmount from '../CoinImageWithAmount/CoinImageWithAmount'
import { PNG_ICONS } from 'src/assets/icons/icons'

enum valueVariantTop {
  Timer = 'timer',
  Coin = 'coin',
  Eth = 'eth',
  ReactNode = 'ReactNode',
}
enum valueVariantBottom {
  Default = 'default',
  ReactNode = 'ReactNode',
}

type BlockItemTop = {
  label: string
  status?: { title: string; iconName: any }
  value: {
    type: `${valueVariantTop}`
    val: React.ReactNode | string | number
    placeholder?: string
  }
}

type BlockItemBottom = {
  icon?: React.ReactNode
  label: string
  value: {
    type: `${valueVariantBottom}`
    val: React.ReactNode | string | number
    suffix?: string
    placeholder?: string
  }
}

export type BlockItemData = {
  top: BlockItemTop[]
  bottom: BlockItemBottom[]
}

type StakingBlockItemProps = {
  title?: string
  bottomTitle?: string
  StackProps?: MuiStackProps
  TopMainStackProps?: MuiStackProps
  BottomStackProps?: MuiStackProps
  TopStackProps?: MuiStackProps
  data: BlockItemData
}

function StakingBlockItem({
  title,
  bottomTitle,
  StackProps,
  TopMainStackProps,
  BottomStackProps,
  TopStackProps,
  data,
}: StakingBlockItemProps) {
  const theme = useTheme()
  const issetBottomValues = !data.bottom.every((item) => item.value.val === false)

  return (
    <Stack
      width="50%"
      {...StackProps}
      sx={{ border: `1px solid ${theme.palette.gray[700]}`, ...StackProps?.sx }}
    >
      <Stack
        flexGrow={1}
        {...TopMainStackProps}
        sx={{
          p: '17px 28px 17px 17px',
          [theme.breakpoints.down('lg2')]: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          [theme.breakpoints.down('md834')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
          [theme.breakpoints.down('sm2')]: {
            p: '12px 22px 11px 11px',
            gap: '11px',
          },
          ...TopMainStackProps?.sx,
        }}
      >
        {title && (
          <TitleWithDot
            typographyProps={{ variant: 'h7' }}
            dotProps={{ bottom: 5, width: 4, height: 4 }}
            rootStackProps={{
              spacing: '6px',
              sx: {
                mb: '15px',
                [theme.breakpoints.down('lg2')]: {
                  width: '147px',
                  mb: 0,
                },
              },
            }}
          >
            {title}
          </TitleWithDot>
        )}

        <Stack
          direction="row"
          gap={1}
          alignItems="stretch"
          justifyContent="space-between"
          minHeight="64px"
          {...TopStackProps}
          sx={{
            [theme.breakpoints.down('lg2')]: {
              flexGrow: 1,
            },
            [theme.breakpoints.down('md834')]: {
              flexWrap: 'wrap',
              width: '100%',
            },
            ...TopStackProps?.sx,
          }}
        >
          {data.top?.map(({ label, status, value }) => (
            <StakingBlockTextItem
              key={label}
              TitleProps={{
                title: label,
                StackProps: {
                  sx: {
                    [theme.breakpoints.down('md2')]: {
                      gap: '8px',
                    },
                  },
                },
                ...(status && {
                  Status: {
                    title: status.title,
                    SvgIconWithTitleProps: { SvgIconProps: { name: status.iconName } },
                  },
                }),
              }}
              StackProps={{
                sx: {
                  [theme.breakpoints.down('md720')]: {
                    '&:first-of-type': { width: '100%', mb: '5px', gap: '5px' },
                  },
                },
              }}
            >
              {value.type === valueVariantTop.Timer &&
                (typeof value.val === 'number' ? (
                  <TimerStaking expirySeconds={value.val} onExpire={() => {}} showSeconds={false} showZeroTime={{zeroDay:true,zeroHrs:true,zeroMin:true}} />
                ) : (
                  <Typography variant="h7" color="gray.600">
                    {value.placeholder ? value.placeholder : '-'}
                  </Typography>
                ))}
              {value.type === valueVariantTop.Eth &&
                (typeof value.val === 'number' || typeof value.val === 'string' ? (
                  <CoinImageWithAmount
                    spacing="8px"
                    avatarProps={{
                      src: PNG_ICONS.weth,
                      sx: { width: '18px', height: '18px' },
                    }}
                    amount={`${value.val}`}
                    amountTypographyProps={{
                      variant: 'h6',
                      color: 'gray.0',
                    }}
                  />
                ) : (
                  <Typography variant="h6" color="gray.600">
                    {value.placeholder ? value.placeholder : '-'}
                  </Typography>
                ))}
              {value.type === valueVariantTop.Coin &&
                (typeof value.val === 'number' || typeof value.val === 'string' ? (
                  <CoinImageWithAmount
                    
                    spacing="8px"
                    avatarProps={{ sx: { width: '18px', height: '18px' }, src: PNG_ICONS.coin}}
                    amount={`${value.val}`}
                    amountTypographyProps={{
                      variant: 'h6',
                      color: 'gray.0',
                    }}
                  />
                ) : (
                  <CoinImageWithAmount
                    spacing="8px"
                    avatarProps={{ sx: { width: '18px', height: '18px' }, src: PNG_ICONS.coin }}
                    amountTypographyProps={{
                      variant: 'h6',
                      color: 'gray.0',
                      children: (
                        <Typography color="gray.700" variant="h6">
                          {value.placeholder ? value.placeholder : '-'}
                        </Typography>
                      ),
                    }}
                  />
                ))}
              {value.type === valueVariantTop.ReactNode && value.val}
            </StakingBlockTextItem>
          ))}
        </Stack>
      </Stack>
      <Stack
        width="100%"
        direction="row"
        flexWrap="wrap"
        {...BottomStackProps}
        sx={{
          p: '6px 6px 5px 25px',
          minHeight: '73px',
          gap: 2,
          borderTop: `1px solid ${theme.palette.gray[700]}`,
          ...BottomStackProps?.sx,
          background: issetBottomValues
            ? `${theme.palette.gray[800]}`
            : `${theme.palette.background.linesFooter}`,
          [theme.breakpoints.down('lg2')]: {
            pl: '17px',
          },
          [theme.breakpoints.down('md720')]: {
            gap: '10px 0',
            p: '11px 11px 9px 11px',
          },
        }}
      >
        {bottomTitle && (
          <TitleWithDot
            typographyProps={{
              variant: 'h7',
              ...(!issetBottomValues && { color: theme.palette.gray[500] }),
            }}
            dotProps={{
              bottom: 5,
              width: 4,
              height: 4,
              ...(!issetBottomValues && { bgcolor: theme.palette.gray[600] }),
            }}
            rootStackProps={{
              spacing: '6px',
              mb: 0,
              alignSelf: 'center',
              width: '21.8%',
              sx: {
                [theme.breakpoints.down('md2')]: {
                  width: '37%',
                },
                [theme.breakpoints.down('sm2')]: {
                  width: '30%',
                },
                [theme.breakpoints.down('md720')]: {
                  width: '50%',
                  alignSelf: 'flex-start',
                },
              },
            }}
          >
            {bottomTitle}
          </TitleWithDot>
        )}
        {data.bottom?.map(({ label, value, icon }) => (
          <LabelWithValue
            key={label}
            StackProps={{
              flexGrow: 1,
              justifyContent: 'center',
              gap: '6px',
              sx: {
                '&:nth-of-type(2)': { maxWidth: '108px' },
                '&:nth-of-type(3)': { maxWidth: '158px' },// 138px Change to 158px FH 240517
                [theme.breakpoints.down('lg2')]: {
                  '&:nth-of-type(2)': { maxWidth: '15%' },
                  '&:nth-of-type(3)': { maxWidth: '18.4%' },
                },
                [theme.breakpoints.down('sm2')]: {
                  '&:nth-of-type(2)': { maxWidth: '17%' },
                  '&:nth-of-type(3)': { maxWidth: '24%' },
                },
                [theme.breakpoints.down('md720')]: {
                  width: '50%',
                  maxWidth: '50% !important',
                },
              },
            }}
            MediaWithTitleProps={{
              StackProps: { gap: '4px' },
              icon,
              TypographyProps: {
                variant: 'paragraphSmall',
                children: label,
                sx: {},
              },
            }}
          >
            <Stack direction="row" alignItems="baseline" ml="24px" gap="4px">
              {value.val || value.suffix ? (
                <>
                  {value.val && (
                    <Typography variant="h7" color="gray.0">
                      {value.val}
                    </Typography>
                  )}

                  {value.suffix && (
                    <Typography variant="bodySmall" color="gray.50">
                      {value.suffix}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="h7" color="gray.600">
                    {value.placeholder || '-'}
                  </Typography>
                </>
              )}
            </Stack>
          </LabelWithValue>
        ))}
      </Stack>
    </Stack>
  )
}

export default StakingBlockItem
