import React from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import TextField, { TextFieldProps } from 'src/components/TextField/TextField'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

export type PasswordFieldProps = TextFieldProps

export default function PasswordField({
  id,
  name,
  endAdornment,
  disabled,
  ...restTextFieldProps
}: PasswordFieldProps) {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <TextField
      id={id || name}
      name={name}
      disabled={disabled}
      endAdornment={
        restTextFieldProps.value
          ? endAdornment || (
              <InputAdornment position="end">
                <IconButton
                  // disabled={disabled}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <SvgIcon name={showPassword ? 'eye-close-line' : 'eye-line'} />
                </IconButton>
              </InputAdornment>
            )
          : null
      }
      {...restTextFieldProps}
      type={showPassword ? 'text' : 'password'}
    />
  )
}
