import { Box } from '@mui/material'
import React from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'
import Typography from '../Typography/Typography'
import Image from '../Image/Image'
import MenuButton from '../MenuButton/MenuButton'

export type HeaderTokensProps = {
  tokenCount?: string
  tokenImgSrc: string
}

const HeaderTokens = (props: HeaderTokensProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: '12px',
        height: '51px',
        mt: '4px',
        gridTemplateColumns: 'max-content 44px',
      }}
    >
      {/* Left block */}

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          pr: '12px',
          position: 'relative',
          borderBottom: `1px solid ${theme.palette.gray[700]}`,
          '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: '-4px',
            width: '37px',
            height: '4px',
            background: theme.palette.gray[700],
          },
        })}
      >
        <Typography
          variant="uiElement"
          color="gray.700"
          sx={{ display: 'flex', gap: '8px', alignItems: 'flex-end' }}
        >
          {'//'} <Box color="gray.500">Your tokens</Box>
        </Typography>
        <Typography
          variant="paragraphSmall"
          sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          <Image src={props.tokenImgSrc} width={18} height={18} alt="coins" />{' '}
          {/* {(+(props.tokenCount || 0)).toLocaleString()} */}
          {props.tokenCount || 0}
        </Typography>
      </Box>
      {/* Right block */}
      <Box
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.gray[700]}`,
        })}
      >
        <MenuButton
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          rows={[
            {
              text: 'Buy coins',
              iconName: 'handle-give',
              onClick: () => {},
              disabled: true,
            },
            {
              text: 'Withdraw',
              iconName: 'arrow-share',
              onClick: () => {},
              disabled: true,
            },
          ]}
          buttonProps={{
            variant: 'outlined',
            size: 'small',
            sx: {
              width: '44px',
              minWidth: '44px',
              height: '44px',
              padding: '0',
            },
            children: <SvgIcon name="snap-line" />,
          }}
        />
      </Box>
    </Box>
  )
}

export default HeaderTokens
