import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'
import { ChallengeStatus } from "src/utils/const";



// Helper function to get a challenge by its ID
const getChallengeById = (state: RootState, challengeId: number) => {
    return state.challengeReducer.activeChallenges.find((x: any) => x.id === challengeId);
}

const selectedStatus = (state: RootState, challengeId: number) => {
    const challenge = getChallengeById(state, challengeId);
    return challenge ? challenge.status : undefined;
}

const selectedParticipants = (state: RootState, challengeId: number) => {
    const challenge = getChallengeById(state, challengeId);
    return challenge ? challenge.participants : [];
}

const selectedChallenge = getChallengeById;


export const getSortedParticipants = createSelector(
    [selectedChallenge, selectedParticipants, selectedStatus],
    (challenge, participants, status) => {
        //When FINAL_DONE we get sorted list from backend for Fortnite, Frontland we do not sort
        // Only sort participants for specific statuses
        if ([ChallengeStatus.PLAY_TIME_OVER, ChallengeStatus.PRE_COOLDOWN_DONE].includes(status)) {
            const sorted = [...participants].sort((a: any, b: any) => {
                const aScore = a.score || 0;
                const bScore = b.score || 0;
                return bScore - aScore;
            });

            return {
                ...challenge,
                participants: sorted,   
            };
        }       
        return challenge;        

    }
  )