import { RiGift2Line, RiPercentLine, RiSafeLine } from "@remixicon/react";
import { BlockItemT } from "src/components/StakingBlocksWrapper/StakingBlocksWrapper";
import { FlexiStakingType, VestedStakingType } from "./stakingTypes";
import { TokenType } from "./types";
import { calculateTimeDifferenceInSeconds, formatUnixTimestampToDateTime, isTimestamp, isTimestampGreaterThanCurrentLocalTime } from "src/utils/datetime";
import { formatTokenToString, formatTokenToStringWithSeparator, toToken } from "src/utils/tokenUtil";


export const emptyStakingData = (title: string, color:string)=> {
    return {
        title: title,
        bottomTitle: 'Your stake',
        data: {
        top: [
            {
            label: 'Reward period',
            status: { title: '', iconName: 'calendar-event-fill' },
            value: { type: 'timer', val: '' },
            },
            {
            label: 'Period Rewards',
            value: {
                type: 'eth',
                val: '',
            },
            },
            {
            label: 'Total Staked',
            status: { title: 'Users', iconName: 'team-fill' },
            value: {
                type: 'coin',
                val: '',
            },
            },
        ],
        bottom: [
            {
            label: 'APY',
            value: { type: 'default', val: '' },
            icon: <RiPercentLine size={18} color={color} />,
            },
            {
            label: 'Potential Reward',
            value: { type: 'default', val: '', suffix: 'ETH' },
            icon: <RiGift2Line size={18} color={color} />,
            },
            {
            label: 'You Staked',
            value: { type: 'default', val: '', suffix: 'CT' },
            icon: <RiSafeLine size={18} color={color} />,
            },
        ],
        },  
    } as BlockItemT
}

export const flexiStakingData = (flexiStaking: FlexiStakingType , color:string)=> {
    
    const rewardPeriodRunningOrFinished = isTimestamp(flexiStaking.currentPeriodFinish);
    const rewardPeriodRunning = rewardPeriodRunningOrFinished ? isTimestampGreaterThanCurrentLocalTime(flexiStaking.currentPeriodFinish) :false;
    const endsIn = rewardPeriodRunningOrFinished ? formatUnixTimestampToDateTime(flexiStaking.currentPeriodFinish) : '';
    const accountBalance = flexiStaking.accountBalance ? BigInt(flexiStaking.accountBalance) : BigInt(0);
    const youStaked = accountBalance > BigInt(0);
    const timeInSecondsToPeriodEnd = rewardPeriodRunning ? calculateTimeDifferenceInSeconds(Number(flexiStaking.currentPeriodFinish)) : 0;

    const potentialReward = youStaked ? calculatePotentialReward(BigInt(flexiStaking.totalStaked), accountBalance, BigInt(flexiStaking.rewardForDuration),BigInt(flexiStaking.currentRewardsDuration),Number(flexiStaking.currentPeriodFinish), BigInt(flexiStaking.accountEarned)) : 0n;
    const potentialRewardInPercentage = youStaked ? caclulatePotentialRewardInPercentage(flexiStaking.rewardForDuration, potentialReward) : false;

    return {
        title: 'Flexi staking',
        bottomTitle: 'Your stake',
        data: {
          top: [
            {
              label: 'Reward period',
              status: { title: endsIn, iconName: 'calendar-event-fill' },
              value: {
                type: 'timer',
                val:  rewardPeriodRunningOrFinished ? timeInSecondsToPeriodEnd : false, 
                placeholder: rewardPeriodRunningOrFinished ? undefined : 'Period not started',
              },
            },
            {
              label: 'Period Rewards',
              value: {
                type: 'coin', //eth
                val: rewardPeriodRunningOrFinished ? formatTokenToString(flexiStaking.rewardForDuration,TokenType.CT,6,false,true,true) : false,
              },
            },
            {
              label: 'Total Staked',
              status: { title: `${flexiStaking.stakerCount} Users`, iconName: 'team-fill' },
              value: {
                type: 'coin',
                val: `${formatTokenToString(flexiStaking.totalStaked,TokenType.CT,2,false,true,true)}`,
              },
            },
          ],
          bottom: [
            {
              label: 'Reward',
              value: { type: 'default', val: potentialRewardInPercentage}, //TODO: calculate APY //'16.7%' 
              icon: <RiPercentLine size={18} color={color} />,
            },
            {
              label: 'Potential Reward',
              value: { 
                type: 'default', 
                val: youStaked ? formatTokenToStringWithSeparator(potentialReward, 4, TokenType.CT) : false,
                suffix: youStaked ? TokenType.CT : undefined,
              },
              icon: <RiGift2Line size={18} color={color} />,
            },
            {
              label: 'You Staked',
              value: { type: 'default', val: youStaked ? formatTokenToStringWithSeparator(accountBalance,4,TokenType.CT) : false, 
                       placeholder: youStaked ? undefined : 'Not Staked', suffix: youStaked ? 'CT': undefined },
              icon: <RiSafeLine size={18} color={color} />,
            },
          ],
        },
      } as BlockItemT
}

export const vestedStakingData = (vestedStaking:VestedStakingType, color:string)=> {
    const rewardPeriodRunningOrFinished = isTimestamp(vestedStaking.currentPeriodFinish);
    const rewardPeriodRunning = rewardPeriodRunningOrFinished ? isTimestampGreaterThanCurrentLocalTime(vestedStaking.currentPeriodFinish) :false;
    const endsIn = rewardPeriodRunningOrFinished ? formatUnixTimestampToDateTime(vestedStaking.currentPeriodFinish) : '';
    const accountBalance = vestedStaking.accountBalance ? BigInt(vestedStaking.accountBalance) : BigInt(0);
    const youStaked = accountBalance > BigInt(0);
    const timeInSecondsToPeriodEnd = rewardPeriodRunning ? calculateTimeDifferenceInSeconds(Number(vestedStaking.currentPeriodFinish)) : 0;

    const potentialReward = youStaked ? calculatePotentialReward(BigInt(vestedStaking.totalStaked), accountBalance, BigInt(vestedStaking.rewardForDuration),BigInt(vestedStaking.currentRewardsDuration),Number(vestedStaking.currentPeriodFinish), BigInt(vestedStaking.accountEarned)) : 0n;
    const potentialRewardInPercentage = youStaked ? caclulatePotentialRewardInPercentage(vestedStaking.rewardForDuration, potentialReward) : false;

  

    return {
        title: 'Vested staking',
        bottomTitle: 'Your stake',
        data: {
          top: [
            {
              label: 'Reward period',
              status: { title: endsIn, iconName: 'calendar-event-fill' },
              value: {
                type: 'timer',
                val: rewardPeriodRunningOrFinished ? timeInSecondsToPeriodEnd : false,
                placeholder: rewardPeriodRunningOrFinished ? undefined : 'Period not started',
              },
            },
            {
              label: 'Period Rewards',
              value: {
                type: 'coin', //eth
                val: rewardPeriodRunningOrFinished ? formatTokenToString(vestedStaking.rewardForDuration,TokenType.CT,6,false,true,true) : false,
              },
            },
            {
              label: 'Total Staked',
              status: { title: `${vestedStaking.stakerCount} Users`, iconName: 'team-fill' },
              value: {
                type: 'coin',
                val: `${formatTokenToString(vestedStaking.totalStaked,TokenType.CT,2,false,true,true)}`,
              },
            },
          ],
          bottom: [
            {
              label: 'Reward',
              value: { type: 'default', val: potentialRewardInPercentage },
              icon: <RiPercentLine size={18} color={color} />,
            },
            {
              label: 'Potential Reward',
              value: {
                type: 'default',
                val: youStaked ? formatTokenToStringWithSeparator(potentialReward,4,TokenType.CT) : false,
                suffix: youStaked ? TokenType.CT : undefined,
              },
              icon: <RiGift2Line size={18} color={color} />,
            },
            {
              label: 'You Staked',
              value: { type: 'default', val: youStaked ? formatTokenToStringWithSeparator(accountBalance,4,TokenType.CT) : false, 
                       placeholder: youStaked ? undefined : 'Not Staked', suffix: youStaked ? 'CT': undefined},
              icon: <RiSafeLine size={18} color={color} />,
            },
          ],
        },
      } as BlockItemT
}

export const calculatePotentialReward = (totalStaked:bigint, myStaked:bigint,rewards:bigint, rewardDuration:bigint, currentPeriodFinish:number, accountEarned:bigint) : bigint => {

    const timeLeft = calculateTimeDifferenceInSeconds(currentPeriodFinish);

    if (totalStaked > 0n) {
        const potentialReward = (myStaked * rewards * BigInt(timeLeft)) / (totalStaked*rewardDuration) + accountEarned;
        return potentialReward;
    } else {
        return 0n;
    }
};

export const calculatePotentialRewardFromStake = (totalStaked:string|undefined, stakedByUser:string|undefined, myStaked:string,rewards:string|undefined, rewardDuration:string|undefined, currentPeriodFinish:string|undefined, accountEarned:string|undefined) : bigint => {

      if (currentPeriodFinish === undefined || totalStaked === undefined || stakedByUser==undefined || rewards === undefined || rewardDuration === undefined) {
        return 0n;
      }
      let myStakedBigInt=0n;
      if(myStaked!==""){
        myStakedBigInt=BigInt(toToken(myStaked+"").toString()) 
      }
      let accountEarnedBigInt=0n;
      if(accountEarned!==undefined){
        accountEarnedBigInt=BigInt(accountEarned) 
      }
      let totalStakedBigInt=BigInt(totalStaked) + myStakedBigInt;
      const rewardsBigInt=BigInt(rewards);
      const rewardDurationBigInt=BigInt(rewardDuration);

      const timeLeft = calculateTimeDifferenceInSeconds(Number(currentPeriodFinish));

      if (totalStakedBigInt > 0n) {
        const potentialReward = ((myStakedBigInt+ BigInt(stakedByUser) )* rewardsBigInt * BigInt(timeLeft)) / (totalStakedBigInt*rewardDurationBigInt) + accountEarnedBigInt;
        return potentialReward;
      } else {
          return 0n;
      }
 
      return 0n;
 
};

export const caclulatePotentialRewardInPercentage = (totalStaked:string|undefined, potentialReward:bigint|undefined) : string => {
    if (totalStaked === undefined || potentialReward === undefined) {
      return "0%";
    }
    
    const totalStakedBigInt=BigInt(totalStaked);
    if (totalStakedBigInt > 0n) {
        const percentage = ((Number(potentialReward) / Number(totalStakedBigInt)) * 100).toFixed(3) + "%";
        return percentage;
    } else {
        return "0%";
    }
}

export function getAvailableAmountToUnstakeNow(vestingSchedule: {
  periods: number[],
  amounts: string[],
  withdrawn: string,
  vestingType: number,
}): bigint {
  
  const { periods, amounts, withdrawn, vestingType } = vestingSchedule;

  // Assuming amounts and withdrawn are in string form with 18 decimals
  const DECIMALS = 18;
  
  const toBigNumber = (value: string) => {
    return BigInt(value);
  };

  const currentTimestamp = Math.floor(Date.now() / 1000);

  const withdrawnAmount = toBigNumber(withdrawn);
  let availableAmount = BigInt(0);

  for (let i = 0; i < periods.length; i++) {
    if (currentTimestamp >= periods[i]) {
      availableAmount += toBigNumber(amounts[i]);
    }
  }

  const finalAvailableAmount = availableAmount - withdrawnAmount;

  // Convert the available amount back to string
  return finalAvailableAmount;
}

export function getNextRelease(vestingSchedule: {
  periods: number[],
  amounts: string[],
  withdrawn: string,
  vestingType: number,
}): { formattedDate: string, amount: string, percentage: string } | null {
  
  const { periods, amounts } = vestingSchedule;

  // Get the current timestamp in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const toBigNumber = (value: string) => BigInt(value);

  // Calculate the total vested amount
  let totalVestedAmount = BigInt(0);
  for (const amount of amounts) {
    totalVestedAmount += toBigNumber(amount);
  }

  // Find the next release
  for (let i = 0; i < periods.length; i++) {
    if (currentTimestamp < periods[i]) {
      // Format the timestamp to local date-time string
      const releaseDate = new Date(periods[i] * 1000);
      const formattedDate = releaseDate.toLocaleString();

      // Get the amount and calculate the percentage
      const amount = toBigNumber(amounts[i]);
      const percentage = ((Number(amount) / Number(totalVestedAmount)) * 100).toFixed(1) + "%";

      return {
        formattedDate,
        amount: amounts[i],
        percentage
      };
    }
  }

  // No upcoming release found
  return null;
}

/**
 * Get formatted strings for the release amounts and dates in the vesting schedule.
 * @param vestingSchedule - The vesting schedule containing periods and amounts.
 * @returns An array of formatted strings indicating the release amounts and their corresponding dates.
 */
export function getFormattedReleaseDates(vestingSchedule: {
  periods: number[],
  amounts: string[],
  withdrawn: string,
  vestingType: number,
}): string[] {
  
  const { periods, amounts } = vestingSchedule;

  // Generate formatted strings
  const formattedStrings = periods.map((timestamp, index) => {
    const releaseDate = new Date(timestamp * 1000);
    const formattedDate = releaseDate.toLocaleString(); // Local date-time string
    const formattedAmount = formatTokenToStringWithSeparator(amounts[index], 6, TokenType.CT);

    return `${formattedAmount} CT, ${formattedDate}`;
  });

  //Insert at first element the text "total"
  formattedStrings.unshift(vestingType(vestingSchedule.vestingType));

  return formattedStrings;
}

const vestingType = (type: number) => {
  switch(type) {
      case 1:
          return 'Pre Seed Funding';
      case 2:
          return 'Private Presale';
      case 3:
          return 'Team and Advisors';
      case 4:
           return 'Public Vesting';
      default:
          return 'Unknown';
  }
}
