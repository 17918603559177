
export function subscribeToChallenges() {
    return { type: "SUBSCRIBE_CHALLENGES" };
}

export function lobbyInfo(challenge, userId) {
    return { type: "LOBBY_INFO", challenge: challenge, userId:userId };
}

export function lobbiesInfo(challenges, userId) {
    return { type: "LOBBIES_INFO", challenges: challenges, userId: userId };
}

export function lobbyNotFound() {
    return { type: "LOBBY_NOT_FOUND"};
}

export function availableChallenges(challenges) {
    return { type: "AVAILABLE_CHALLANGES", challenges: challenges };
}

export function availableSpecialChallenges(challenges) {
    return { type: "AVAILABLE_SPECIAL_CHALLANGES", challenges: challenges };
}

export function challengeUpdate(challengeUpdate, userId) {
    return { type: "CHALLENGE_UPDATE", challengeUpdate: challengeUpdate, userId: userId};
}

export function challengeRemoveWithDelay(id) {
    return { type: "CHALLENGE_REMOVE_WITH_DELAY", id: id };
}

export function challengeAdd(newChallenge) {
    return { type: "CHALLENGE_ADD", newChallenge: newChallenge };
}

export function goto(id) {
    return { type: "GO_TO", id: id };
}

export function signup(id, fee, tokenType, isTemplate, isSpecial, isTournament, tournamentId) {
    console.log("Signup to challenge with id=" + id);
    return { type: "CHALLENGE_SIGNUP", id: id, fee: fee, tokenType: tokenType, isTemplate: isTemplate, isSpecial: isSpecial, isTournament: isTournament,tournamentId: tournamentId };
}

export function leave(id , blockchainChallengeId, fee, type, isTournament) {
    console.log("Leave challenge with id=" + id + ", blockchainChallengeId=" + blockchainChallengeId);
    return { type: "CHALLENGE_LEAVE", id: id, blockchainChallengeId: blockchainChallengeId, fee: fee, challengeType: type, isTournament: isTournament };
}

export function closeLobby(id, returnTo) {
    console.log("Close current lobby");
    return { type: "CLOSE_LOBBY", id: id, returnTo: returnTo };
}

export function closeStreamerLobby(id) {
    console.log("Close streamer lobby");
    return { type: "CLOSE_STREAMER_LOBBY", id: id };
}


export function subscribeToLobby(id, isTournament) {
    console.log("Subscribe to lobby with id=" + id+ ", isTournament=" + isTournament);
    return { type: "SUBSCRIBE_TO_LOBBY", id: id, isTournament: isTournament };
}

export function requestSubscriptionToStreamerLobby(nick) {
    console.log("Request subscription to streamer lobby with nick=" + nick);
    return { type: "REQUEST_SUBSCRIPTION_TO_STREAMER_LOBBY", nick: nick };
}

export function subscribeToStreamerLobby(nick) {
    console.log("Subscribe to streamer lobby with nick=" + nick);
    return { type: "SUBSCRIBE_TO_STREAMER_LOBBY", nick: nick };
}

export function joinedChallenge(joinedChallenge) {
    return { type: "JOINED_CHALLENGE", joinedChallenge: joinedChallenge };
}

export function leftChallenge(leftChallenge) {
    return { type: "LEFT_CHALLENGE", leftChallenge: leftChallenge };
}

export function challengeEvent(challengeEvent) {
    return { type: "CHALLENGE_EVENT", challengeEvent: challengeEvent };
}

export function challengeRoundResultEvent(challengeRoundResultEvent) {
    return { type: "CHALLENGE_ROUND_RESULT_EVENT", challengeRoundResultEvent: challengeRoundResultEvent };
}

export function initGlobalEvents(globalEvents) {
    return { type: "INIT_GLOBAL_EVENTS", globalEvents: globalEvents };
}

export function globalEvents(globalEvents) {
    return { type: "GLOBAL_EVENTS", globalEvents: globalEvents };
}

export function challengeLogEvent(challengeLogEvent) {
    return { type: "CHALLENGE_LOG_EVENT", challengeLogEvent: challengeLogEvent };
}

export function challengeLogEvents(challengeLogEvents) {
    return { type: "CHALLENGE_LOG_EVENTS", challengeLogEvents: challengeLogEvents };
}

export function streamerChallengeEvent(challengeEvent) {
    return { type: "STREAMER_CHALLENGE_EVENT", challengeEvent: challengeEvent };
}

export function challengeParticipantEvent(challengeParticipantEvent) {
    return { type: "CHALLENGE_PARTICIPANT_EVENT", challengeParticipantEvent: challengeParticipantEvent };
}

export function streamerChallengeParticipantEvent(challengeParticipantEvent) {
    return { type: "STREAMER_CHALLENGE_PARTICIPANT_EVENT", challengeParticipantEvent: challengeParticipantEvent };
}

export function challengeResult(challengeResult) {
    return { type: "CHALLENGE_RESULT", challengeResult: challengeResult };
}

export function challengeRefund(challengeRefund) {
    return { type: "CHALLENGE_REFUND", challengeRefund: challengeRefund };
}

export function streamerChallengeResult(challengeResult) {
    return { type: "STREAMER_CHALLENGE_RESULT", challengeResult: challengeResult };
}

export function challengeError(error) { 
    return { type: "CHALLENGE_ERROR", error: error };
}

export function streamerChallengeError(error) {
    return { type: "STREAMER_CHALLENGE_ERROR", error: error };
}

export function challengeParticipantsError(error) {
    return { type: "CHALLENGE_PARTICIPANTS_ERROR", error: error };
}

export function streamerChallengeParticipantsError(error) {
    return { type: "STREAMER_CHALLENGE_PARTICIPANTS_ERROR", error: error };
}

export function getActiveChallenge() {
    return { type: "GET_ACTIVE_CHALLENGE"};
}

export function getFinishedChallenge(id) {
    return { type: "GET_FINISHED_CHALLENGE", id: id };
}