import { createSelector } from '@reduxjs/toolkit'
import { ActiveChallengeType } from 'src/helpers/types';
import type { RootState } from 'src/store/configureStore'


// Basic selector to get the onTimeChallenges from the state
const getOnTimeChallenges = (state: RootState, ) => state.tournamentReducer.onTimeChallenges;

// Selector to sort the onTimeChallenges by startTime, newest first
const getSortedOnTimeChallenges = createSelector(
  [getOnTimeChallenges],
  (onTimeChallenges) => {
    return onTimeChallenges.slice().sort((a:ActiveChallengeType, b:ActiveChallengeType) => {
      // Converting startTime to a Date object for comparison
      return new Date(b.startTime as Date).getTime() - new Date(a.startTime as Date).getTime();
    });
  }
);

export default getSortedOnTimeChallenges;