import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button, { ButtonProps } from 'src/components/Button/Button'
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import SvgIcon, { SvgIconProps } from '../SvgIcon/SvgIcon'
const StyledMenu = styled((props: MuiMenuProps) => <MuiMenu elevation={0} {...props} />)(
  ({ theme }) => ({
    '& .MuiPaper-root': {
      marginTop: '4px',
      minWidth: 120,
      borderRadius: 0,
      background: theme.palette.gray[900],
      border: `1px solid ${theme.palette.gray[800]}`,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0px',
      },
      '& .MuiMenuItem-root': {
        ...theme.typography.uiElement,
        height: '39px',
        padding: '0px 12px',
        border: `1px solid ${theme.palette.gray[900]}`,
        letterSpacing: '-0.3px',
        color: theme.palette.gray[25],
        transition: theme.transitions.create(['color', 'background-color']),
        '&:hover': {
          color: theme.palette.gray[0],
          backgroundColor: theme.palette.gray[800],
        },
        '& .MuiSvgIcon-root': {
          width: '14px',
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  })
)

export type MenuButtonProps = {
  anchorOrigin: MuiMenuProps['anchorOrigin']
  transformOrigin: MuiMenuProps['transformOrigin']
  buttonProps?: ButtonProps & { children?: React.ReactNode }
  id?: string
  sx?: ButtonProps['sx']
  rows?: Array<{
    iconName: SvgIconProps['name']
    text: string
    onClick: () => void
    disabled?: boolean
  }>
}

export default function MenuButton(props: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    if (props.buttonProps?.onClick) {
      props.buttonProps.onClick(event)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const id = props.id || 'customized'
  return (
    <div>
      <Button
        id={`${id}-button`}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        {...(props.buttonProps || {})}
      >
        {props.buttonProps?.children}
      </Button>
      <StyledMenu
        id={`${id}-menu`}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
      >
        {props.rows?.length &&
          props.rows.map((row, index) => (
            <MenuItem
              key={`${id}-${row.text}-${index}`}
              onClick={() => {
                handleClose()
                if (row.onClick) {
                  row.onClick()
                }
              }}
              disableRipple
              disabled={row.disabled}
            >
              <SvgIcon name={row.iconName} />
              {row.text}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  )
}
