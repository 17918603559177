import React from 'react'
import SvgIconWithTitle, {
  SvgIconWithTitleProps as SvgIconWithTitlePropsT,
} from '../SvgIconWithTitle/SvgIconWithTitle'
import { useTheme } from '@mui/material'

export type SvgIconWithTitleBgProps = {
  title: string
  SvgIconWithTitleProps?: SvgIconWithTitlePropsT
}

function SvgIconWithTitleBg({ title, SvgIconWithTitleProps }: SvgIconWithTitleBgProps) {
  const { TypographyProps, StackProps, SvgIconProps, ...restProps } = SvgIconWithTitleProps || {}
  const theme = useTheme()
  return (
    <SvgIconWithTitle
      SvgIconProps={{
        name: 'flag-2-line',
        ...SvgIconProps,
        sx: {
          color: 'gray.400',
          fontSize: '10px',
          ...SvgIconProps?.sx,
        },
      }}
      TypographyProps={{
        variant: 'uiElement',
        ...TypographyProps,
        sx: {
          color: 'gray.400',
          position: 'relative',
          bottom: '-2px',
          ...TypographyProps?.sx,
        },
      }}
      StackProps={{
        alignItems: 'center',
        gap: '4px',
        ...StackProps,
        sx: {
          borderRight: `1px solid ${theme.palette.gray[500]}`,
          backgroundColor: theme.palette.gray[700],
          p: '0px 4px',
          minHeight: '15px',
          ...StackProps?.sx,
        },
      }}
      {...restProps}
    >
      {title}
    </SvgIconWithTitle>
  )
}

export default SvgIconWithTitleBg
