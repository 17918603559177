import React from 'react'
import { Box, Stack, useTheme, BoxProps } from '@mui/material'
import LabelWithValue from 'src/components/LabelWithValue/LabelWithValue'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import CountWithToken, { CountWithTokenProps } from 'src/components/CountWithToken/CountWithToken'

export type TotalItem = {
  label: string
  value?: React.ReactNode
  icon?: React.ReactNode
}

export type StakingTotalProps = {
  data?: TotalItem[]
}

const xsm = 'xsm'

export default function StakingTotal({ data = [] }: StakingTotalProps) {
  const theme = useTheme()

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      bgcolor="gray.700"
      p="16px"
      gap="16px"
      sx={{
        [theme.breakpoints.down(xsm)]: {
          flexDirection: 'column',
          gap: '8px',
          p: '8px',
        },
      }}
    >
      {data.map(({ label, value, icon }) => (
        <LabelWithValue
          key={label}
          StackProps={{
            sx: {
              [theme.breakpoints.down(xsm)]: {
                gap: '6px',
              },
            },
          }}
          MediaWithTitleProps={{
            StackProps: { gap: '4px' },
            icon,
            TypographyProps: {
              children: label,
              sx: {
                [theme.breakpoints.down(xsm)]: {
                  ...theme.typography.paragraphSmall,
                },
              },
            },
          }}
        >
          {value ?? '-'}
        </LabelWithValue>
      ))}
    </Stack>
  )
}

export function TotalValue({
  value,
  TypographyProps,
}: {
  value?: string
  TypographyProps?: TypographyProps
}) {
  return (
    <Typography variant="h7" color={value ? 'gray.0' : 'gray.600'} ml="20px" {...TypographyProps}>
      {value}
    </Typography>
  )
}

export function TotalValueOrStatus({
  value,
  token,
  status,
  BoxProps,
  CountWithTokenProps,
  StatusTypographyProps,
}: {
  value?: number | string
  token?: string
  status?: string
  BoxProps?: BoxProps
  CountWithTokenProps?: CountWithTokenProps
  StatusTypographyProps?: TypographyProps
}) {
  return (
    <Box ml="20px" {...BoxProps}>
      {value ? (
        <CountWithToken count={value} token={token} {...CountWithTokenProps} />
      ) : (
        <Typography variant="bodySmall" color="gray.600" {...StatusTypographyProps}>
          {status}
        </Typography>
      )}
    </Box>
  )
}
