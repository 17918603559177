import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'
const MuiAlert: {
  styleOverrides: ComponentsOverrides<Theme>['MuiAlert']
  variants: ComponentsVariants['MuiAlert']
} = {
  variants: [
    {
      props: { severity: 'error' },
      style: ({ theme }: { theme: any }) => ({
        background: theme.palette.background.notificationError,
      }),
    },
    {
      props: { severity: 'success' },
      style: ({ theme }: { theme: any }) => ({
        background: theme.palette.background.notificationSuccess,
      }),
    },
    {
      props: { severity: 'warning' },
      style: ({ theme }: { theme: any }) => ({
        background: theme.palette.background.notificationWarning,
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '0px',
      width: '392px',
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: '-3px',
        right: '-3px',
        background: theme.palette.primary.main,
        width: '16px',
        height: '16px',
        zIndex: '-1',
      },
      '& .MuiAlert-message': {
        ...theme.typography.bodySmall,
        color: theme.palette.gray[200],
        padding: '10px 0px 8px',
      },
      '& .MuiAlert-action': {
        aliItems: 'center',
        display: 'flex',
        padding: '10px 4px 10px 8px',
        color: 'white',
      },
      border: `1px solid ${theme.palette.gray[700]}`,
      transition: 'border 0.2s ease-in-out',
      '&:hover': {
        border: `1px solid ${theme.palette.gray[500]}`,
      },
    }),
  },
}

export default MuiAlert
