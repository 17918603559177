import React from 'react'
import { Box, Grid } from '@mui/material'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Typography from 'src/components/Typography/Typography'
import { config } from 'src/config/config'
import Link from 'src/components/Link/Link'


const Info = () => (

    <Box bgcolor="gray.900" position="relative">
        <PageContainer sx={{ pt: '17px' }}
        innerContainerProps={{
          sx: { position: 'relative' },
        }}
        >
          <PatternLines height="262px" top="9px" />
          <TitleWithDot
            typographyProps={{ variant: 'h2' }}
            dotProps={{ bottom: '16px' }}
            rootStackProps={{ spacing: '10px', mb: '23px' }}
          >
            Info          
          </TitleWithDot>
          <Typography variant="paragraphBig" sx={{ mb: 2 }}>
              {`Challenge Token (CT), ${config.blockchain.chainDisplayName} address`}
          </Typography> 
            <Typography variant="bodySmall" sx={{ mb: 3 }}>
              <Link href={`${config.blockchain.explorer.url}/address/${config.blockchain.cc_address}`}  target="_blank" rel="noopener">{config.blockchain.cc_address}</Link>
            </Typography>
          <Typography variant="paragraphBig" sx={{ mb: 2 }}>
              {`Flexi Staking, ${config.blockchainStaking.chainDisplayName} address`}
          </Typography> 
          <Typography variant="bodySmall" sx={{ mb: 3 }}>
               <Link href={`${config.blockchainStaking.explorer.url}/address/${config.blockchainStaking.flexi_staking_address}`}  target="_blank" rel="noopener">{config.blockchainStaking.flexi_staking_address}</Link>
          </Typography>
          <Typography variant="paragraphBig" sx={{ mb: 2 }}>
              {`Vested Staking, ${config.blockchainStaking.chainDisplayName} address`}
          </Typography> 
          <Typography variant="bodySmall" sx={{ mb: 3 }}>
               <Link href={`${config.blockchainStaking.explorer.url}/address/${config.blockchainStaking.vested_staking_address}`}  target="_blank" rel="noopener">{config.blockchainStaking.vested_staking_address}</Link>
          </Typography>
        </PageContainer>
    </Box>       
)

export default Info