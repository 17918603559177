import dayjs from 'dayjs'
import 'dayjs/locale/en'
import updateLocale from 'dayjs/plugin/updateLocale'
import relativeTime from 'dayjs/plugin/relativeTime'

const config = {
  thresholds: [
    { l: 's', r: 4, d: 'second' }, // if less than 5 seconds
    { l: 'ss', r: 59, d: 'second' }, // if 5 seconds or more
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 1 },
    { l: 'yy', d: 'year' },
  ],
}

dayjs.extend(updateLocale)
dayjs.extend(relativeTime, config)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'Just Now',
    ss: '%d Seconds ago',
    m: 'a minute ago',
    mm: '%d minutes ago',
    h: 'an hour ago',
    hh: '%d hours ago',
    d: 'a day ago',
    dd: '%d days ago',
    M: 'a month ago',
    MM: '%d months ago',
    y: 'a year ago',
    yy: '%d years ago',
  },
})

// Define the customFromNow function
const customFromNow = function () {
  const now = dayjs()
  if (Math.abs(now.diff(this, 'month')) >= 1) {
    return this.format('MM.DD.YY [at] HH:mm')
  } else {
    return this.fromNow()
  }
}

// Add the customFromNow function to the Dayjs prototype
dayjs.extend((_, Dayjs) => {
  Dayjs.prototype.customFromNow = customFromNow
})

export default dayjs
