import React from 'react'
import { Box, BoxProps as MuiBoxProps, useMediaQuery, useTheme } from '@mui/material'
import Timer from '../Timer/Timer'
import SvgIconWithTitle from '../SvgIconWithTitle/SvgIconWithTitle'

enum StatusVariants {
  NotJoined = 'notJoined',
  Joined = 'joined',
}

export type TournamentBannerTimerProps = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  time?: number
  status?: `${StatusVariants}`
  timerText?: string
}

function TournamentBannerTimer({
  time,
  status = StatusVariants.NotJoined,
  timerText = 'tournament starts in',
  BoxProps,
}: TournamentBannerTimerProps) {
  const theme = useTheme()
  const md834 = useMediaQuery(theme.breakpoints.down('md834'))
  const customDateLabels = { day: 'day', hrs: 'hou', min: 'min', sec: 'sec' }
  const customShowZeroTime = { zeroDay: true, zeroHrs: true, zeroMin: true }

  const [showTimer, setShowTimer] = React.useState(true)
  
  //if time is undefined or 0, then hide timer
  React.useEffect(() => {
    if (time === undefined || time <= 0) {
      setShowTimer(false)
    }
    else{
      setShowTimer(true)
    }
  }, [time])

  return (
    <Box pt={{ xs: '0', md834: '17px' }} position="relative" {...BoxProps}>
      {status === StatusVariants.Joined && (
        <SvgIconWithTitle
          SvgIconProps={{
            fontSize: 'medium',
            name: 'checkbox-circle-line-2',
            color: 'success',
          }}
          TypographyProps={{ variant: 'h7', color: 'inherit' }}
          StackProps={{
            gap: '12px',
            position: 'absolute',
            right: '10px',
            top: 0,
            sx: {
              [theme.breakpoints.down('md834')]: {
                position: 'relative',
                right: 'auto',
              },
            },
          }}
        >
          {statusValues[StatusVariants.Joined].statusText}
        </SvgIconWithTitle>
      )}
      {!md834 && showTimer  && (
        <Timer
          showZeroTime={customShowZeroTime}
          showSeconds={false}
          dateLabels={customDateLabels}
          title={timerText}
          expirySeconds={time}
          onExpire={() => {
            setShowTimer(false)
          }}
        />
      )}
    </Box>
  )
}

const statusValues = {
  [StatusVariants.Joined]: {
    statusText: 'You joined',
  },
}

export default TournamentBannerTimer
