import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'


const gameSelector = (state:RootState, gameId:string) => state.gameReducer.games &&
     state.gameReducer.games.filter((x:any) => x.intGameId === gameId) 
 


export const getGameLink = createSelector(
    gameSelector,
    (game) => {
        return game!==undefined && game.length>0 ? game[0].downloadLink: undefined;
    }
);