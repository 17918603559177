import React , {useEffect} from 'react';
import { logout } from "../actions/userActions";
import { useAppDispatch } from 'src/hooks';
import { useDisconnect} from '@thirdweb-dev/react';

export function Logout() {
    const dispatch = useAppDispatch();
    const disconnect = useDisconnect();

    useEffect(() => {
        const doLogout: () => Promise<void> = async () => {
            dispatch(logout());
            await disconnect();
        }
        doLogout();

    }, [dispatch])
   
    return null;
   
}

export default Logout;