import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { serializeError } from 'eth-rpc-errors';


export function parseMetamaskError(e) {
    console.log("original error:"+ JSON.stringify(e));
    const parsedEthersError = getParsedEthersError(e);
    console.log("parsed error:"+ JSON.stringify(parsedEthersError));

    if(parsedEthersError!==undefined){
        switch(parsedEthersError.errorCode){
            case 'REJECTED_TRANSACTION':
                return 'User rejected transaction';
            case 'CALL_REVERTED':
                if(e.method.includes('balanceOf(address)'))
                    return 'Incorrect chain selected, please select the correct chain in your wallet';
                else
                    return 'Call reverted';
                return 'Call reverted';
            case 'TRANSACTION_UNDERPRICED':
                return 'Out of gas';
        }
    }
    const parsedError = serializeError(e);
  
   const originalError = parsedError.data?.originalError?.cause?.message;
    if(originalError!==undefined){
         if(originalError.includes('Request of type \'wallet_switchEthereumChain\' already pending'))
            return 'A request to switch chains is already pending, please approve request in your wallet';
         else
            return originalError;
    }

    //Metamask error code
    if(e.code && e.code === 4001)
        return 'User rejected the request';
    else if(e.code && e.code === -32002)
        return 'Wallet is locked or not connected, please check your wallet';  //Resource is currently unavailable
    if(e.info?.error?.code && e.info.error.code === 4001)
        return 'User rejected the request';
    else if(e.error?.code && e.error.code === -32002)
        return 'Wallet is locked or not connected, please check your wallet';  //Resource is currently unavailable

    var txt = e.reason !== undefined  &&  e.reason !== null? 
        e.reason 
        : 
        ((e.data !== undefined && e.data !== null && e.data.message !== undefined) ? 
            e.data.message 
            : 
            ((e.info?.error?.data?.message !== undefined ) ? 
                e.info.error.data.message 
                : 
                e.message));

    if (txt?.includes("VM Exception while processing transaction: revert ")) {
        txt = txt.substring(50);
    }
    else if (txt?.includes("execution reverted: ")) {
        txt = txt.substring(20);
    }
    return txt ? txt: e;
}