import React from 'react'
import SvgIcon, { SvgIconProps } from '../SvgIcon/SvgIcon'
import ICONS from 'src/assets/icons/icons'

export type LogoProps = {
  sx?: SvgIconProps['sx']
  variant?: keyof Pick<typeof ICONS, 'logo-colored' | 'logo-white' | 'logo-black'>
  size: 'small' | 'medium' | 'large' | 'xs' | 'xxs'
  onClick?: ()=>void
}

const Logo: React.FC<LogoProps> = (props) => {
  return (
    <SvgIcon
      name={props.variant || 'logo-colored'}
      sx={{
        ...(props.sx || {}),
        cursor: 'pointer',
        width: {
          xxs: '112px',
          xs: '150px',
          small: '191px',
          medium: '250px',
          large: '300px',
        }[props.size || 'medium'],
        fontSize: {
          xxs: '36px',
          xs: '42px',
          small: '62px',
          medium: '70px',
          large: '83px',
        }[props.size || 'medium'],
      }}
      {...props}
    />
  )
}

export default Logo
