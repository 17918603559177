const initialState = {
    gameTemplates: [],
    games:[]
};


export const gameReducer = (state=initialState, action) => {
    switch (action.type) {
        case "AVAILABLE_GAMES":
            return Object.assign({}, state,
            {
                gameTemplates: action.availableGames.gameTemplates,
                games: action.availableGames.games,
            });
       {/* case "LOGOUT":  //No need to remove games and templates since thet do not change
        return initialState; */}
        default:
            return state;
    }

}


