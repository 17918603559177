import React from 'react'
import { Stack, StackProps, BoxProps } from '@mui/material'
import BoxWrapper from 'src/components/BoxWrapper/BoxWrapper'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type MediaBoxWithTextProps = {
  StackProps?: StackProps
  MediaBox?: BoxProps
  TitleTypographyProps?: TypographyProps
  SubTitleTypographyProps?: TypographyProps
}

export default function MediaBoxWithText({
  StackProps,
  MediaBox,
  TitleTypographyProps,
  SubTitleTypographyProps,
}: MediaBoxWithTextProps) {
  return (
    <Stack gap="8px" {...StackProps}>
      <BoxWrapper
        width="28px"
        height="28px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...MediaBox}
      >
        {MediaBox?.children}
      </BoxWrapper>
      <div>
        <Typography variant="h7" color="gray.600" mb="2px" {...TitleTypographyProps} />
        <Typography variant="description" color="gray.500" {...SubTitleTypographyProps} />
      </div>
    </Stack>
  )
}
