// rootSaga.js
import { all } from 'redux-saga/effects';
import apiSagas from './apiSagas';
import stakingSagas from './stakingSagas';


export default function* rootSaga() {
  yield all([
    stakingSagas(),
    apiSagas()
 ]);
}