import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store/configureStore'


const globalEventsSelector = (state:RootState, gameId:string|undefined) =>state.challengeEventReducer.globalEvents &&
    (gameId !== undefined ?
        state.challengeEventReducer.globalEvents.filter((x:any) => x.intGameId === gameId)
        :
        state.challengeEventReducer.globalEvents
    ); 
  
export const filteredGlobalEvents = createSelector(
    globalEventsSelector,
    (globalEvents) => {
       
        return globalEvents;
    }
);