import React, { useMemo } from 'react'
import { Box, BoxProps } from '@mui/material'

export type ImageBoxProps = BoxProps & {
  size?: 'xs' | 'small' | 'medium'
}

const sizes = {
  xs: {
    width: '28px',
    height: '28px',
  },
  small: {
    width: '36px',
    height: '36px',
  },
  medium: {
    width: '44px',
    height: '44px',
  },
}

const getSize = (size: ImageBoxProps['size']) => {
  switch (size) {
    case 'xs':
      return sizes.xs
    case 'small':
      return sizes.small
    case 'medium':
      return sizes.medium
    default:
      return sizes.small
  }
}

export default function ImageBox({ children, size = 'small', ...restProps }: ImageBoxProps) {
  const sizeStyle = useMemo(() => getSize(size), [size])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="gray.800"
      border="1px solid"
      borderColor="gray.700"
      position="relative"
      {...sizeStyle}
      {...restProps}
    >
      {children}
    </Box>
  )
}
